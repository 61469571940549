import { TransactionDetailsWithdrawalWireInternationalViewModel } from 'view-models/transactions/details/fiat/transaction-details-withdrawal-wire-international-vm';
import { TransactionDetailsDepositWireReversalViewModel } from 'view-models/transactions/details/fiat/transaction-details-deposit-wire-reversal-vm';
import { TransactionDetailsWithdrawalWireReversalViewModel } from 'view-models/transactions/details/fiat/transaction-details-withdrawal-wire-reversal-vm';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { TransactionDetailResponseModel } from 'models/response/transactions/transaction-detail-response-model';
import { TransactionDetails } from 'ui-interfaces/transaction-details/transaction-details';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { TransactionDetailsViewModelWithUser } from 'view-models/transactions/details/decorators/transaction-details-view-model-with-user';
import { TransactionDetailsBaseViewModel } from 'view-models/transactions/details/transaction-details-base-vm';
import { ExternalAccountDetailResponseModel } from 'models/response/current/external-account-detail-response-model';
// fiat - start
import { TransactionDetailsInternalTransferViewModel } from 'view-models/transactions/details/fiat/transaction-details-internal-transfer-vm';
import { TransactionDetailsDepositAchViewModel } from 'view-models/transactions/details/fiat/transaction-details-deposit-ach-vm';
import { TransactionDetailsDepositWireViewModel } from 'view-models/transactions/details/fiat/transaction-details-deposit-wire-vm';
import { TransactionDetailsWithdrawalAchViewModel } from 'view-models/transactions/details/fiat/transaction-details-withdrawal-ach-vm';
import { TransactionDetailsDepositAchReturnViewModel } from 'view-models/transactions/details/fiat/transaction-details-deposit-ach-return-vm';
import { TransactionDetailsWithdrawalWireViewModel } from 'view-models/transactions/details/fiat/transaction-details-withdrawal-wire-vm';
import { TransactionDetailsDepositCardViewModel } from 'view-models/transactions/details/fiat/transaction-details-deposit-card-vm';
import { TransactionDetailsDepositCheckViewModel } from 'view-models/transactions/details/fiat/transaction-details-deposit-check-vm';
import { TransactionDetailsDepositAchReverseViewModel } from 'view-models/transactions/details/fiat/transaction-details-deposit-ach-reverse-vm';
import { TransactionDetailsWithdrawalAchReverseViewModel } from 'view-models/transactions/details/fiat/transaction-details-withdrawal-ach-reverse-vm';
import { TransactionDetailsWithdrawalAchReturnViewModel } from 'view-models/transactions/details/fiat/transaction-details-withdrawal-ach-return-vm';
import { TransactionDetailsWithdrawalAchIncomingViewModel } from 'view-models/transactions/details/fiat/transaction-details-withdrawal-ach-incoming-vm';
import { TransactionDetailsDepositAchIncomingViewModel } from 'view-models/transactions/details/fiat/transaction-details-deposit-ach-incoming-vm';
import { TransactionDetailsDepositWireReturnViewModel } from 'view-models/transactions/details/fiat/transaction-details-deposit-wire-return-vm';
import { TransactionDetailsWithdrawalWireReturnViewModel } from 'view-models/transactions/details/fiat/transaction-details-withdrawal-wire-return-vm';
// fiat - end
// crypto - start
import { TransactionDetailsBuyCryptoViewModel } from 'view-models/transactions/details/crypto/transaction-details-buy-crypto-vm';
import { TransactionDetailsSellCryptoViewModel } from 'view-models/transactions/details/crypto/transaction-details-sell-crypto-vm';
import { TransactionDetailsDepositCryptoViewModel } from 'view-models/transactions/details/crypto/transaction-details-deposit-crypto-vm';
import { TransactionDetailsWithdrawalCryptoViewModel } from 'view-models/transactions/details/crypto/transaction-details-withdrawal-crypto-vm';
import { TransactionDetailsInternalTransferCryptoViewModel } from 'view-models/transactions/details/crypto/transaction-details-internal-transfer-crypto-vm';
// crypto - end
// private security - start
import { TransactionDetailsPrivateSecurityInternalCryptoViewModel } from 'view-models/transactions/details/private-security/transaction-details-private-security-internal-crypto-vm';
import { TransactionDetailsPrivateSecurityDepositCryptoViewModel } from 'view-models/transactions/details/private-security/transaction-details-private-security-deposit-crypto-vm';
import { TransactionDetailsPrivateSecurityWithdrawalCryptoViewModel } from 'view-models/transactions/details/private-security/transaction-details-private-security-withdrawal-crypto-vm';
// private security - end
// precious metal - start
import { TransactionDetailsPreciousMetalSellViewModel } from 'view-models/transactions/details/precious-metal/transaction-details-precious-metal-sell-vm';
import { TransactionDetailsPreciousMetalBuyViewModel } from 'view-models/transactions/details/precious-metal/transaction-details-precious-metal-buy-vm';
// precious metal - end
// common
import { TransactionInitialDepositDetailsViewModel } from 'view-models/transactions/details/common/transaction-details-deposit-initial-vm';
import { TransactionDetailsManualViewModel } from 'view-models/transactions/details/manual/transaction-details-manual-vm';
import { TransactionDetailsTransferFeeViewModel } from 'view-models/transactions/details/transfer-fee/transaction-details-transfer-fee-vm';

export const createTransactionDetailsViewModel = (
  transaction: TransactionDetailResponseModel,
  user: ShortUserResponseModel,
  externalAccount?: ExternalAccountDetailResponseModel,
): TransactionDetails => {
  switch (transaction.paymentType.toLowerCase()) {
    // fiat - start
    case PaymentType.internal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsInternalTransferViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.achDeposit.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositAchViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.wireDeposit.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositWireViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.achWithdrawal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsWithdrawalAchViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.returnAchDeposit.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositAchReturnViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.wireWithdrawal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsWithdrawalWireViewModel(transaction, externalAccount),
        transaction,
        user,
      );
    }
    case PaymentType.internationalWireWithdrawal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsWithdrawalWireInternationalViewModel(transaction, externalAccount),
        transaction,
        user,
      );
    }
    case PaymentType.cardDeposit.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositCardViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.checkDeposit.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositCheckViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.reversalWireDeposit.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositWireReversalViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.reversalWireWithdrawal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsWithdrawalWireReversalViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.returnAchWithdrawal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsWithdrawalAchReturnViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.incomingAchDeposit.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositAchIncomingViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.incomingAchWithdrawal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsWithdrawalAchIncomingViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.achDepositReverse.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositAchReverseViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.achWithdrawalReverse.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsWithdrawalAchReverseViewModel(transaction),
        transaction,
        user,
      );
    }
    // fiat - end

    // crypto - start
    case PaymentType.buyCrypto.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsBuyCryptoViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.sellCrypto.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsSellCryptoViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.depositCrypto.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositCryptoViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.withdrawalCrypto.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsWithdrawalCryptoViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.internalCrypto.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsInternalTransferCryptoViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.wireDepositReturn.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsDepositWireReturnViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.wireWithdrawalReturn.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsWithdrawalWireReturnViewModel(transaction),
        transaction,
        user,
      );
    }
    // crypto - end

    // private security - start
    case PaymentType.privateSecurityInternal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsPrivateSecurityInternalCryptoViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.privateSecurityDeposit.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsPrivateSecurityDepositCryptoViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.privateSecurityWithdrawal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsPrivateSecurityWithdrawalCryptoViewModel(transaction),
        transaction,
        user,
      );
    }
    // private security - end

    // precious metal - start
    case PaymentType.buyPreciousMetal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsPreciousMetalBuyViewModel(transaction),
        transaction,
        user,
      );
    }
    case PaymentType.sellPreciousMetal.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsPreciousMetalSellViewModel(transaction),
        transaction,
        user,
      );
    }
    // precious metal - end

    case PaymentType.manuallyProcessed.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsManualViewModel(transaction),
        transaction,
        user,
      );
    }

    case PaymentType.transferFee.toLowerCase(): {
      return new TransactionDetailsViewModelWithUser(
        new TransactionDetailsTransferFeeViewModel(transaction),
        transaction,
        user,
      );
    }

    // common
    case PaymentType.initialDeposit.toLowerCase(): {
      return new TransactionInitialDepositDetailsViewModel(transaction);
    }
    default: {
      return new TransactionDetailsBaseViewModel(transaction);
    }
  }
};
