import {
  CryptoAddressesApiParams,
  CryptoAddressRequest,
  CryptoAddressResponse,
  CustodialAccountsApiParams,
  ExternalAccountsApiParams,
  GetBankInfoResponse,
  WireDepositInstructionsResponse,
} from 'elements/element-transfer/types';
import { CustodialAccount, ExternalAccount, IdentityData, RecipientCustodialAccount } from 'elements/types';
import { getRouteWithParams } from 'elements/utils';
import { axiosInstance, CollectionResponse } from 'elements/api';
import { TryLockInitRequestModel } from 'models/request/current/try-lock-init-request-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { InitiateCoinsWithdrawalModel } from 'models/request/transfers/initiate-coins-withdrawal-model';
import { TransferPaymentModel } from 'models/response/transfer-elements/transfer-payment-model';
import { InitiateDepositTransferModel } from 'models/request/transfers/initiate-deposit-transfer-model';
import { InitiateWireWithdrawalTransferModel } from 'models/request/transfers/initiate-wire-withdrawal-transfer-model';
import { InitiateInternationalWireWithdrawalTransferModel } from 'models/request/transfers/initiate-international-wire-withdrawal-transfer-model';
import { InitiateInternalTransferModel } from 'models/request/transfers/initiate-internal-transfer-model';

enum TransferElementEndpoints {
  GetBankInfo = '/current/wire/:routingNumber',

  GetIdentityData = '/current/:custodialAccountId/identity',
  GetCustodialAccount = '/current/:custodialAccountId/custodial-account',
  GetCustodialAccounts = '/custodial-accounts',
  GetCustodialAccountDetails = '/custodial-accounts/:custodialAccountId',
  GetWireDepositInstructions = '/current/:custodialAccountId/wire-instructions',
  GetCoinsBalance = '/custodial-accounts/:custodialAccountId/balances?page=1&pageSize=100&operation=:operation',
  GetExternalAccounts = '/external-accounts',
  DeleteExternalAccounts = '/external-accounts/:externalAccountId',

  CryptoAddresses = '/crypto-addresses',
  DeleteCryptoAddress = '/crypto-addresses/:cryptoAddressId',

  ProcessDeposit = '/transfers/deposit',
  WithdrawCoins = '/transfers/withdraw-crypto',
  DomesticWireWithdrawal = '/transfers/wire-withdrawal',
  InternationalWireWithdrawal = '/transfers/international-wire-withdrawal',
  InternalTransfer = '/transfers/internal',

  GetAddBankAccountJwt = '/custodial-accounts/:custodialAccountId/jwt/addBankAccount',
  GetKYCJwt = '/custodial-accounts/:custodialAccountId/jwt/kyc',

  // TODO update
  LockPaymentAmount = '/current/current/try-lock',
  UnlockPaymentAmount = '/current/current/cancel-lock/:lockId',
}

export const transferElement = {
  processDeposit(body: InitiateDepositTransferModel): Promise<TransferPaymentModel> {
    return axiosInstance.post(TransferElementEndpoints.ProcessDeposit, body);
  },

  getIdentityData(custodialAccountId: string): Promise<IdentityData> {
    return axiosInstance.get(getRouteWithParams(TransferElementEndpoints.GetIdentityData, { custodialAccountId }));
  },

  getAddBankAccountJwt(): Promise<{ jwt: string }> {
    return axiosInstance.get(TransferElementEndpoints.GetAddBankAccountJwt);
  },

  getKYCJwt(custodialAccountId: string): Promise<{ jwt: string }> {
    return axiosInstance.get(getRouteWithParams(TransferElementEndpoints.GetKYCJwt, { custodialAccountId }));
  },
  getCustodialAccount(custodialAccountId: string): Promise<CustodialAccount> {
    return axiosInstance.get(getRouteWithParams(TransferElementEndpoints.GetCustodialAccount, { custodialAccountId }));
  },
  getCustodialAccounts(params?: CustodialAccountsApiParams): Promise<CollectionResponse<RecipientCustodialAccount>> {
    return axiosInstance.get(TransferElementEndpoints.GetCustodialAccounts, { params });
  },
  getCustodialAccountDetails(custodialAccountId: string): Promise<RecipientCustodialAccount> {
    return axiosInstance.get(
      getRouteWithParams(TransferElementEndpoints.GetCustodialAccountDetails, { custodialAccountId }),
    );
  },
  getExternalAccounts(params?: ExternalAccountsApiParams): Promise<CollectionResponse<ExternalAccount>> {
    return axiosInstance.get(TransferElementEndpoints.GetExternalAccounts, {
      params,
    });
  },
  deleteExternalAccount(externalAccountId: string): Promise<undefined> {
    return axiosInstance.delete(
      getRouteWithParams(TransferElementEndpoints.DeleteExternalAccounts, { externalAccountId }),
    );
  },
  getCryptoAddresses(params?: CryptoAddressesApiParams): Promise<CollectionResponse<CryptoAddressResponse>> {
    return axiosInstance.get(TransferElementEndpoints.CryptoAddresses, {
      params,
    });
  },
  deleteCryptoAddress(cryptoAddressId: string): Promise<undefined> {
    return axiosInstance.delete(getRouteWithParams(TransferElementEndpoints.DeleteCryptoAddress, { cryptoAddressId }));
  },
  saveCryptoAddress(body: CryptoAddressRequest): Promise<undefined> {
    return axiosInstance.post(TransferElementEndpoints.CryptoAddresses, body);
  },
  getWireDepositInstructions(custodialAccountId: string): Promise<WireDepositInstructionsResponse> {
    return axiosInstance.get(
      getRouteWithParams(TransferElementEndpoints.GetWireDepositInstructions, { custodialAccountId }),
    );
  },
  getBankInfo(routingNumber: string): Promise<GetBankInfoResponse> {
    return axiosInstance.get(getRouteWithParams(TransferElementEndpoints.GetBankInfo, { routingNumber }));
  },
  initiateCoinsWithdrawal(body: InitiateCoinsWithdrawalModel): Promise<TransferPaymentModel> {
    return axiosInstance.post(TransferElementEndpoints.WithdrawCoins, body);
  },
  getCoinsBalance(
    custodialAccountId: string,
    operation: string,
  ): Promise<CollectionResponse<CustodialAccountBalanceModel>> {
    return axiosInstance.get(
      getRouteWithParams(TransferElementEndpoints.GetCoinsBalance, { custodialAccountId, operation }),
    );
  },
  initiateDomesticWireWithdrawal(body: InitiateWireWithdrawalTransferModel): Promise<TransferPaymentModel> {
    return axiosInstance.post(TransferElementEndpoints.DomesticWireWithdrawal, body);
  },
  initiateInternationalWireWithdrawal(
    body: InitiateInternationalWireWithdrawalTransferModel,
  ): Promise<TransferPaymentModel> {
    return axiosInstance.post(TransferElementEndpoints.InternationalWireWithdrawal, body);
  },
  initiateInternalTransfer(body: InitiateInternalTransferModel): Promise<TransferPaymentModel> {
    return axiosInstance.post(TransferElementEndpoints.InternalTransfer, body);
  },
  lockPaymentAmount(lockParams: TryLockInitRequestModel): Promise<string> {
    return axiosInstance.post(TransferElementEndpoints.LockPaymentAmount, lockParams);
  },

  unlockPaymentAmount(lockId: string): Promise<void> {
    return axiosInstance.post(getRouteWithParams(TransferElementEndpoints.UnlockPaymentAmount, { lockId }));
  },
};
