import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as api from 'elements/element-trade/api';
import { getVerificationType } from 'elements/features/IdentityVerification';
import { TradeFlow } from 'elements/element-trade/types';
import { isUnavailableServiceError } from 'elements/api';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import { AccountStatusType, IdentityData, IdentityLevel, IdentityType } from 'elements/types';
import { setErrorBanner } from 'elements/utils';
import { OnCloseElement } from 'elements/models/types/element-result';
import ContentBlock from 'elements/element-trade/components/BuySellCoinPage';
import {
  setCustodialAccountList,
  setFlow,
  setIsServiceUnavailable,
  setLockId,
  setPage,
  useElement,
  setPaymentType,
} from 'elements/element-trade/contexts/Element';
import { useOrderPreparation } from 'elements/element-trade/hooks/useOrderPreparation';
import useRequestCoinsList, { RequestCoinListStatus } from 'elements/element-trade/hooks/useRequestCoinList';
import isCoinDisabled from 'elements/element-trade/utils/isCoinDisabled';
import { Paths } from 'elements/element-trade/navigation/routes';
import { useLockPaymentAmount } from 'elements/hooks/useLockPaymentAmount';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { TryLockInitRequestModel } from 'models/request/current/try-lock-init-request-model';
import IdentityVerificationPage from './IdentityVerificationPage';

const BuySellCoinPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();

  const [isIdentityDataLoading, setIsIdentityDataLoading] = useState(false);

  const [isGetCustodialAccountLoading, setIsGetCustodialAccountLoading] = useState(false);

  const { requestCoinList, requestCoinListStatus } = useRequestCoinsList();

  const { lock, unlock, locking, unlocking, lockError, unlockError, clearLockError } = useLockPaymentAmount({
    lockId: state.lockId,
    setLockId: (id: string) => dispatch(setLockId(id)),
    lockRequest: api.tradeElement.lockPaymentAmount,
    unlockRequest: api.tradeElement.unlockPaymentAmount,
  });

  const orderInfo = useOrderPreparation(lockError ? { clearLockError } : {});

  const {
    tradeDirection,
    contactUsUrl,
    serviceUnavailable,
    custodialAccountList,
    custodialAccountId,
    paymentType,
    [state.flowType]: { amount, selectedBuySellCoin },
  } = state;

  const isContinueButtonDisabled = useMemo(() => {
    const notSupportedCoin = state.flowType === TradeFlow.Sell && isCoinDisabled(selectedBuySellCoin?.assetType);

    return (
      !Number(amount) ||
      !selectedBuySellCoin ||
      orderInfo.coinPriceError ||
      orderInfo.coinPriceLoading ||
      notSupportedCoin ||
      orderInfo.getIsAmountInvalid(amount)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.flowType, amount, selectedBuySellCoin, orderInfo.coinPriceError, orderInfo.coinPriceLoading]);

  const getIdentityData = async () => {
    let data: IdentityData | null;
    try {
      setIsIdentityDataLoading(true);
      data = {
        type: IdentityType.Personal,
        kycLevel: IdentityLevel.L1,
        status: AccountStatusType.Active,
        canUseCustodialAccounts: true,
      };
      data = await api.tradeElement.getIdentityData(custodialAccountId);
    } catch (e) {
      setErrorBanner(e, enqueueSnackbar, true);
      data = null;
    } finally {
      setIsIdentityDataLoading(false);
    }
    return data;
  };

  const getCustodialAccount = async () => {
    try {
      setIsGetCustodialAccountLoading(true);

      const { data } = await api.tradeElement.getCustodialAccounts(custodialAccountId);

      dispatch(setCustodialAccountList(data));
      dispatch(setIsServiceUnavailable(false));
      return true;
    } catch (e) {
      if (isUnavailableServiceError(e)) {
        dispatch(setIsServiceUnavailable(true));
        return false;
      }

      setErrorBanner(e, enqueueSnackbar, true);
      return false;
    } finally {
      setIsGetCustodialAccountLoading(false);
    }
  };

  const onTabChange = async (value: TradeFlow) => {
    dispatch(setFlow(value));
    clearLockError();

    if (
      (value !== state.flowType && !state[value].coinsList.length) ||
      requestCoinListStatus === RequestCoinListStatus.Failed
    ) {
      await requestCoinList(value);
    }
  };

  const onReload = async (flowType: TradeFlow) => {
    if (requestCoinListStatus === RequestCoinListStatus.Failed) {
      await requestCoinList(flowType);
    }

    if (unlockError) {
      await unlock();
    }
  };

  const onContinue = async () => {
    if (!selectedBuySellCoin) {
      return;
    }

    if (orderInfo.getIsAmountInvalid(amount)) {
      return;
    }

    const identityData = await getIdentityData();

    if (!identityData) {
      return;
    }

    const isInactiveAccount = identityData.status !== AccountStatusType.Active || !identityData.canUseCustodialAccounts;

    const verificationType = getVerificationType(isInactiveAccount, identityData.type);

    if (isInactiveAccount || identityData?.kycLevel === IdentityLevel.L0) {
      dispatch(setPage(Paths.Verification, { verificationType }));
      return;
    }

    if (identityData?.kycLevel === IdentityLevel.L1 || identityData?.kycLevel === IdentityLevel.L2) {
      let composedData = {} as TryLockInitRequestModel;

      if (paymentType === PaymentType.buyCrypto) {
        composedData = {
          paymentType,
          usdAmount: +amount,
          assetType: selectedBuySellCoin.assetTicker,
          network: selectedBuySellCoin.network,
        };
      }

      if (paymentType === PaymentType.sellCrypto) {
        composedData = {
          paymentType,
          cryptoAmount: +amount,
          network: selectedBuySellCoin.network,
          assetType: selectedBuySellCoin.assetTicker,
        };
      }

      const isLocked = await lock(composedData);

      if (!isLocked) {
        return;
      }

      if (!custodialAccountList[0]?.id) {
        const isSuccess = await getCustodialAccount();
        if (isSuccess) {
          dispatch(setPage(Paths.Review));
        }
      } else {
        dispatch(setPage(Paths.Review));
      }
    }
  };

  useEffect(() => {
    const type = state.flowType === TradeFlow.Buy ? PaymentType.buyCrypto : PaymentType.sellCrypto;
    dispatch(setPaymentType(type));
  }, [dispatch, paymentType, state.flowType]);

  return (
    <>
      {isIdentityDataLoading || isGetCustodialAccountLoading || locking || unlocking ? (
        <LoadingPage />
      ) : (
        <ContentBlock
          {...orderInfo}
          selectedCoin={selectedBuySellCoin}
          coinsList={orderInfo.coinsBuySellList}
          tradeDirection={tradeDirection}
          flowType={state.flowType}
          lockAmountError={lockError}
          unlockAmountError={unlockError}
          continueButtonDisabled={isContinueButtonDisabled}
          coinListLoading={requestCoinListStatus === RequestCoinListStatus.Loading}
          coinListError={requestCoinListStatus === RequestCoinListStatus.Failed}
          contactUsUrl={contactUsUrl}
          serviceUnavailable={serviceUnavailable}
          onTabChange={onTabChange}
          onContinue={onContinue}
          onClose={onClose}
          onReload={onReload}
        />
      )}
      {state.page.location === Paths.Verification ? <IdentityVerificationPage /> : null}
    </>
  );
};

export default BuySellCoinPage;
