import React from 'react';
import { IdentityLevel } from 'elements/types';
import { OnCloseElement } from 'elements/models/types/element-result';
import { Paths } from 'elements/element-transfer/navigation/routes';

import DepositAchAmountPage from 'elements/element-transfer/pages/Deposit/AchAmountPage';
import DepositAchPlacedPage from 'elements/element-transfer/pages/Deposit/AchPlacedPage';
import DepositAchReviewPage from 'elements/element-transfer/pages/Deposit/AchReviewPage';
import CryptoAddressesPage from 'elements/element-transfer/pages/Deposit/CryptoAddressesPage';
import WireInstructionsPage from 'elements/element-transfer/pages/Deposit/WireInstructionsPage';
import EntryPage from 'elements/element-transfer/pages/EntryPage';
import TransferPage from 'elements/element-transfer/pages/TransferPage';
import WithdrawalAchAmountPage from 'elements/element-transfer/pages/Withdrawal/AchAmountPage';
import WithdrawalCryptoAmountPage from 'elements/element-transfer/pages/Withdrawal/CryptoAmountPage';
import WithdrawalCryptoReviewPage from 'elements/element-transfer/pages/Withdrawal/CryptoReviewPage';
import WithdrawalCryptoSentPage from 'elements/element-transfer/pages/Withdrawal/CryptoSentPage';
import WithdrawalWireAccountNumbersPage from 'elements/element-transfer/pages/Withdrawal/WireAccountNumbersPage';
import WithdrawalWireAmountPage from 'elements/element-transfer/pages/Withdrawal/WireAmountPage';
import WithdrawalWireBankInfoPage from 'elements/element-transfer/pages/Withdrawal/WireBankInfoPage';
import WithdrawalWirePlacedPage from 'elements/element-transfer/pages/Withdrawal/WirePlacedPage';
import WithdrawalWireRecipientSearchPage from 'elements/element-transfer/pages/Withdrawal/WireRecipientSearchPage';
import WithdrawalWireRecipientInfoPage from 'elements/element-transfer/pages/Withdrawal/WireRecipientInfoPage';
import WithdrawalWireReviewPage from 'elements/element-transfer/pages/Withdrawal/WireReviewPage';
import CryptoQrCodePage from 'elements/element-transfer/pages/Deposit/CryptoQrCodePage';
import IdentityRestriction from 'elements/element-transfer/restrictions/IdentityRestriction';
import InternalTransferRecipientSearchPage from 'elements/element-transfer/pages/Withdrawal/InternalTransferRecipientSearchPage';
import InternalTransferAmountPage from 'elements/element-transfer/pages/Withdrawal/InternalTransferAmountPage';
import InternalTransferReviewPage from 'elements/element-transfer/pages/Withdrawal/InternalTransferReviewPage';
import InternalTransferPlacedPage from 'elements/element-transfer/pages/Withdrawal/InternalTransferPlacedPage';

const createAppContent = (route: Paths, onClose: OnCloseElement) => {
  switch (route) {
    case Paths.Root: {
      return <EntryPage />;
    }
    case Paths.Transfer: {
      return <TransferPage onClose={onClose} />;
    }
    case Paths.DepositAchAmount:
    case Paths.DepositPaymentMethod: {
      return (
        <IdentityRestriction onClose={onClose} path={Paths.DepositAchAmount}>
          <DepositAchAmountPage onClose={onClose} />
        </IdentityRestriction>
      );
    }
    case Paths.DepositAchReview: {
      return <DepositAchReviewPage onClose={onClose} />;
    }
    case Paths.DepositAchPlaced: {
      return <DepositAchPlacedPage onClose={onClose} />;
    }
    case Paths.DepositWire: {
      return (
        <IdentityRestriction onClose={onClose} path={Paths.DepositWire}>
          <WireInstructionsPage onClose={onClose} />
        </IdentityRestriction>
      );
    }
    case Paths.DepositCryptoAddresses: {
      return (
        <IdentityRestriction requiredLevel={IdentityLevel.L2} onClose={onClose} path={Paths.DepositCryptoAddresses}>
          <CryptoAddressesPage onClose={onClose} />
        </IdentityRestriction>
      );
    }
    case Paths.DepositCryptoQrCode: {
      return <CryptoQrCodePage onClose={onClose} />;
    }
    // TODO consider to remove
    case Paths.WithdrawalAchAmount: {
      return (
        <IdentityRestriction onClose={onClose} path={Paths.WithdrawalAchAmount}>
          <WithdrawalAchAmountPage />
        </IdentityRestriction>
      );
    }
    case Paths.WithdrawalWireRecipientSearch: {
      return (
        <IdentityRestriction onClose={onClose} path={Paths.WithdrawalWireRecipientSearch}>
          <WithdrawalWireRecipientSearchPage onClose={onClose} />
        </IdentityRestriction>
      );
    }
    case Paths.WithdrawalWireRecipientInfo: {
      return <WithdrawalWireRecipientInfoPage onClose={onClose} />;
    }
    case Paths.WithdrawalWireBankInfo: {
      return <WithdrawalWireBankInfoPage onClose={onClose} />;
    }
    case Paths.WithdrawalWireAccountNumbers: {
      return <WithdrawalWireAccountNumbersPage onClose={onClose} />;
    }
    case Paths.WithdrawalWireAmount: {
      return <WithdrawalWireAmountPage onClose={onClose} />;
    }
    case Paths.WithdrawalWireReview: {
      return <WithdrawalWireReviewPage onClose={onClose} />;
    }
    case Paths.WithdrawalWirePlaced: {
      return <WithdrawalWirePlacedPage onClose={onClose} />;
    }
    case Paths.WithdrawalCryptoAmount: {
      return (
        <IdentityRestriction requiredLevel={IdentityLevel.L2} onClose={onClose} path={Paths.WithdrawalCryptoAmount}>
          <WithdrawalCryptoAmountPage onClose={onClose} />
        </IdentityRestriction>
      );
    }
    case Paths.WithdrawalCryptoReview: {
      return <WithdrawalCryptoReviewPage onClose={onClose} />;
    }
    case Paths.WithdrawalCryptoSent: {
      return <WithdrawalCryptoSentPage onClose={onClose} />;
    }
    case Paths.InternalTransferRecipientSearch: {
      return (
        <IdentityRestriction
          requiredLevel={IdentityLevel.L1}
          onClose={onClose}
          path={Paths.InternalTransferRecipientSearch}
        >
          <InternalTransferRecipientSearchPage onClose={onClose} />;
        </IdentityRestriction>
      );
    }
    case Paths.InternalTransferAmount: {
      return <InternalTransferAmountPage onClose={onClose} />;
    }
    case Paths.InternalTransferReview: {
      return <InternalTransferReviewPage onClose={onClose} />;
    }
    case Paths.InternalTransferPlaced: {
      return <InternalTransferPlacedPage onClose={onClose} />;
    }

    default:
      return <EntryPage />;
  }
};

export { createAppContent };
