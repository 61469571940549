import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Stack } from 'elements/components';
import { StyledBankAccountIcon, StyledContainer, StyledTruncatedTypography } from './styled';
import { StyledAccountContainer, StyledIconContainer, StyledTextContainer } from '../InternalTransferSource/styled';

type Props = {
  recepientName: string;
  recepientAccountNumber: string;
};

const WireTo: React.FC<Props> = ({ recepientName, recepientAccountNumber }) => {
  const { t } = useTranslation();

  const title =
    !!recepientName?.length && recepientName.length > 35 ? `${recepientName?.substr(0, 35)}...` : recepientName;
  const postfix = `*${recepientAccountNumber.slice(-4)}`;

  return (
    <StyledContainer>
      <StyledAccountContainer>
        <StyledIconContainer>
          <StyledBankAccountIcon />
        </StyledIconContainer>
        <StyledTextContainer>
          <Typography variant="captionMedium" color="secondary.font">
            {t('elements.transfer.internalTransferAmountPage.toLabel')}
          </Typography>
          <Stack direction="row" fullWidth gap={0.5}>
            <StyledTruncatedTypography variant="bodyDefaultMedium" color="primary.font">
              {title}
            </StyledTruncatedTypography>
            <Typography variant="bodyDefaultMedium" color="primary.font">
              {postfix}
            </Typography>
          </Stack>
        </StyledTextContainer>
      </StyledAccountContainer>
    </StyledContainer>
  );
};

export default WireTo;
