import React, { ForwardedRef, forwardRef } from 'react';
import Typography from '@mui/material/Typography';
import { ReactComponent as ExportIcon } from 'assets/icons/export-icon.svg';
import i18n from 'localizations';
import { Row } from 'components/shared/Flex/Row';
import { Gap } from 'components/shared/Gap';
import { StyledButton, StyledLoader } from './styled';

type Props = {
  isDisabled: boolean;
  isLoading?: boolean;
  onClick: () => void;
};

export const ExportButton = forwardRef<HTMLButtonElement | null, Props>(
  ({ isDisabled, isLoading = false, onClick }: Props, ref: ForwardedRef<HTMLButtonElement | null>) => {
    return (
      <StyledButton
        sx={{
          opacity: isDisabled ? 0 : 1,
        }}
        disabled={isDisabled}
        variant="outlined"
        onClick={onClick}
        ref={ref}
      >
        {isLoading ? (
          <StyledLoader size="small" />
        ) : (
          <Row>
            <ExportIcon />
            <Gap size="_12px" isHorizontal />
            <Typography variant="buttonMedium">{i18n.t('filters.exportButtonLabel')}</Typography>
          </Row>
        )}
      </StyledButton>
    );
  },
);
