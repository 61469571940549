import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Divider, Stack } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import { formatCryptoBalance, formatUsdBalance } from 'elements/utils';
import { getAccountData } from 'elements/element-transfer/utils/getAccountData';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { TrustAssetType } from 'ui-enums/response/custodial-accounts/trust-asset-type';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import {
  StyledAccountContainer,
  StyledAmountContainer,
  StyledContainer,
  StyledIconContainer,
  StyledTextContainer,
  StyledTruncatedTypography,
} from './styled';

type Props = {
  custodialAccount: CustodialAccount;
  custodialAccountName: string;
  withBalance?: boolean;
  coin?: CustodialAccountBalanceModel;
};

const InternalTransferSource: React.FC<Props> = ({ custodialAccount, custodialAccountName, withBalance, coin }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const { type, accountNumber, balance } = custodialAccount;

  const { Icon } = getAccountData(type);
  const title = custodialAccountName.length > 35 ? `${custodialAccountName?.substr(0, 35)}...` : custodialAccountName;
  const postfix = `*${accountNumber.slice(-4)}`;

  return (
    <StyledContainer>
      <StyledAccountContainer>
        <StyledIconContainer>
          <Icon />
        </StyledIconContainer>
        <StyledTextContainer>
          <Typography variant="captionMedium" color="secondary.font">
            {t('elements.transfer.internalTransferAmountPage.fromLabel')}
          </Typography>
          <Stack direction="row" fullWidth gap={0.5}>
            <StyledTruncatedTypography variant="bodyDefaultMedium" color="primary.font">
              {title}
            </StyledTruncatedTypography>
            <Typography variant="bodyDefaultMedium" color="primary.font">
              {postfix}
            </Typography>
          </Stack>
        </StyledTextContainer>
      </StyledAccountContainer>
      {withBalance && coin && (
        <>
          <Divider />
          <StyledAmountContainer>
            <StyledTruncatedTypography variant="bodyDefaultBook" color="primary.font">
              {t('elements.transfer.internalTransferAmountPage.availableAmountLabel')}
            </StyledTruncatedTypography>
            <Typography variant="bodyDefaultMedium" color="primary.font">
              {coin.assetType.toLowerCase() === TrustAssetType.fiatCurrency.toLowerCase()
                ? formatUsdBalance(balance, isMobile)
                : formatCryptoBalance(coin.disbursable, { coinAbbreviation: coin.assetTicker })}
            </Typography>
          </StyledAmountContainer>
        </>
      )}
    </StyledContainer>
  );
};

export default InternalTransferSource;
