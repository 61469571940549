import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Divider, NoteReview, Spacer } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import { formatCryptoBalance, formatUsdBalance } from 'elements/utils';
import { ReactComponent as RoundedCheckIcon } from 'elements/element-transfer/assets/icons/roundedCheck.svg';
import Layout from 'elements/element-transfer/components/Layout';
import TransactionSummary from 'elements/element-transfer/components/TransactionSummary';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import InternalTransferTo from 'elements/element-transfer/components/Withdrawal/InternalTransferTo';
import InternalTransferSource from 'elements/element-transfer/components/Withdrawal/InternalTransferSource';
import { TrustAssetType } from 'ui-enums/response/custodial-accounts/trust-asset-type';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { StyledButton } from './styled';

type Props = {
  amount: string;
  recipientName: string;
  recipientAccountNumber: string;
  custodialAccount: CustodialAccount | null;
  custodialAccountName: string;
  selectedCoin: CustodialAccountBalanceModel | null;
  comment?: string;
  onDone: () => void;
};

const InternalTransferPlacedPage: React.FC<Props> = ({
  amount,
  recipientName,
  recipientAccountNumber,
  custodialAccount,
  custodialAccountName,
  selectedCoin,
  comment,
  onDone,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const formattedAmount =
    selectedCoin?.assetType.toLowerCase() === TrustAssetType.fiatCurrency.toLowerCase()
      ? formatUsdBalance(Number(amount), isMobile)
      : formatCryptoBalance(Number(amount), { coinAbbreviation: selectedCoin?.assetTicker, withCommas: true });

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.internalTransferPlacedPageTitle')}
      onClose={onDone}
      withCloseButton={false}
      footerButtons={
        <StyledButton onClick={onDone}>
          <Typography variant="buttonLarge">{t('elements.common.done')}</Typography>
        </StyledButton>
      }
    >
      <TransactionSummary
        icon={RoundedCheckIcon}
        amount={formattedAmount}
        amountLabel={t('elements.transfer.internalTransferPlacedPage.amountLabel')}
        totalAmount={formattedAmount}
        totalLabel={t('elements.common.total')}
      />
      <Spacer size={40} />
      <InternalTransferTo recepientName={recipientName} recepientAccountNumber={recipientAccountNumber} />
      <Spacer size={isMobile ? 32 : 24} />
      {!!custodialAccount && (
        <>
          <InternalTransferSource custodialAccountName={custodialAccountName} custodialAccount={custodialAccount} />
          <Spacer size={isMobile ? 32 : 24} />
        </>
      )}
      {!!comment && (
        <>
          <NoteReview note={comment} title={t('elements.transfer.internalTransferPlacedPage.commentLabel')} />
          <Divider />
          <Spacer size={32} />
        </>
      )}
    </Layout>
  );
};

export default InternalTransferPlacedPage;
