import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Gap } from 'components/shared/Gap';
import { Column } from 'components/shared/Flex/Column';
import { Row } from 'components/shared/Flex/Row';
import DatePicker from 'components/shared/DatePicker';
import useForm from 'elements/hooks/useForm';
import { PDFStatementRequestParams } from 'redux/api/types';
import { useYupRules } from 'hooks/use-yup-rules';
import {
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
  StyledLoadingButtonWithSkeleton,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onExportSubmit: (dateRange: PDFStatementRequestParams) => void;
};

export const ExportPDFModal: React.FC<Props> = ({ isOpen, onClose, onExportSubmit }) => {
  const { t } = useTranslation();

  const { pdfStatementToDateRule, pdfStatementFromDateRule } = useYupRules();

  const [initialToDate] = useState(dayjs().format());
  const [initialFromDate] = useState(dayjs().subtract(1, 'day').format());

  const {
    formik: { setFieldValue, setValues, submitForm, values, setFieldTouched },
    helpers: { getFieldProps, getFieldErrorProps, isFormDisabled },
  } = useForm({
    initialValues: {
      dateFrom: initialFromDate,
      dateTo: initialToDate,
    },
    yupShape: {
      dateFrom: pdfStatementFromDateRule,
      dateTo: pdfStatementToDateRule,
    },
    onSubmit: (formValues, { setSubmitting }) => {
      onExportSubmit(formValues);
      setValues({ dateFrom: initialFromDate, dateTo: initialToDate });
      setSubmitting(false);
    },
  });

  const onDateFromChange = (value: string | number) => {
    if (typeof value === 'string') {
      setFieldValue('dateFrom', value);
    }
  };

  const onDateToChange = (value: string | number) => {
    if (typeof value === 'string') {
      setFieldValue('dateTo', value);
    }
  };

  useEffect(() => {
    (() => {
      setFieldTouched('dateFrom', true, true);
      setFieldTouched('dateTo', true, true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseClick = () => {
    onClose();
    setValues({ dateFrom: initialFromDate, dateTo: initialToDate });
  };

  return (
    <StyledDialog open={isOpen} onClose={onCloseClick}>
      <StyledDialogTitle>
        <Column>
          <Gap size="_24px" />
          <Typography variant="h4">{t('filters.exportPDFModal.title')}</Typography>
        </Column>
        <StyledIconButton onClick={onCloseClick}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Column
          sx={{
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Gap size="_24px" />
          <Row
            sx={{
              width: '85%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="bodyDefaultBook">{t('filters.exportPDFModal.dateFromLabel')}</Typography>
            <Gap size="_24px" isHorizontal />
            <DatePicker
              textFieldProps={{ ...getFieldProps('dateFrom'), ...getFieldErrorProps('dateFrom') }}
              label=""
              selectedDate={values.dateFrom}
              onChange={onDateFromChange}
            />
          </Row>
          <Gap size="_24px" />
          <Row
            sx={{
              width: '85%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="bodyDefaultBook">{t('filters.exportPDFModal.dateToLabel')}</Typography>
            <Gap size="_24px" isHorizontal />
            <DatePicker
              textFieldProps={{ ...getFieldProps('dateTo'), ...getFieldErrorProps('dateTo') }}
              label=""
              selectedDate={values.dateTo}
              onChange={onDateToChange}
            />
          </Row>
        </Column>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton variant="outlined" size="large" onClick={onCloseClick}>
          <Typography variant="buttonMedium">{t('custodialAccountDetailsPage.suspendAccountModal.cancel')}</Typography>
        </StyledButton>
        <Gap isHorizontal size="_4px" />
        <StyledLoadingButtonWithSkeleton
          disabled={isFormDisabled}
          variant="contained"
          size="large"
          text={
            <Typography variant="buttonMedium" color="background.paper">
              {t('filters.exportButtonLabel')}
            </Typography>
          }
          loading={false}
          onClick={submitForm}
        />
      </StyledDialogActions>
    </StyledDialog>
  );
};
