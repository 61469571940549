import React from 'react';
import { Box } from '@mui/material';
import { SideMenuListItem } from 'ui-interfaces/side-menu/side-menu-list-item';
import { InfoColumn } from './InfoColumn';
import { FooterIcon } from './FooterIcon';
import { StyledButtonRow, StyledUserInfoRow } from './styled';
import LogOutButton from './LogOutButton';
import { SideMenuItem } from '../SideMenuItem';
import { StyledList } from '../styled';

type Props = {
  open: boolean;
  title: string;
  subtitle: string;
  onLogOut: () => void;
  helpListItem?: SideMenuListItem;
};

export const Footer: React.FC<Props> = ({ open, title, subtitle, onLogOut, helpListItem }) => {
  return (
    <Box>
      {helpListItem && (
        <StyledList>
          <SideMenuItem el={helpListItem} open={open} />
        </StyledList>
      )}
      <StyledUserInfoRow>
        <div>
          <FooterIcon isTooltipEnabled={!open} tooltipTitle={title} toolTipSubtitle={subtitle} />
        </div>
        {open && <InfoColumn title={title} subtitle={subtitle} />}
      </StyledUserInfoRow>
      <StyledButtonRow isSideBarExpanded={open}>
        <LogOutButton onClick={onLogOut} isSideBarExpanded={open} />
      </StyledButtonRow>
    </Box>
  );
};
