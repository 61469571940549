import { Paths } from 'elements/element-transfer/navigation/routes';
import {
  TransferDirectionEnum,
  DepositCoin,
  TransferFlow,
  TransferOptionsEnum,
  WireAccountNumbersFormData,
  WireBankInfoFormData,
  WireInstructionsState,
  WireRecipientInfoFormData,
  WireWithdrawalAdditionalDetails,
  InternalRecipientInfoFormData,
  CryptoAddressResponse,
} from 'elements/element-transfer/types';
import { PaymentMethodTypesMap } from 'elements/features/PaymentMethods/utils/getPaymentMethodTypesMap';
import {
  CustodialAccount,
  CustomFormFieldError,
  ExternalAccount,
  IdentityData,
  PaymentAccount,
  RecipientCustodialAccount,
} from 'elements/types';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';

type CryptoState<T> = {
  coinList: T[];
  selectedCoin: null | T;
  selectedAsset: AssetResponseModel | null;
  walletAddress: string;
  destinationTag: string;
};
export type CryptoSendState = CryptoState<CustodialAccountBalanceModel> & {
  amount: string;
  recipientId?: string;
  search: string;
  name?: string;
  recipients: RecipientsState<CryptoAddressResponse>;
  isManualInput: boolean;
};
export type CryptoReceiveState = CryptoState<DepositCoin>;

export type RecipientsState<T> = {
  list: T[];
  page: number;
  pageCount: number;
};

export type WireWithdrawalState = {
  recipientId?: string;
  amount: string;
  recipientInfo: WireRecipientInfoFormData;
  bankInfo: WireBankInfoFormData;
  accountNumbers: WireAccountNumbersFormData;
  routingNumberCustomError: CustomFormFieldError | null;
  note: string;
  memo: string;
  purposeOfPayment: string;
  isCloseConfirmationRequired: boolean;
  search: string;
  recipients: RecipientsState<ExternalAccount>;
};

export type InternalTransferState = {
  recipientId?: string;
  amount: string;
  recipientInfo: InternalRecipientInfoFormData;
  comment: string;
  search: string;
  recipients: RecipientsState<RecipientCustodialAccount>;
  coinList: CustodialAccountBalanceModel[];
  selectedCoin: CustodialAccountBalanceModel | null;
};

export type StateType = {
  [TransferFlow.Deposit]: {
    ACH: {
      amount: string;
      externalAccountList: ExternalAccount[];
      paymentAccount: PaymentAccount | null;
    };
    WIRE: {
      instructions: WireInstructionsState | null;
    };
    CRYPTO: CryptoReceiveState;
  };
  [TransferFlow.Withdrawal]: {
    ACH: null;
    WIRE: WireWithdrawalState;
    CRYPTO: CryptoSendState;
    INTERNAL: InternalTransferState;
  };
  transferDirection: TransferDirectionEnum;
  lockId: string;
  paymentType: PaymentType | null;
  flowType: TransferFlow;
  contactUsUrl: string | null;
  paymentMethodTypesMap: PaymentMethodTypesMap;
  transferOptionsList: Array<TransferOptionsEnum>;
  identityData: IdentityData | null;
  custodialAccount: CustodialAccount | null;
  custodialAccountName: string;
  isOrderPlaced: boolean;
  achDepositPaymentId: string | null;
  cryptoWithdrawalPaymentId: string | null;
  wireDomesticWithdrawalPaymentId: string | null;
  wireInternationalWithdrawalPaymentId: string | null;
  internalTransferPaymentId: string | null;
  custodialAccountId: string;
  identityId: string;
  page: { location: Paths; state?: any };
};

export enum ACTION {
  SET_FLOW = 'SET_FLOW',
  SET_IS_ORDER_PLACED = 'SET_IS_ORDER_PLACED',
  SET_CUSTODIAL_ACCOUNT = 'SET_CUSTODIAL_ACCOUNT',
  SET_IDENTITY_DATA = 'SET_IDENTITY_DATA',
  SET_PAGE = 'SET_PAGE',
  SET_PAYMENT_METHOD_TYPES_MAP = 'SET_PAYMENT_METHOD_TYPES_MAP',
  SET_TRANSFER_OPTIONS_LIST = 'SET_TRANSFER_OPTIONS_LIST',
  SET_TRANSFER_DIRECTION = 'SET_TRANSFER_DIRECTION',
  SET_LOCK_ID = 'SET_LOCK_ID',
  SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE',

  SET_ACH_DEPOSIT_PAYMENT_ID = 'SET_ACH_DEPOSIT_PAYMENT_ID',
  SET_CRYPTO_WITHDRAWAL_PAYMENT_ID = 'SET_CRYPTO_WITHDRAWAL_PAYMENT_ID',
  SET_WIRE_DOMESTIC_WITHDRAWAL_PAYMENT_ID = 'SET_WIRE_DOMESTIC_WITHDRAWAL_PAYMENT_ID',
  SET_WIRE_INTERNATIONAL_WITHDRAWAL_PAYMENT_ID = 'SET_WIRE_INTERNATIONAL_WITHDRAWAL_PAYMENT_ID',
  SET_INTERNAL_TRANSFER_PAYMENT_ID = 'SET_INTERNAL_TRANSFER_PAYMENT_ID',

  SET_DEPOSIT_ACH_AMOUNT = 'SET_DEPOSIT_ACH_AMOUNT',
  SET_DEPOSIT_ACH_EXTERNAL_ACCOUNTS = 'SET_DEPOSIT_ACH_EXTERNAL_ACCOUNTS',
  SET_DEPOSIT_ACH_PAYMENT_ACCOUNT = 'SET_DEPOSIT_ACH_PAYMENT_ACCOUNT',

  SET_DEPOSIT_WIRE_INSTRUCTIONS = 'SET_DEPOSIT_WIRE_INSTRUCTIONS',

  SET_DEPOSIT_CRYPTO_COIN_LIST = 'SET_DEPOSIT_CRYPTO_COIN_LIST',
  SET_DEPOSIT_CRYPTO_SELECTED_COIN = 'SET_DEPOSIT_CRYPTO_SELECTED_COIN',
  SET_DEPOSIT_SELECTED_ASSET = 'SET_DEPOSIT_SELECTED_ASSET',
  SET_DEPOSIT_CRYPTO_WALLET_ADDRESS = 'SET_DEPOSIT_CRYPTO_WALLET_ADDRESS',

  SET_WITHDRAWAL_WIRE_RECIPIENT_INFO = 'SET_WITHDRAWAL_WIRE_RECIPIENT_INFO',
  RESET_WITHDRAWAL_WIRE_RECIPIENT_INFO = 'RESET_WITHDRAWAL_WIRE_RECIPIENT_INFO',
  SET_WITHDRAWAL_WIRE_BANK_INFO = 'SET_WITHDRAWAL_WIRE_BANK_INFO',
  RESET_WITHDRAWAL_WIRE_BANK_INFO = 'RESET_WITHDRAWAL_WIRE_BANK_INFO',
  SET_WITHDRAWAL_DOMESTIC_WIRE_ACCOUNT_NUMBERS = 'SET_WITHDRAWAL_DOMESTIC_WIRE_ACCOUNT_NUMBERS',
  SET_WITHDRAWAL_WIRE_ADDITIONAL_DETAILS = 'SET_WITHDRAWAL_WIRE_ADDITIONAL_DETAILS',
  RESET_WITHDRAWAL_WIRE_ACCOUNT_NUMBERS = 'RESET_WITHDRAWAL_WIRE_ACCOUNT_NUMBERS',
  SET_WITHDRAWAL_WIRE_ROUTING_NUMBER_CUSTOM_ERROR = 'SET_WITHDRAWAL_WIRE_ROUTING_NUMBER_CUSTOM_ERROR',
  SET_WITHDRAWAL_WIRE_AMOUNT = 'SET_WITHDRAWAL_WIRE_AMOUNT',
  SET_WITHDRAWAL_WIRE_CLOSE_CONFIRMATION_REQUIRED = 'SET_WITHDRAWAL_WIRE_CLOSE_CONFIRMATION_REQUIRED',
  RESET_WITHDRAWAL_WIRE_STATE = 'RESET_WITHDRAWAL_WIRE_STATE',
  SET_WITHDRAWAL_WIRE_RECIPIENT_SEARCH_VALUE = 'SET_WITHDRAWAL_WIRE_RECIPIENT_SEARCH_VALUE',
  SET_WITHDRAWAL_WIRE_RECIPIENTS = 'SET_WITHDRAWAL_WIRE_RECIPIENTS',
  RESET_WITHDRAWAL_WIRE_RECIPIENTS = 'RESET_WITHDRAWAL_WIRE_RECIPIENTS',
  MERGE_WITHDRAWAL_WIRE_RECIPIENT_DATA = 'MERGE_WITHDRAWAL_WIRE_RECIPIENT_DATA',
  RESET_WITHDRAWAL_WIRE_RECIPIENT_DATA = 'RESET_WITHDRAWAL_WIRE_RECIPIENT_DATA',

  SET_WITHDRAWAL_CRYPTO_COIN_LIST = 'SET_WITHDRAWAL_CRYPTO_COIN_LIST',
  SET_WITHDRAWAL_CRYPTO_SELECTED_COIN = 'SET_WITHDRAWAL_CRYPTO_SELECTED_COIN',
  SET_WITHDRAWAL_CRYPTO_COIN_AMOUNT = 'SET_WITHDRAWAL_CRYPTO_COIN_AMOUNT',
  SET_WITHDRAWAL_CRYPTO_WALLET_ADDRESS = 'SET_WITHDRAWAL_CRYPTO_WALLET_ADDRESS',
  SET_WITHDRAWAL_CRYPTO_DESTINATION_TAG = 'SET_WITHDRAWAL_CRYPTO_DESTINATION_TAG',
  SET_WITHDRAWAL_CRYPTO_RECIPIENT_NAME = 'SET_WITHDRAWAL_CRYPTO_RECIPIENT_NAME',

  MERGE_INTERNAL_TRANSFER_RECIPIENT_DATA = 'MERGE_INTERNAL_TRANSFER_RECIPIENT_DATA',
  RESET_INTERNAL_TRANSFER_RECIPIENT_DATA = 'RESET_INTERNAL_TRANSFER_RECIPIENT_DATA',
  RESET_INTERNAL_TRANSFER_RECIPIENTS = 'RESET_INTERNAL_TRANSFER_RECIPIENTS',
  SET_INTERNAL_TRANSFER_RECIPIENTS = 'SET_INTERNAL_TRANSFER_RECIPIENTS',
  SET_INTERNAL_TRANSFER_RECIPIENT_SEARCH_VALUE = 'SET_INTERNAL_TRANSFER_RECIPIENT_SEARCH_VALUE',
  SET_INTERNAL_TRANSFER_COIN_LIST = 'SET_INTERNAL_TRANSFER_COIN_LIST',
  SET_INTERNAL_TRANSFER_SELECTED_COIN = 'SET_INTERNAL_TRANSFER_SELECTED_COIN',
  SET_INTERNAL_TRANSFER_COIN_AMOUNT = 'SET_INTERNAL_TRANSFER_COIN_AMOUNT',
  SET_INTERNAL_TRANSFER_COMMENT = 'SET_INTERNAL_TRANSFER_COMMENT',
  SET_CUSTODIAL_ACCOUNT_NAME = 'SET_CUSTODIAL_ACCOUNT_NAME',

  SET_CRYPTO_WITHDRAWAL_RECIPIENTS = 'SET_CRYPTO_WITHDRAWAL_RECIPIENTS',
  RESET_CRYPTO_WITHDRAWAL_RECIPIENTS = 'RESET_CRYPTO_WITHDRAWAL_RECIPIENTS',
  MERGE_CRYPTO_WITHDRAWAL_RECIPIENT_DATA = 'MERGE_CRYPTO_WITHDRAWAL_RECIPIENT_DATA',
  RESET_CRYPTO_WITHDRAWAL_RECIPIENT_DATA = 'RESET_CRYPTO_WITHDRAWAL_RECIPIENT_DATA',
  SET_CRYPTO_WITHDRAWAL_RECIPIENT_SEARCH_VALUE = 'SET_CRYPTO_WITHDRAWAL_RECIPIENT_SEARCH_VALUE',
  SET_CRYPTO_WITHDRAWAL_IS_MANUAL_VALUE = 'SET_CRYPTO_WITHDRAWAL_IS_MANUAL_VALUE',
}

export type ActionType =
  | {
      type: ACTION.SET_FLOW;
      payload: TransferFlow;
    }
  | {
      type: ACTION.SET_DEPOSIT_ACH_AMOUNT;
      payload: string;
    }
  | {
      type: ACTION.SET_ACH_DEPOSIT_PAYMENT_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_CRYPTO_WITHDRAWAL_PAYMENT_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_WIRE_DOMESTIC_WITHDRAWAL_PAYMENT_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_WIRE_INTERNATIONAL_WITHDRAWAL_PAYMENT_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_INTERNAL_TRANSFER_PAYMENT_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_CUSTODIAL_ACCOUNT;
      payload: CustodialAccount;
    }
  | {
      type: ACTION.SET_IDENTITY_DATA;
      payload: IdentityData;
    }
  | {
      type: ACTION.SET_WITHDRAWAL_CRYPTO_COIN_LIST;
      payload: CustodialAccountBalanceModel[];
    }
  | {
      type: ACTION.SET_WITHDRAWAL_CRYPTO_SELECTED_COIN;
      payload: CustodialAccountBalanceModel | null;
    }
  | {
      type: ACTION.SET_WITHDRAWAL_CRYPTO_COIN_AMOUNT;
      payload: string;
    }
  | {
      type: ACTION.SET_WITHDRAWAL_CRYPTO_WALLET_ADDRESS;
      payload: string;
    }
  | {
      type: ACTION.SET_WITHDRAWAL_CRYPTO_DESTINATION_TAG;
      payload: string;
    }
  | {
      type: ACTION.SET_WITHDRAWAL_CRYPTO_RECIPIENT_NAME;
      payload: string;
    }
  | { type: ACTION.SET_CRYPTO_WITHDRAWAL_RECIPIENTS; payload: RecipientsState<CryptoAddressResponse> }
  | { type: ACTION.RESET_CRYPTO_WITHDRAWAL_RECIPIENTS }
  | { type: ACTION.MERGE_CRYPTO_WITHDRAWAL_RECIPIENT_DATA; payload: CryptoAddressResponse }
  | { type: ACTION.RESET_CRYPTO_WITHDRAWAL_RECIPIENT_DATA }
  | { type: ACTION.SET_CRYPTO_WITHDRAWAL_IS_MANUAL_VALUE; payload: boolean }
  | {
      type: ACTION.SET_CRYPTO_WITHDRAWAL_RECIPIENT_SEARCH_VALUE;
      payload: string;
    }
  | {
      type: ACTION.SET_DEPOSIT_ACH_EXTERNAL_ACCOUNTS;
      payload: ExternalAccount[];
    }
  | {
      type: ACTION.SET_DEPOSIT_ACH_PAYMENT_ACCOUNT;
      payload: PaymentAccount;
    }
  | {
      type: ACTION.SET_WITHDRAWAL_WIRE_AMOUNT;
      payload: string;
    }
  | {
      type: ACTION.SET_WITHDRAWAL_WIRE_CLOSE_CONFIRMATION_REQUIRED;
      payload: boolean;
    }
  | {
      type: ACTION.SET_DEPOSIT_WIRE_INSTRUCTIONS;
      payload: WireInstructionsState | null;
    }
  | {
      type: ACTION.SET_IS_ORDER_PLACED;
      payload: boolean;
    }
  | { type: ACTION.SET_WITHDRAWAL_WIRE_RECIPIENT_INFO; payload: WireRecipientInfoFormData }
  | { type: ACTION.RESET_WITHDRAWAL_WIRE_RECIPIENT_INFO }
  | { type: ACTION.SET_WITHDRAWAL_WIRE_BANK_INFO; payload: WireBankInfoFormData }
  | { type: ACTION.RESET_WITHDRAWAL_WIRE_BANK_INFO }
  | {
      type: ACTION.SET_WITHDRAWAL_DOMESTIC_WIRE_ACCOUNT_NUMBERS;
      payload: WireAccountNumbersFormData;
    }
  | { type: ACTION.RESET_WITHDRAWAL_WIRE_ACCOUNT_NUMBERS }
  | {
      type: ACTION.SET_WITHDRAWAL_WIRE_ROUTING_NUMBER_CUSTOM_ERROR;
      payload: CustomFormFieldError | null;
    }
  | {
      type: ACTION.SET_WITHDRAWAL_WIRE_ADDITIONAL_DETAILS;
      payload: WireWithdrawalAdditionalDetails;
    }
  | { type: ACTION.RESET_WITHDRAWAL_WIRE_STATE }
  | {
      type: ACTION.SET_WITHDRAWAL_WIRE_RECIPIENTS;
      payload: RecipientsState<ExternalAccount>;
    }
  | {
      type: ACTION.RESET_WITHDRAWAL_WIRE_RECIPIENTS;
    }
  | {
      type: ACTION.SET_WITHDRAWAL_WIRE_RECIPIENT_SEARCH_VALUE;
      payload: string;
    }
  | {
      type: ACTION.MERGE_WITHDRAWAL_WIRE_RECIPIENT_DATA;
      payload: ExternalAccount;
    }
  | {
      type: ACTION.RESET_WITHDRAWAL_WIRE_RECIPIENT_DATA;
    }
  | {
      type: ACTION.SET_DEPOSIT_CRYPTO_COIN_LIST;
      payload: DepositCoin[];
    }
  | {
      type: ACTION.SET_DEPOSIT_CRYPTO_SELECTED_COIN;
      payload: DepositCoin | null;
    }
  | {
      type: ACTION.SET_DEPOSIT_SELECTED_ASSET;
      payload: AssetResponseModel | null;
    }
  | {
      type: ACTION.SET_DEPOSIT_CRYPTO_WALLET_ADDRESS;
      payload: string;
    }
  | {
      type: ACTION.SET_PAGE;
      payload: { location: Paths; state?: any };
    }
  | {
      type: ACTION.SET_PAYMENT_METHOD_TYPES_MAP;
      payload: PaymentMethodTypesMap;
    }
  | {
      type: ACTION.SET_TRANSFER_OPTIONS_LIST;
      payload: Array<TransferOptionsEnum>;
    }
  | {
      type: ACTION.SET_TRANSFER_DIRECTION;
      payload: TransferDirectionEnum;
    }
  | {
      type: ACTION.SET_LOCK_ID;
      payload: string;
    }
  | {
      type: ACTION.SET_PAYMENT_TYPE;
      payload: PaymentType;
    }
  | {
      type: ACTION.SET_INTERNAL_TRANSFER_RECIPIENTS;
      payload: RecipientsState<RecipientCustodialAccount>;
    }
  | {
      type: ACTION.RESET_INTERNAL_TRANSFER_RECIPIENTS;
    }
  | {
      type: ACTION.SET_INTERNAL_TRANSFER_RECIPIENT_SEARCH_VALUE;
      payload: string;
    }
  | {
      type: ACTION.MERGE_INTERNAL_TRANSFER_RECIPIENT_DATA;
      payload: RecipientCustodialAccount;
    }
  | {
      type: ACTION.RESET_INTERNAL_TRANSFER_RECIPIENT_DATA;
    }
  | {
      type: ACTION.SET_INTERNAL_TRANSFER_COIN_LIST;
      payload: CustodialAccountBalanceModel[];
    }
  | {
      type: ACTION.SET_INTERNAL_TRANSFER_SELECTED_COIN;
      payload: CustodialAccountBalanceModel | null;
    }
  | {
      type: ACTION.SET_INTERNAL_TRANSFER_COIN_AMOUNT;
      payload: string;
    }
  | {
      type: ACTION.SET_INTERNAL_TRANSFER_COMMENT;
      payload: string;
    }
  | { type: ACTION.SET_CUSTODIAL_ACCOUNT_NAME; payload: string };
