import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Gap } from 'components/shared/Gap';
import { Column } from 'components/shared/Flex/Column';
import { Row } from 'components/shared/Flex/Row';
import {
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
  StyledLoadingButtonWithSkeleton,
  StyledNumberField,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  page: string;
  pageSize: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  setPageSize: React.Dispatch<React.SetStateAction<string>>;
  onExportSubmit: () => void;
};

export const ExportCSVModal: React.FC<Props> = ({
  isOpen,
  onClose,
  page,
  pageSize,
  setPage,
  setPageSize,
  onExportSubmit,
}) => {
  const { t } = useTranslation();

  const onPageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPage(e.target.value);
  };

  const onPageSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPageSize(e.target.value);
  };

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle>
        <Column>
          <Gap size="_24px" />
          <Typography variant="h4">{t('filters.exportCSVModal.title')}</Typography>
        </Column>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Column
          sx={{
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Gap size="_24px" />
          <Row
            sx={{
              width: '60%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="bodyDefaultBook">{t('filters.exportCSVModal.pageLabel')}</Typography>
            <Gap size="_24px" isHorizontal />
            <StyledNumberField
              inputProps={{
                decimalScale: 0,
              }}
              name="page"
              value={page}
              onChange={onPageChange}
              placeholder={t('createUserDialog.maxValueOfTransactionsNumberFieldPlaceholder')}
            />
          </Row>
          <Gap size="_24px" />
          <Row
            sx={{
              width: '60%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="bodyDefaultBook">{t('filters.exportCSVModal.pageSizeLabel')}</Typography>
            <Gap size="_24px" isHorizontal />
            <StyledNumberField
              inputProps={{
                decimalScale: 0,
              }}
              name="page"
              value={pageSize}
              onChange={onPageSizeChange}
              placeholder={t('createUserDialog.maxValueOfTransactionsNumberFieldPlaceholder')}
            />
          </Row>
        </Column>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton variant="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">{t('custodialAccountDetailsPage.suspendAccountModal.cancel')}</Typography>
        </StyledButton>
        <Gap isHorizontal size="_4px" />
        <StyledLoadingButtonWithSkeleton
          variant="contained"
          size="large"
          text={
            <Typography variant="buttonMedium" color="background.paper">
              {t('filters.exportButtonLabel')}
            </Typography>
          }
          loading={false}
          onClick={onExportSubmit}
        />
      </StyledDialogActions>
    </StyledDialog>
  );
};
