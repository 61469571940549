export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const AUTHORIZATION_URL = `https://${process.env.REACT_APP_AUTH0_DOMAIN}`;

export const API_ROUTES = {
  private: {
    ASSETS: '/assets',
    TRANSACTIONS: '/transactions',
    TRANSACTION: '/transactions/:transactionId',
    WALLETS: '/wallets',
    WALLET: '/wallets/:walletId',
    WALLET_ASSETS: '/wallets/:walletId/assets',
    WALLET_TOKENS: '/wallets/:walletId/tokens',
    WALLET_TOKEN: '/wallets/:walletId/tokens/:tokenId',
    WALLET_TRANSACTIONS: '/wallet-transactions',
    WALLET_TRANSACTION: '/wallet-transactions/:transactionId',
    USERS_ME: '/users/me',
    USERS: '/users',
    USER_DETAILS: '/users/:userId',
    USER_ROLE_TEMPLATES: '/users/role-templates',
    PERSONAL_IDENTITIES: '/personal-identities',
    PERSONAL_IDENTITY: '/personal-identities/:identityId',
    PERSONAL_IDENTITY_DOCUMENTS: '/personal-identities/:identityId/documents',
    PERSONAL_IDENTITY_VERIFY: '/personal-identities/:identityId/verify',
    BUSINESS_IDENTITIES: '/business-identities',
    BUSINESS_IDENTITY: '/business-identities/:identityId',
    BUSINESS_IDENTITY_DOCUMENTS: '/business-identities/:identityId/documents',
    BUSINESS_IDENTITY_BENEFICIAL_OWNERS: '/business-identities/:identityId/beneficial-owners',
    BUSINESS_IDENTITY_VERIFY: '/business-identities/:identityId/verify',
    CUSTODIAL_ACCOUNTS: '/custodial-accounts',
    CUSTODIAL_ACCOUNT: '/custodial-accounts/:custodialAccountId',
    CUSTODIAL_ACCOUNT_BALANCES: '/custodial-accounts/:custodialAccountId/balances',
    CUSTODIAL_ACCOUNT_CUSTOM_ASSET_BALANCES: '/custodial-accounts/:custodialAccountId/custom-asset-balances',
    CUSTODIAL_ACCOUNT_BENEFICIARIES: '/custodial-accounts/:custodialAccountId/beneficiaries',
    CUSTODIAL_ACCOUNT_VERIFY: '/custodial-accounts/:custodialAccountId/verify',
    CUSTODIAL_ACCOUNT_CRYPTO_DEPOSIT_INSTRUCTIONS:
      '/custodial-accounts/:custodialAccountId/crypto-deposit-instructions/:network/:asset',
    CUSTODIAL_ACCOUNT_JWT: '/custodial-accounts/:custodialAccountId/jwt/:elementType',
    CUSTODIAL_ACCOUNT_STATEMENT: '/custodial-accounts/:custodialAccountId/statement',
    INITIALIZED_PAYMENT: '/payments/:paymentId/initialized',
    APPROVE_PAYMENT: '/payments/:paymentId/approve',
    REJECT_PAYMENT: '/payments/:paymentId/reject',
    RESET_PASSWORD: '/users/:userId/reset-password',
    CLOSE_JWT: '/users/close-jwt',
    TRY_LOCK: '/current/current/try-clock',
    CANCEL_LOCK: '/current/current/cancel-clock/:lockId',
    CURRENT_CUSTODIAL_ACCOUNT: '/current/:custodialAccountId/custodial-account',
    CURRENT_IDENTITY: '/current/:custodialAccountId/identity',
    EXTERNAL_ACCOUNT: '/current/:identityId/external-accounts/:externalAccountId',
    CRYPTO_PRICE: '/trades/:network/:asset/crypto-price',
    BUY: '/trades/buy',
    SELL: '/trades/sell',
    DEPOSIT: '/transfers/deposit',
    WITHDRAW: '/transfers/withdrawal',
    WIRE_WITHDRAW: '/transfers/wire-withdrawal',
    WITHDRAW_CRYPTO: '/transfers/withdraw-crypto',
    INTERNATIONAL_WIRE_WITHDRAW: '/transfers/international-wire-withdrawal',
  },
  public: {
    OAUTH_TOKEN: '/oauth/token',
  },
};

export const PRIVATE_ROUTES = Object.values(API_ROUTES.private);
