import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Divider, Stack, CountryImage } from 'elements/components';
import { COUNTRY_CODE_US, rawCountriesObjectByCountryCode } from 'elements/constants';
import {
  StyledBankAccountIcon,
  StyledBankInfoContainer,
  StyledContainer,
  StyledNameContainer,
  StyledNoWrapTypography,
  StyledTruncatedCountryTypography,
  StyledTruncatedTypography,
} from './styled';

type Props = {
  recipientName: string;
  bankName: string;
  bankCountry: string;
  accountNumber: string;
};

const WireTo: React.FC<Props> = ({ recipientName, bankName, bankCountry, accountNumber }) => {
  const { t } = useTranslation();

  const postfix = `*${accountNumber.slice(-4)}`;

  const countryObject = rawCountriesObjectByCountryCode[bankCountry as keyof typeof rawCountriesObjectByCountryCode];

  return (
    <StyledContainer>
      <StyledNameContainer>
        <StyledBankAccountIcon />
        <Stack gap="6px">
          <Typography variant="captionMedium" color="secondary.font">
            {t('elements.transfer.withdrawalWireReviewPage.sendTo')}
          </Typography>
          <Typography variant="bodyDefaultMedium" color="primary.font">
            {recipientName}
          </Typography>
        </Stack>
      </StyledNameContainer>
      <Divider />
      <StyledBankInfoContainer paddingTop="16px" paddingBottom="8px">
        <StyledNoWrapTypography variant="bodyDefaultBook" color="primary.font">
          {t('elements.transfer.withdrawalWireReviewPage.financialInstitution')}
        </StyledNoWrapTypography>
        <StyledTruncatedTypography variant="bodyDefaultMedium" color="primary.font">
          {bankName}
        </StyledTruncatedTypography>
      </StyledBankInfoContainer>
      <Divider />
      <StyledBankInfoContainer paddingTop="8px" paddingBottom="8px">
        <StyledNoWrapTypography variant="bodyDefaultBook" color="primary.font">
          {t('elements.transfer.withdrawalWireReviewPage.bankCountry')}
        </StyledNoWrapTypography>
        {countryObject && (
          <StyledTruncatedCountryTypography variant="bodyDefaultMedium" color="primary.font">
            <CountryImage
              height={20}
              width={28}
              borderRadius={2}
              code={countryObject.code}
              label={countryObject.label}
            />
            {countryObject.code === COUNTRY_CODE_US
              ? t('elements.transfer.withdrawalWireReviewPage.bankCountryUS')
              : countryObject.label}
          </StyledTruncatedCountryTypography>
        )}
      </StyledBankInfoContainer>
      <Divider />
      <StyledBankInfoContainer paddingTop="8px" paddingBottom="16px">
        <Typography variant="bodyDefaultBook" color="primary.font">
          {t('elements.transfer.withdrawalWireReviewPage.accountNumber')}
        </Typography>
        <Stack flexDirection="row" gap={0.5}>
          <Typography variant="bodyDefaultBook" color="primary.font">
            {t('elements.transfer.withdrawalWireReviewPage.endingIn')}
          </Typography>
          <Typography variant="bodyDefaultMedium" color="primary.font">
            {postfix}
          </Typography>
        </Stack>
      </StyledBankInfoContainer>
    </StyledContainer>
  );
};

export default WireTo;
