export default {
  common: {
    loginPageTitle: 'loginPageTitle',
    validation: {
      fieldIsRequired: 'This field is required.',
      fieldIsTooLong: 'Max. limit: {{max}} characters.',
      fieldIsNotEmail: 'Email format isn’t valid. Please try again.',
      multipleDailyLimits: 'You cannot set up multiple Daily limits.',
      multipleWeeklyLimits: 'You cannot set up multiple Weekly limits.',
      multipleMonthlyLimits: 'You cannot set up multiple Monthly limits.',
      multipleTransactionLimits: 'You cannot set up multiple Per Transaction limits.',
      dailyLimitExceedsWeeklyMonthly: 'Daily limit cannot exceed Weekly/Monthly limit.',
      weeklyLimitExceedsMonthly: 'Weekly limit cannot exceed Monthly limit.',
      transactionLimitExceedsDailyWeeklyMonthly: 'Transaction limit cannot exceed Daily/Weekly/Monthly limit.',
      accountNameMustBe50CharactersOrLess: 'Account name must be 50 characters or less.',
      suspensionReason: {
        minMaxErrorMsg: 'Suspension reason should be from 4 to 50 symbols',
      },
      unSuspensionReason: {
        minMaxErrorMsg: 'Unsuspension reason should be from 4 to 50 symbols',
      },
    },
    loginPageEmailLabel: 'Email',
    loginPagePasswordLabel: 'Password',
    loginPageButtonLabel: 'Log in',
    loginPageContactUsLabel: 'Contact us',
    loginPageTitle1: 'Welcome to the',
    loginPageTitle2: 'Instant Payments',
    loginPageTitle3: 'Dashboard',
    loginPageDescription:
      'Instant Payments Dashboard is a secure and convenient tool for storing & tracking funds, as well as seamlessly conducting operations with various assets.',
    loginBlockTitle: 'Log In',
    loginBlockSubTitle: 'Log in to your Integrator Dashboard account',
    offlineNetworkMessage: 'Oops, we’re having some connection issues. Please try again.',
    emailOrPasswordIncorrect: 'Your email or password is incorrect.',
    serverErrorBanner: 'Oops, we can’t connect to the server. Please try again.',
    serverError: {
      transactionlimitsError:
        'You cannot initiate this operation since either your transaction limit has been exceeded or it has been updated by admin.',
      limitsError:
        'You have reached your approval limit. Please reach out to an Administrator if you have any questions.',
      paymentAlreadyRejected:
        'You cannot approve or reject this transaction as it has already been authorized. Please reload the page to see the actual transaction state. ',
      validationError: 'One or more input fields are invalid. Please make adjustments to continue.',
      jwtTokenIsAlreadyInUse:
        'You cannot initiate 2 payments simultaneously. Please complete or cancel another payment to proceed.',
      transferIsForbiddenForAssetPairs: 'Transfer is forbidden for this asset pair.',
      notFound: 'Not Found',
      somethingWentWrong: 'Something went wrong',
      withdrawalAmountIsLowerRequiredMinAccountBalance:
        'Withdrawal amount is lower than required minimal account balance.',
      withdrawalAmountIsLowerRequiredMinTransactionBalance:
        'Withdrawal amount is lower than required minimal transaction amount.',
      balanceOfWalletForNativeCoinIsNotSufficient: 'Balance of a wallet for a native coin is not sufficient.',
      pageSizeMustBeLessOrEqualThan500: "'Page Size' must be less or equal than '5000'.",
      badRequest: 'Something went wrong',
      functionalityIsNotAvailableForOrganization: 'This functionality is unavailable for your Organization',
      addressAlreadyExists: 'An address with this name already exists. Please enter a unique address.',
    },
    accessForbiddenMessage: 'Your permissions have been changed, certain features may be disabled or enabled.',
    identitiesPage: {
      title: 'Identities',
      noResultsTitle: {
        personal: 'No personal identities yet',
        business: 'No business identities yet',
      },
      noResultsSubtitle: {
        personal: 'It seems that no personal identities were created yet.',
        business: 'It seems that no business identities were created yet.',
      },
      gridTitle: {
        personal: 'Personal identities',
        business: 'Business identities',
      },
      personal: 'Personal',
      business: 'Business',
      firstName: 'First name',
      companyName: 'Company name',
      lastName: 'Last name',
      id: 'ID',
      email: 'Email',
      phone: 'Phone',
      kycLevel: 'KYC level',
      kybLevel: 'KYB level',
      status: 'Status',
      dateCreated: 'Date created',
      levels: {
        l0: 'L0',
        l1: 'L1',
        l2: 'L2',
      },
      statuses: {
        active: 'Active',
        inactive: 'Inactive',
      },
    },
    custodialAccountsPage: {
      title: 'Accounts',
      gridTitle: 'Accounts',
      userCustodialAccountsGridTitle: 'User Accounts',
      corporateCustodialAccountsGridTitle: 'Corporate Accounts',
      accountId: 'Account ID',
      accountNumber: 'Account number',
      accountType: 'Account type',
      owner: 'Account owner',
      name: 'Account name',
      noName: '--',
      dateOpened: 'Date opened',
      usdBalance: 'USD balance',
      usdBalanceToolTipMsg: {
        part1: 'USD balance only. Crypto',
        part2: 'is not included.',
      },
      lastActivity: 'Last activity',
      status: 'Status',
      statuses: {
        open: 'Open',
        closed: 'Closed',
        suspended: 'Suspended',
        pending: 'Pending',
      },
      noActivity: 'No activity yet',
      noResultsTitle: 'No accounts yet',
      noResultsSubtitle: 'It seems that no accounts were opened yet.',
      breadcrumbs: {
        accounts: 'Accounts',
        account: 'Account details',
      },
      assets: 'Assets',
      asset: 'Asset',
      noAssets: 'No assets',
      type: {
        personal: 'Personal',
        business: 'Business',
        rothIra: 'Roth IRA',
        traditionalIra: 'Traditional IRA',
        sepIra: 'SEP IRA',
        simpleIra: 'SIMPLE IRA',
      },
      tabs: {
        user: 'User',
        corporate: 'Corporate',
      },
    },
    transactionsPage: {
      tabs: {
        all: 'All transactions',
        approvalNeeded: 'Approval needed',
        rejected: 'Rejected',
      },
      title: 'Transactions',
      gridTitle: 'Transactions',
      noResultsTitle: 'No transactions yet',
      noResultsSubtitle: 'It seems that no transactions have been made yet.',
      transactionId: 'Transaction ID',
      paymentId: 'Payment ID',
      date: 'Date created',
      type: 'Type',
      source: 'Source',
      destination: 'Destination',
      status: 'Status',
      asset: 'Asset',
      name: 'Name',
      amount: 'Amount',
      account: 'Account',
      wallet: 'Wallet',
      externalAccount: 'Ext.account',
      internalTransfer: 'Internal transfer',
      deposit: 'Deposit',
      reverseDeposit: 'Reverse deposit',
      returnDeposit: 'Return deposit',
      wire: 'Wire',
      domesticWire: 'Domestic wire',
      domesticWireDetails: 'Domestic wire (USA)',
      internationalWire: 'Intl. wire',
      withdrawal: 'Withdrawal',
      withdrawalReturn: 'Return withdrawal ',
      reverseWithdrawal: 'Reverse withdrawal',
      initialDeposit: 'Initial deposit',
      preciousMetals: 'Precious metal',
      manuallyProcessed: 'Manually processed',
      buy: 'Buy',
      sell: 'Sell',
      ach: 'ACH',
      card: 'Card',
      check: 'Check',
      usd: 'USD',
      defaultAssetTicker: 'DefaultAssetTicker',
      defaultAssetName: 'DefaultAssetName',
      noData: 'N/A',
      cryptoType: 'Crypto',
      fiatType: 'Fiat',
      trading: 'Trading',
      privateSecurities: 'Private securities',
      achWithdrawalTooltipMsgPart1: 'ACH bank transfer system typically takes',
      achWithdrawalTooltipMsgPart2: '3-5 business days to complete after initiating withdrawals.',
      defaultCreatedAtFilter:
        'Please note, that only transactions for the last 7 days are displayed. Please set another filter if needed.',
      grid: {
        status: {
          success: 'Success',
          processing: 'Processing',
          fail: 'Fail',
          pending: 'Pending',
          approvalNeeded: 'Approval needed',
          rejected: 'Rejected',
          canceled: 'Canceled',
        },
      },
      utcTimeZone: 'UTC timezone',
      depositCardTooltipMessage: 'The transaction is being executed.',
      crypto: {
        buy: {
          tooltipMessage: {
            pending: 'The order is being executed. Funds get locked unless the order is either executed or canceled.',
            processing: 'The order is being settled. Settlement may take up to 24 hours.',
          },
        },
        withdrawal: {
          tooltipMessage: {
            processing: 'The transaction has not been confirmed on the blockchain yet.',
          },
        },
      },
    },
    error: {
      title: 'Something went wrong',
      subtitle: "Page can't be loaded right now. Please try again later.",
      subtitleOr: 'Page can’t be loaded right now. Please try again later or ',
      contactUs: 'contact us',
    },
    identityDetailsPage: {
      tabs: {
        detailsTab: 'Details',
        accountsTab: 'Accounts',
        walletsTab: 'Wallets',
      },
      breadcrumbs: {
        identities: 'Identities',
        identityDetails: 'Identity details',
        beneficialOwnerDetails: 'Beneficial owner details',
        beneficiaryDetails: 'Beneficiary details',
        accountOwnerDetails: 'Account Owner Details',
      },
      types: {
        personal: 'Personal',
        business: 'Business',
      },
      kycLevels: {
        l0: 'L0',
        l1: 'L1',
        l2: 'L2',
      },
      dateCreated: 'Date created',
      lastUpdate: 'Last update',
      lastUpdateTooltip: 'Date the identity was last modified.',
      identityId: 'Identity ID',
      identityDetailsTitle: 'Identity details',
      identityType: 'Identity type',
      detailsLabels: {
        firstName: 'First name',
        lastName: 'Last name',
        middleName: 'Middle name',
        email: 'Email',
        phoneNumber: 'Phone number',
        dateOfBirth: 'Date of birth',
        ssn: 'SSN',
        address: 'Address',
        companyName: 'Company name',
        webSite: 'Website',
        ein: 'EIN',
        regionOfFormation: 'Region of formation',
        description: 'Description',
        establishedOn: 'Established on',
        mailingAddress: 'Mailing Address',
        naics: 'NAICS',
        naicsDescription: 'NAICS description',
        legalStructure: 'Legal structure',
        industry: 'Industry',
      },
      legalStructure: {
        ccorp: 'C corporation',
        llc: 'LLC',
        partnership: 'Partnership',
        nonprofit: 'Nonprofit',
        scorp: 'S corporation',
        soleprop: 'Sole proprietorship',
      },
      industry: {
        b2B: 'B2B',
        b2C: 'B2C',
        accounting: 'Accounting',
        aerospace: 'Aerospace',
        agriculture: 'Agriculture',
        aquaticsMarine: 'Aquatics Marine',
        automotive: 'Automotive',
        businessServices: 'Business Services',
        charity: 'Charity',
        computerSoftware: 'Computer Software',
        construction: 'Construction',
        cryptoBlockchain: 'Crypto Blockchain',
        education: 'Education',
        energy: 'Energy',
        engineering: 'Engineering',
        entertainment: 'Entertainment',
        environmentGreen: 'Environment Green',
        farming: 'Farming',
        fashion: 'Fashion',
        financialServices: 'Financial Services',
        franchise: 'Franchise',
        gaming: 'Gaming',
        gigEconomy: 'Gig Economy',
        government: 'Government',
        hospitality: 'Hospitality',
        informationTechnology: 'Information Technology',
        insurance: 'Insurance',
        internet: 'Internet',
        international: 'International',
        lawEnforcementMilitary: 'Law Enforcement Military',
        lifestyle: 'Lifestyle',
        manufacturing: 'Manufacturing',
        marketingServices: 'Marketing Services',
        mediaPublishing: 'Media Publishing',
        medical: 'Medical',
        mobileApps: 'Mobile Apps',
        music: 'Music',
        naturalResources: 'Natural Resources',
        networkMarketing: 'Network Marketing',
        performingArts: 'Performing Arts',
        personalServices: 'Personal Services',
        professionalServices: 'Professional Services',
        realEstate: 'Real Estate',
        recreation: 'Recreation',
        retail: 'Retail',
        royalties: 'Royalties',
        science: 'Science',
        securities: 'Securities',
        shippingTransportation: 'Shipping Transportation',
        software: 'Software',
        storageWarehousing: 'Storage Warehousing',
        supplier: 'Supplier',
        technology: 'Technology',
        transportation: 'Transportation',
        wholesale: 'Wholesale',
        art: 'Art',
      },
      emptyFields: {
        industry: '-',
      },
      suspendIdentityModal: {
        suspendIdentity: 'Suspend Identity',
        unSuspendIdentity: 'Unsuspend Identity',
        suspendCaption:
          'Are you sure you want to suspend this identity? Users will not be able to take any actions on any of their accounts.',
        unSuspendCaption:
          'Are you sure you want to unsuspend this identity? User will have the ability to trade and transfer.',
        identityId: 'Identity ID:',
        suspensionReason: 'Suspension Reason',
        unSuspensionReason: 'Unsuspension Reason',
        suspendTextAreaPlaceholder: 'Enter suspension reason here',
        unSuspendTextAreaPlaceholder: 'Enter unsuspension reason here',
        cancel: 'Cancel',
        suspend: 'Suspend',
        unSuspend: 'Unsuspend',
      },
      suspendActions: {
        suspend: 'Suspend',
        unSuspend: 'Unsuspend',
      },
    },
    transactionDetailsPage: {
      breadcrumbs: {
        transactions: 'Transactions',
        transactionDetails: 'Transaction details',
      },
      labels: {
        transactionId: 'Transaction ID',
        paymentId: 'Payment ID',
        date: 'Date created',
        type: 'Type',
        status: 'Status',
        accountNumber: 'Account number',
        accountOwner: 'Account owner',
        recipientName: 'Recipient name',
        beneficiaryName: 'Beneficiary name',
        paymentMethod: 'Payment method',
        purposeOfPayment: 'Payment purpose',
        routingNumber: 'Routing number',
        internationalBankAccountNumber: 'International bank account number',
        swift: 'SWIFT',
        address: 'Address',
        bankName: 'Bank name',
        bankAddress: 'Bank address',
        externalAccountNumber: 'External account number',
        origin: 'Origin',
        card: 'Card',
        cardOwner: 'Card owner',
        masterCard: 'Mastercard',
        visa: 'Visa',
        americanExpress: 'American Express',
        discover: 'Discover',
        maestro: 'Maestro',
        jcb: 'JCB',
        dinnersClubInternational: 'Diners Club International',
        defaultCard: 'Card',
        price: 'Price',
        fillPercentage: 'Fill percentage',
        transactionHash: 'Transaction hash',
        blockchain: 'Blockchain',
        walletAddress: 'Wallet address',
        deposit: 'Deposit',
        withdrawal: 'Withdrawal',
        internationalWireWithdrawal: 'International wire',
        withdrawalAmount: 'Withdrawal amount',
        feeBlockHeaderLabel: 'Transaction fee',
        amountBlockHeaderSubLabelForCryptoWithdrawalFirstPart: 'This fee is paid by',
        amountBlockHeaderSubLabelForCryptoWithdrawalSecondPart: ". Customer doesn't get charged.",
        gasPrice: 'Gas price',
        gasLimit: 'Gas limit',
        gasFee: 'Gas fee',
        total: 'Total',
        gasPriceTooltip:
          'Cost per unit of gas denoted in Gwei. The total transaction cost equals ‘Gas price’ x ‘Gas limit’. ',
        gasLimitTooltip: 'Maximum number of gas units that can be consumed to process transaction on the blockchain.',
        gasFeeTooltip: 'Fee paid to miners for verifying transactions on the blockchain.',
        tapiBlockchainFeeTooltip: 'Total fee paid to process the transaction on the blockchain. ',
        gwei: 'gwei',
        units: 'units',
        note: 'Note',
        transferFee: 'Transfer fee',
        internalTransfer: 'Internal transfer',
        buy: 'Buy',
        sell: 'Sell',
        comment: 'Comment',
        memo: 'Memo',
        destinationTag: 'Destination tag',
        reverseWithdrawal: 'Reverse withdrawal',
        reverseDeposit: 'Reverse deposit',
        wapiDepositCrypto: 'Deposit',
        walletId: 'Wallet ID',
        amountBlockHeaderLabel: 'Amount',
        depositAmount: 'Deposit amount',
        blockchainFee: 'Blockchain fee',
        noWapiDepositCryptoFee: 'No fee. Transaction cost is paid by the user who initiated withdrawal.',
        wapiWithdrawalCrypto: 'Withdrawal',
        wapiWithdrawalAmount: 'Withdrawal amount',
        wapiBlockchainFeeToolTip: 'Total fee paid to process the transaction on the blockchain. ',
        wapiGasPriceToolTip:
          'Maximum number of gas units that can be consumed to process transaction on the blockchain.',
        wapiGasLimitToolTip:
          'Cost per unit of gas denoted in Gwei. The total transaction cost equals ‘Gas price’ x ‘Gas limit’. ',
        tokenizedStockName: 'Private security name',
        noData: 'N/A',
        privateSecurityDeposit: 'Deposit',
        privateSecurityWithdrawal: 'Withdrawal',
        checkDeposit: 'Deposit',
        check: 'Check',
        returnDeposit: 'Return deposit',
        shares: 'shares',
        withdrawalReturn: 'Return withdrawal',
        na: 'N/A',
        tapiDepositCryptoBlockChainFee: 'No fee. Transaction cost is paid by the user who initiated withdrawal.',
        blockChainFeeLabel: 'Blockchain fee',
        description: 'Description',
        ach: 'ACH',
        initialDeposit: 'Initial deposit',
        initiatedBy: 'Initiated by',
        dateInitiated: 'Date initiated',
        sourceIdentifierLabel: 'Source identifier',
        sequenceNumberLabel: 'Sequence number',
        dateLabel: 'Date',
        approvalRowLabels: {
          status: 'Status',
          rejected: {
            by: 'Rejected by',
            at: 'Date rejected',
            reason: 'Rejection reason',
          },
          approved: {
            by: 'Approved by',
            at: 'Date approved',
          },
          approvalNeeded: {
            by: 'Approved by',
            at: 'Date approved',
          },
          rejectedReasons: {
            approvalTimeExpired: 'Approval time expired',
            rejectedByAdmin: 'Rejected by admin',
          },
        },
        domesticWire: 'Domestic wire (USA)',
        reasonOfCancellation: 'Reason of cancellation',
        imadNumberLabel: 'IMAD number',
        exportButtonLabel: 'Export',
        general: 'General',
      },
      transactionsDetails: 'Transaction details',
      transactionOverview: 'Overview',
      source: 'Source',
      destination: 'Destination',
      fedReference: 'Federal reference',
      transactionHeaderLabel: 'Transaction details',
      account: 'Account',
      initiationSectionLabel: 'Initiation',
      approveButtonLabel: 'Approve',
      rejectButtonLabel: 'Reject',
      actionDialog: {
        title: 'Confirm your action',
        cancelButtonLabel: 'Cancel',
        approveButtonLabel: 'Approve',
        approveQuestion: 'Are you sure you want to approve this transaction for:',
        rejectButtonLabel: 'Reject',
        rejectQuestion: 'Are you sure you want to reject this transaction for:',
      },
      approvalSectionLabel: 'Approvals',
      approvalIsForbiddenForSelfCreatedTransactionMessage:
        'You cannot approve or reject this operation since you initiated it. It should be authorized by another admin with appropriate permissions.',
      cancellationReason: 'Transaction was canceled because of technical issues.',
      mt103: {
        sectionTitle: 'MT103',
        sendersReference: 'Senders reference',
        timeIndication: 'Time indication',
        bankOperationCode: 'Bank operation code',
        instructionCode: 'Instruction code',
        transactionTypeCode: 'Transaction type code',
        valueDateCurrencyInterbankSettledAmount: 'Value date/Currency/Interbank settled amount',
        date: 'Date',
        currency: 'Currency',
        amount: 'Amount',
        currencyInstructedAmount: 'Currency/Instructed amount',
        exchangeRate: 'Exchange rate',
        orderingCustomer: 'Ordering customer',
        accountIdentifierCode: 'Account identifier code',
        account: 'Account',
        identifierCode: 'Identifier code',
        partyIdentifierNameAddress: 'Party identifier name address',
        partyIdentifier: 'Party identifier',
        nameAddress: 'Name address',
        accountNameAddress: 'Account name address',
        sendingInstitution: 'Sending institution',
        orderingInstitution: 'Ordering institution',
        sendersCorrespondent: "Sender's correspondent",
        receiversCorrespondent: "Receiver's correspondent",
        thirdReimbursementInstitution: 'Third reimbursement institution',
        intermediaryInstitution: 'Intermediary institution',
        accountWithInstitution: 'Account with institution',
        beneficiaryCustomer: 'Beneficiary customer',
        accountNumberNameAddress: 'Account number name address',
        number: 'Number',
        remittanceInformation: 'Remittance information',
        detailsOfCharges: 'Details of charges',
        sendersCharges: 'Sender’s charges',
        receiversCharges: "Receiver's charges",
        senderToReceiverInformation: 'Sender to receiver information',
        regulatoryReporting: 'Regulatory reporting',
      },
    },
    documentsList: {
      sectionTitle: 'Documents',
      closeModalBtn: 'Close',
      errorModalTitle: 'Something went wrong',
      errorModalSubtitle: 'We can’t generate a preview for this file.',
      sliderPagesOf: 'of',
      columnTitles: {
        document: 'Document',
        submitted: 'Submitted',
        documentStatus: 'Status',
      },
      documentStatuses: {
        accepted: 'ACCEPTED',
        rejected: 'REJECTED',
        pending: 'PENDING',
        automaticReviewInProgress: 'AUTOMATIC REVIEW',
        manualReviewNeeded: 'MANUAL REVIEW NEEDED',
        resubmit: 'RESUBMIT NEEDED',
      },
    },
    custodialAccountDetailsPage: {
      headerTitle: 'Account',
      detailsLabels: {
        modifiedAt: 'Last update',
        modifiedAtTooltip: 'Date the account was last modified.',
      },
      errorTitle: 'Something went wrong',
      errorSubtitle: 'Page can’t be loaded right now. Please try again later.',
      accountBalanceTitle: 'Account balance',
      assetTitle: 'Asset',
      totalBalanceTitle: 'Total balance',
      banner: {
        beginText: 'Please note, account balances and transactions may take up to 5 minutes to update. ',
        contactUsLinkText: 'Contact us',
        endText: ' if you need assistance.',
      },
      accountBalances: {
        title: 'Fiat & Crypto',
        balanceAssetName: 'Asset',
        availableBalance: 'Available balance',
        lockedBalance: 'Locked balance',
        pendingBalance: 'Pending balance',
        totalBalance: 'Total balance',
        availableBalanceTooltipMessage: 'Funds that are available for immediate use.',
        lockedBalanceTooltipMessage:
          'Funds that were locked when the user placed a buy/sell order. Once the order is either executed or canceled, the amount will decrease.',
        pendingBalanceTooltipMessage:
          'Pending incoming funds. Once the order is settled, the amount will become available.',
        totalBalanceTooltipMessage: 'Sum of the Available and Locked balances.',
      },
      customAssetBalance: {
        title: 'Private securities',
        cols: {
          name: 'Name',
          description: 'Description',
          price: 'Price',
          balance: 'Balance',
        },
        noResultsTitle: 'No assets yet',
        noResultsSubtitle: 'This account does not hold any private securities yet.',
        emptyFields: {
          description: 'No description',
          price: '--',
        },
      },
      beneficiaries: {
        grid: {
          title: 'Beneficiaries',
          cols: {
            name: 'Name',
            relationship: 'Relationship',
            percentage: '% of division',
            lastUpdate: 'Last update',
            status: 'Identity status',
          },
          noResultsTitle: 'No beneficiaries yet',
          noResultsSubtitle: 'It seems that no beneficiaries were added yet. ',
          emptyFields: {
            relationship: '--',
          },
        },
      },
      tabs: {
        fiatCrypto: 'Fiat & Crypto',
        tokenizedStocks: 'Tokenized stocks',
        privateSecurities: 'Private securities',
        beneficiaries: 'Beneficiaries',
      },
      caNamingModal: {
        editAccount: 'Edit Account',
        accountName: 'Account name',
        addAccountName: 'Add account name',
        accountLabel: 'Account label',
        saveButtonLabel: 'Save',
        cancelButtonLabel: 'Cancel',
      },
      suspendAccountModal: {
        suspendAccount: 'Suspend Account',
        unSuspendAccount: 'Unsuspend Account',
        suspendCaption:
          'Are you sure you want to suspend this account? Users will not be able to take any actions on their account.',
        unSuspendCaption:
          'Are you sure you want to unsuspend this account? User will have the ability to trade and transfer.',
        suspensionReason: 'Suspension Reason',
        unSuspensionReason: 'Unsuspension Reason',
        suspendTextAreaPlaceholder: 'Enter suspension reason here',
        unSuspendTextAreaPlaceholder: 'Enter unsuspension reason here',
        cancel: 'Cancel',
        suspend: 'Suspend',
        unSuspend: 'Unsuspend',
      },
      ownersIdentitySuspended: "Owner's Identity suspended",
      accountSuspendedByFortressSystem:
        'Account suspended by Fortress System. Please reach out to Customer Service for help.',
    },
    createUserDialog: {
      title: 'Add user',
      cancelButtonLabel: 'Cancel',
      addButtonLabel: 'Add',
      addMaxLimitButtonLabel: 'Set transaction limits',
      removeMaxLimitButtonLabel: 'Remove transaction limits',
      firstNameFieldLabel: 'First name',
      lastNameFieldLabel: 'Last name',
      emailFieldLabel: 'Email',
      phoneNumberFieldLabel: 'Phone number',
      roleTemplateFieldLabel: 'Role',
      viewerOptionTitle: 'Viewer',
      viewerOptionDescription: 'Can only view data',
      editorOptionTitle: 'Editor',
      editorOptionDescription: 'Can view data, initiate transactions, or approve transactions',
      adminOptionTitle: 'Admin',
      adminOptionDescription: 'Can view data, perform operations and manage user access',
      transactionLimitsSectionLabel: 'Transaction Limits',
      approveLimitSectionLabel: 'Approve transactions',
      conflictEmailErrorMessage: 'This email already exists in the database. Please make adjustments to continue.',
      initCountLimitSectionLabel: 'Initiate transactions',
      addInitCountLimitSectionButtonLabel: '+ Add Initiator Limits',
      permissionsFieldLabel: 'Permissions',
      paymentInitiatorOptionTitle: 'Initiate transactions',
      paymentInitiatorOptionDescription: 'Ability to initiate transactions and velocity limits.',
      paymentApproverOptionTitle: 'Approve transactions',
      paymentApproverOptionDescription: 'Ability to approve transactions and approval limits.',
      userManagerOptionTitle: 'Users management',
      userManagerOptionDescription: 'Access to add or delete users and control permissions.',
      corporateAccountViewerOptionTitle: 'View Corporate Accounts',
      corporateAccountViewerOptionDescription: 'Access to view both user and corporate accounts.',
      piiOptionTitle: 'View Personal Identifiable Information',
      piiOptionDescription: 'Access to view sensitive user (PII) data.',
      caEditorOptionTitle: 'Account Naming',
      caEditorOptionDescription: 'Ability to name and label corporate accounts.',
      accountSuspenderOptionTitle: 'Suspend Accounts',
      accountSuspenderOptionDescription: 'User is able to suspend\\unsuspend accounts only.',
      identitySuspenderOptionTitle: 'Suspend Identities',
      identitySuspenderOptionDescription: 'Ability to suspend\\unsuspend identities and all related accounts.',
      amountLimitTypeOption: '$ Amount',
      countLimitTypeOption: '# Transactions',
      maxValueOfAmountFieldPlaceholder: '$100,000.00',
      maxValueOfTransactionsNumberFieldPlaceholder: '0',
      addApproveLimitSectionButtonLabel: '+ Add Approval Limits',
    },
    editUserDialog: {
      title: 'Edit user',
      cancelButtonLabel: 'Cancel',
      saveButtonLabel: 'Save',
      addMaxLimitButtonLabel: 'Set transaction limits',
      removeMaxLimitButtonLabel: 'Remove transaction limits',
      firstNameFieldLabel: 'First name',
      lastNameFieldLabel: 'Last name',
      emailFieldLabel: 'Email',
      phoneNumberFieldLabel: 'Phone number',
      roleTemplateFieldLabel: 'Role',
      viewerOptionTitle: 'Viewer',
      viewerOptionDescription: 'Can only view data',
      editorOptionTitle: 'Editor',
      editorOptionDescription: 'Can view data, initiate transactions, or approve transactions',
      adminOptionTitle: 'Admin',
      adminOptionDescription: 'Can view data, perform operations and manage user access',
      transactionLimitsSectionLabel: 'Transaction Limits',
      approveLimitSectionLabel: 'Approve transactions',
      conflictEmailErrorMessage: 'This email already exists in the database. Please make adjustments to continue.',
      initCountLimitSectionLabel: 'Initiate transactions',
      addInitCountLimitSectionButtonLabel: '+ Add Initiator Limits',
      permissionsFieldLabel: 'Permissions',
      paymentInitiatorOptionTitle: 'Initiate transactions',
      paymentInitiatorOptionDescription: 'Ability to initiate transactions and velocity limits.',
      paymentApproverOptionTitle: 'Approve transactions',
      paymentApproverOptionDescription: 'Ability to approve transactions and approval limits.',
      userManagerOptionTitle: 'Users management',
      userManagerOptionDescription: 'Access to add or delete users and control permissions.',
      corporateAccountViewerOptionTitle: 'View Corporate Accounts',
      corporateAccountViewerOptionDescription: 'Access to view both user and corporate accounts.',
      piiOptionTitle: 'View Personal Identifiable Information',
      piiOptionDescription: 'Access to view sensitive user (PII) data.',
      caEditorOptionTitle: 'Account Naming',
      caEditorOptionDescription: 'Ability to name and label corporate accounts.',
      accountSuspenderOptionTitle: 'Suspend Accounts',
      accountSuspenderOptionDescription: 'User is able to suspend\\unsuspend accounts only.',
      identitySuspenderOptionTitle: 'Suspend Identities',
      identitySuspenderOptionDescription: 'Ability to suspend\\unsuspend identities and all related accounts.',
      amountLimitTypeOption: '$ Amount',
      countLimitTypeOption: '# Transactions',
      maxValueOfAmountFieldPlaceholder: '$100,000.00',
      maxValueOfTransactionsNumberFieldPlaceholder: '0',
      addApproveLimitSectionButtonLabel: '+ Add Approval Limits',
    },
    userDetailsPage: {
      breadcrumbs: {
        settings: 'Settings',
        userDetails: 'User details',
      },
      title: 'User details',
      fullName: 'Full name',
      userID: 'User ID',
      email: 'Email',
      phoneNumber: 'Phone number',
      role: 'Role',
      dateAdded: 'Date added',
      permissions: 'Permissions',
      transactionLimits: 'Transaction limits',
      canApproveUpTo: 'Can approve up to',
      canApproveUpToTooltipMsg: 'Maximum transaction value that can be authorized by this user.',
      emptyValue: '--',
      pendingTooltipMessage: 'User hasn’t accepted the invitation yet.',
      na: 'N/A',
      buttons: {
        edit: 'Edit',
        resetPassword: 'Reset password',
        remove: 'Remove',
        resendInvitation: 'Resend invitation',
      },
      limitRangeType: {
        day: 'Day',
        week: 'Week',
        month: 'Month',
      },
      per: 'Per',
      maxNumberOfTransactions: 'Max # of transactions',
      enabled: 'Enabled',
      disabled: 'Disabled',
      dollarAmount: '$ Amount',
      numberOfTransactions: '# Transactions',
      approveTransactions: 'APPROVE TRANSACTIONS',
      initiateTransactions: 'INITIATE TRANSACTIONS',
    },
    filters: {
      column: 'Column',
      operator: 'Operator',
      value: 'Value',
      applyButtonLabel: 'Apply',
      clearButtonLabel: 'Clear filters',
      cancelButtonLabel: 'Cancel',
      filterButtonLabel: 'Filters',
      exportButtonLabel: 'Export',
      exportPDFButtonLabel: 'Generate a PDF',
      exportCSVButtonLabel: 'Generate a CSV',
      exportCSVModal: {
        title: 'Pagination',
        pageLabel: 'Page',
        pageSizeLabel: 'Page Size',
      },
      exportPDFModal: {
        title: 'Date Range',
        dateFromLabel: 'Date From',
        dateToLabel: 'Date To',
        futureDateError: 'Date cannot be in the future',
        fromDateError: 'From date needs to be before To date',
        toDateError: 'To date needs to be after From date',
        invalidDateError: 'Invalid date',
      },
      select: 'Select',
      operators: {
        equals: 'Equals',
        contains: 'Contains',
        greaterThan: 'Is greater than',
        lessThan: 'Is less than',
        is: 'Is',
        isBefore: 'Is before',
        isAfter: 'Is after',
        equalsSign: '=',
        greaterThanSign: '>',
        lessThanSign: '<',
      },
      buy: 'Trading | Buy',
      sell: 'Trading | Sell',
      internalTransfer: 'Int. transfer | Fiat',
      internalTransferCrypto: 'Int. transfer | Crypto',
      internalPrivateSecurity: 'Int. transfer |  Private security',
      depositAch: 'Deposit | ACH',
      returnAchDeposit: 'Return deposit | ACH',
      withdrawalAch: 'Withdrawal | ACH',
      depositCheck: 'Deposit | Check',
      cardWithdrawal: 'Card Withdrawal', // not used currently
      depositWire: 'Deposit | Wire',
      returnDepositWire: 'Return deposit | Wire',
      reverseDepositWire: 'Reverse deposit | Wire',
      withdrawalWire: 'Withdrawal | Domestic wire',
      internationalWithdrawalWire: 'Withdrawal | Intl. wire',
      reverseWithdrawalDomesticWire: 'Reverse withdrawal | Dom. wire',
      returnWithdrawalWire: 'Return withdrawal | Dom. wire',
      withdrawalCrypto: 'Withdrawal | Crypto',
      withdrawalPrivateSecurity: 'Withdrawal |  Private security',
      depositCrypto: 'Deposit | Crypto',
      withdrawalToken: 'Withdrawal | NFT',
      depositToken: 'Deposit | NFT',
      depositPrivateSecurity: 'Deposit |  Private security',
      withdrawalAchReturn: 'Return withdrawal | ACH',
      withdrawalAchReverse: 'Reverse withdrawal | ACH',
      depositAchReverse: 'Reverse deposit | ACH',
      initialDeposit: 'Initial deposit',
      buyPreciousMetal: 'Precious Metal | Buy',
      sellPreciousMetal: 'Precious Metal | Sell',
      manuallyProcessed: 'Manually processed',
      noFilteredResultsTitle: 'No results found',
      noFilteredResultsSubtitle: 'Reset filters or try a different keyword.',
    },
    nft: {
      nftDetails: {
        pageTitle: 'NFT details',
        headerIdLabel: 'Internal token ID',
        noDescription: 'No description',
        collectionLabel: 'Collection:',
        creatorLabel: 'Creator:',
        detailsTitle: 'Details',
        propertiesTitle: 'Properties',
        errorTitle: 'Something went wrong',
        errorSubtitle: 'Page can’t be loaded right now. Please try again later.',
        rowLabels: {
          contractAddress: 'Contract address',
          blockchain: 'Blockchain',
          tokenId: 'Token ID',
          tokenStandart: 'Token standard',
          fileType: 'File type',
          amount: 'Amount',
          totalEdition: 'Total editions',
          dateCreated: 'Date created',
        },
        fileTypeNamimg: {
          image: 'Image',
          gif: 'GIF',
          audio: 'Audio',
          video: 'Video',
          document: 'Document',
          graphics3D: '3D',
          other: 'Other',
        },
        breadcrumbs: {
          nftDetails: 'NFT details',
        },
      },
    },
    currency: {
      short: {
        usd: 'USD',
        btc: 'BTC',
        eth: 'ETH',
        matic: 'MATIC',
        usdc: 'USDC',
        sol: 'SOL',
      },
      long: {
        usd: 'US Dollar',
        btc: 'Bitcoin',
        eth: 'Ethereum',
        matic: 'Polygon',
        usdc: 'USD Coin',
        sol: 'Solana',
      },
    },
    walletsPage: {
      title: 'Wallets',
      gridTitle: 'Wallets',
      noResultsTitle: 'No wallets yet',
      noResultsSubtitle: 'It seems that no wallets were created yet. ',
      walletName: 'Name',
      walletId: 'Wallet ID',
      owner: 'Owner',
      date: 'Date created',
      assets: 'Assets',
      noAssets: 'No assets',
      noData: 'N/A',
      noOwnerTooltipMessage: 'No owner (identity reference) has been provided for this wallet.',
    },
    walletDetailsPage: {
      tabs: {
        cryptoTab: 'Crypto',
        nftTab: 'NFT',
      },
      assets: {
        title: 'Crypto assets',
        cols: {
          name: 'Name',
          address: 'Address',
          blockchain: 'Blockchain',
          balance: 'Balance',
        },
        noResultsTitle: 'No assets yet',
        noResultsSubtitle: 'This wallet does not hold any crypto assets yet.',
      },
      tokens: {
        title: 'NFT assets',
        cols: {
          name: 'Name',
          contractAddress: 'Contract address',
          tokenId: 'Token ID',
          blockchain: 'Blockchain',
          tokenStandard: 'Token standard',
          fileType: 'File type',
          amount: 'Amount',
        },
        noResultsTitle: 'No assets yet',
        noResultsSubtitle: 'This wallet does not hold any NFT assets yet.',
        emptyFields: {
          name: 'Untitled NFT',
          tokenId: 'No token ID',
          amount: '--',
        },
      },
      walletId: 'Wallet ID',
      walletOwner: 'Owner',
      walletDateCreated: 'Date created',
      noOwnerTooltipMessage: 'No owner (identity reference) has been provided for this wallet.',
      noData: 'N/A',
      breadcrumbs: {
        wallets: 'Wallets',
        wallet: 'Wallet details',
      },
    },
    sideMenu: {
      labels: {
        general: 'General',
        generalCollapsed: 'General',
        trustApi: 'trust api',
        trustApiCollapsed: 'trust',
        walletApi: 'Wallet api',
        walletApiCollapsed: 'Wallet',
        identities: 'Identities',
        accounts: 'Accounts',
        transactions: 'Transactions',
        wallets: 'Wallets',
        settings: 'Settings',
        help: 'Help',
        logOut: 'Log out',
      },
    },
    cryptoAssets: {
      eth: {
        long: 'Ethereum',
        short: 'ETH',
      },
      matic: {
        long: 'Polygon',
        short: 'MATIC',
      },
      maticEth: {
        long: 'Polygon on Ethereum',
        short: 'MATIC',
      },
      btc: {
        long: 'Bitcoin',
        short: 'BTC',
      },
      usdc: {
        long: 'USD Coin',
        short: 'USDC',
      },
      usdcEth: {
        long: 'USD Coin on Ethereum',
        short: 'USDC',
      },
      usdcMatic: {
        long: 'USD Coin on Polygon',
        short: 'USDC',
      },
      flrns: {
        long: 'Florins',
        short: 'FLRNS',
      },
      sol: {
        long: 'Solana',
        short: 'SOL',
      },
      weth: {
        long: 'Wrapped Ether',
        short: 'WETH',
      },
      wethEth: {
        long: 'Wrapped Ether on Ethereum',
        short: 'WETH',
      },
      wethMatic: {
        long: 'Wrapped Ether on Polygon',
        short: 'WETH',
      },
      ada: {
        long: 'Cardano',
        short: 'ADA',
      },
      avax: {
        long: 'Avalanche',
        short: 'AVAX',
      },
      dot: {
        long: 'Polkadot',
        short: 'DOT',
      },
      bat: {
        long: 'Basic Attention Token',
        short: 'BAT',
      },
      shib: {
        long: 'Shiba Inu',
        short: 'SHIB',
      },
      cro: {
        long: 'Cronos',
        short: 'CRO',
      },
      bnb: {
        long: 'Binance Coin',
        short: 'BNB',
      },
      nft: {
        long: 'NFT',
        short: 'NFT',
      },
      usd: {
        long: 'US Dollar',
        short: 'USD',
      },
      privateSecurity: {
        long: 'Private security',
        short: 'Private security',
      },
      xlm: {
        long: 'Stellar',
        short: 'XLM',
      },
      link: {
        long: 'Chainlink',
        short: 'LINK',
      },
      algo: {
        long: 'Algorand',
        short: 'ALGO',
      },
      busd: {
        long: 'Binance USD',
        short: 'BUSD',
      },
      ltc: {
        long: 'Litecoin',
        short: 'LTC',
      },
      uni: {
        long: 'Uniswap',
        short: 'UNI',
      },
      busdEth: {
        long: 'Binance USD on Ethereum',
        short: 'BUSD',
      },
      crv: {
        long: 'Curve DAO Token',
        short: 'CRV',
      },
      aave: {
        long: 'Aave',
        short: 'AAVE',
      },
      doge: {
        long: 'Dogecoin',
        short: 'DOGE',
      },
      comp: {
        long: 'Compound',
        short: 'COMP',
      },
      xtz: {
        long: 'Tezos',
        short: 'XTZ',
      },
      bch: {
        long: 'Bitcoin Cash',
        short: 'BCH',
      },
      eos: {
        long: 'EOS',
        short: 'EOS',
      },
      atom: {
        long: 'Cosmos',
        short: 'ATOM',
      },
      grt: {
        long: 'The Graph',
        short: 'GRT',
      },
      sushi: {
        long: 'SushiSwap',
        short: 'SUSHI',
      },
      sand: {
        long: 'The Sandbox',
        short: 'SAND',
      },
      enj: {
        long: 'Enjin Coin',
        short: 'ENJ',
      },
      axs: {
        long: 'Axie Infinity',
        short: 'AXS',
      },
      mana: {
        long: 'Decentraland',
        short: 'MANA',
      },
      mkr: {
        long: 'Maker',
        short: 'MKR',
      },
      yfi: {
        long: 'Yearn Finance',
        short: 'YFI',
      },
      ape: {
        long: 'ApeCoin',
        short: 'APE',
      },
      near: {
        long: 'NEAR Protocol',
        short: 'NEAR',
      },
      dai: {
        long: 'Dai',
        short: 'DAI',
      },
      tether: {
        long: 'Tether',
        short: 'USDT',
      },
      xrp: {
        long: 'XRP',
        short: 'XRP',
      },
      usdcArbitrum: {
        long: 'USD Coin on Arbitrum',
        short: 'USDC',
      },
      usdcAlgorand: {
        long: 'USD Coin on Algorand',
        short: 'USDC',
      },
      usdcSolana: {
        long: 'USD Coin on Solana',
        short: 'USDC',
      },
      usdcAvalanche: {
        long: 'USD Coin on Avalanche',
        short: 'USDC',
      },
      usdcStellar: {
        long: 'USD Coin on Stellar',
        short: 'USDC',
      },
      usdcFantom: {
        long: 'USD Coin on Fantom',
        short: 'USDC',
      },
      usdcTron: {
        long: 'USD Coin on Tron',
        short: 'USDC',
      },
      usdcBnb: {
        long: 'USD Coin on Binance',
        short: 'USDC',
      },
      smtf: {
        long: 'SmartFi',
        short: 'SMTF',
      },
      ftm: {
        long: 'Fantom',
        short: 'FTM',
      },
      flow: {
        long: 'Flow',
        short: 'FLOW',
      },
      trx: {
        long: 'Tron',
        short: 'TRX',
      },
      usdcFlow: {
        long: 'USD Coin on Flow',
        short: 'USDC',
      },
      qnt: {
        long: 'Quant',
        short: 'QNT',
      },
      hbar: {
        long: 'Hedera',
        short: 'HBAR',
      },
      chz: {
        long: 'Chiliz',
        short: 'CHZ',
      },
      gala: {
        long: 'Gala',
        short: 'GALA',
      },
      gold: {
        long: 'Gold',
        short: 'XAU',
      },
      silver: {
        long: 'Silver',
        short: 'XAG',
      },
    },
    networkChain: {
      bnb: {
        long: 'Binance Smart Chain',
        short: 'BNB',
      },
      xrp: {
        long: 'Ripple',
        short: 'XRP',
      },
    },
    tokenType: {
      erc721: 'ERC-721',
      erc1155: 'ERC-1155',
      erc20: 'ERC-20',
      solana: 'Metaplex',
      // @TODO: non existing case for now
      cryptoTransfer: 'Crypto Transfer',
    },
    fileType: {
      image: 'Image',
      gif: 'GIF',
      audio: 'Audio',
      video: 'Video',
      doc: 'Document',
      threeD: '3D',
      other: 'Other',
    },
    notImplemented: 'Not Implemented',
    noData: 'N/A',
    signs: {
      plus: '+',
      minus: '-',
    },
    beneficialOwnersList: {
      sectionTitle: 'Beneficial owners',
      columnTitles: {
        ownerName: 'Name',
        id: 'ID',
        kycLevel: 'KYC level',
        ownerStatus: 'Status',
      },
    },
    statuses: {
      identities: {
        tooltip: {
          active: 'Identity is active.',
          inactive: 'Identity is deactivated.',
          suspended: 'Identity is suspended.',
          suspendedByFortress: 'Identity suspended by Fortress System. Please reach out to Customer Service for help.',
        },
      },
      accounts: {
        tooltip: {
          pending: 'Trust account is created but bank account hasn’t been opened or approved yet.',
          open: 'Account is active.',
          closed: 'Account is closed.',
          suspended: 'Account has been suspended by admin.',
        },
      },
      transactions: {
        tooltip: {
          approvalNeeded: "The transaction is awaiting admin's approval before it can be processed.",
          rejected: 'The transaction has been rejected by admin or the approval time has expired.',
        },
      },
    },
    elementButton: {
      transfer: 'Transfer',
      trade: 'Trade',
    },
    settingsPage: {
      title: 'Settings',
      grid: {
        title: 'Users',
        noResultsTitle: 'Something went wrong',
        noResultsSubtitle: 'Page can’t be loaded right now. Please try again later.',
        cols: {
          fullName: 'Full name',
          email: 'Email',
          phone: 'Phone number',
          role: 'Role',
          createdAt: 'Date added',
        },
        addUserButton: 'Add user',
        noData: 'N/A',
        actions: {
          edit: 'Edit',
          reset: 'Reset password',
          resend: 'Resend invitation',
          remove: 'Remove',
        },
        pendingInvitation: 'Pending invitation',
      },
      banner: {
        resetPasswordSuccessMessage: 'Email with corresponding instructions sent. Links are valid for 5 days.',
      },
    },
    actionDialog: {
      title: 'Confirm your action',
      cancelButtonLabel: 'Cancel',
      approveButtonLabel: 'Approve',
      approveQuestion: 'Are you sure you want to approve this transaction for:',
      rejectButtonLabel: 'Reject',
      rejectQuestion: 'Are you sure you want to reject this transaction for:',
      users: {
        destroyCaptionText: 'Are you sure you want to remove access for:',
        destroyButtonTitle: 'Remove',
      },
    },
    roleTemplates: {
      admin: 'Admin',
      editor: 'Editor',
      viewer: 'Viewer',
    },
    limitRangeType: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      transaction: 'Transaction',
    },
    walletTransactions: {
      methods: {
        crypto: 'Crypto',
        token: 'NFT',
        notImplemented: 'Not implemented method',
      },
      operations: {
        deposit: 'Deposit',
        withdrawal: 'Withdrawal',
        notImplemented: 'Not implemented operation',
      },
    },
    walletTransactionDetails: {
      headerLabel: 'Transaction details',
      headerDateLabel: 'Date created',
      nodesTitle: 'Overview',
      sourceNodeTitle: 'Source',
      destinationNodeTitle: 'Destination',
      amountNodeTitle: 'Amount',
      types: {
        withdrawal: 'Withdrawal',
        deposit: 'Deposit',
      },
      breadcrumbs: { transactions: 'Transactions', transactionDetails: 'Transaction details' },
      labels: {
        transactionId: 'Transaction ID',
        transactionHash: 'Transaction hash',
        dateCreated: 'Date created',
        type: 'Transaction type',
        blockchain: 'Blockchain',
        note: 'Note',
        walletAddress: 'Wallet address',
        walletId: 'Wallet ID',
        nftAmount: 'NFT amount',
        depositAmount: 'Deposit amount',
        withdrawalAmount: 'Withdrawal amount',
        blockchainFee: 'Blockchain fee',
        gasPrice: 'Gas price',
        wapiGasPriceToolTip:
          'Maximum number of gas units that can be consumed to process transaction on the blockchain.',
        gasLimit: 'Gas limit',
        wapiGasLimitToolTip:
          'Cost per unit of gas denoted in Gwei. The total transaction cost equals ‘Gas price’ x ‘Gas limit’.',
        gasLimitTooltip: 'Maximum number of gas units that can be consumed to process transaction on the blockchain.',
        wapiBlockchainFeeToolTip: 'Total fee paid to process the transaction on the blockchain.',
        noWapiDepositCryptoFee: 'No fee. Transaction cost is paid by the user who initiated withdrawal.',
        total: 'Total',
        gwei: 'gwei',
        units: 'units',
        noData: 'N/A',
      },
    },
    transactionStatus: {
      approvalNeeded: 'Approval needed',
      approved: 'Approved',
      rejected: 'Rejected',
      success: 'Success',
      pending: 'Pending',
      processing: 'Processing',
      canceled: 'Canceled',
      fail: 'Fail',
    },
    permission: {
      initiateTransactions: 'Initiate transactions',
      approveTransactions: 'Approve transactions',
      usersManagement: 'Users management',
      viewCorporateAccounts: 'View corporate accounts',
      pii: 'View PII',
      adminManager: 'Admins management',
      caEditor: 'Account naming',
      accountSuspender: 'Suspend Accounts',
      identitySuspender: 'Suspend Identities',
    },
    elements: {
      common: {
        errorBanner: {
          connectionIssuesErrorMessage: 'Oops, we’re having some connection issues. Please try again.',
          processingError: 'There was an error processing your request. Please try again.',
        },
        closeConfirmModal: {
          title: 'Are you sure you want to cancel?',
          subtitle: 'Your progress will be lost.',
          buttonLabel: 'Yes, cancel',
        },
        close: 'Close',
        cancel: 'Cancel',
        copy: 'Copy',
        share: 'Share',
        done: 'Done',
        edit: 'Edit',
        save: 'Save',
        continue: 'Continue',
        goBack: 'Go back',
        letsGo: 'Let’s go',
        contactUs: 'Need help? Contact us',
        contactUsShort: 'Contact us',
        needHelp: 'Need help?',
        total: 'Total',
        businessAccount: 'Business Account',
        personalAccount: 'Personal Account',
        account: 'Account',
        sendTo: 'Send to',
        from: 'From',
        search: 'Search',
        nothingFound: 'Nothing found',
        datePlaceholder: 'MM/DD/YYYY',
        traceId: 'Trace ID',
      },
      features: {
        loadingErrorState: {
          cancel: 'Cancel',
          errorStateHeader: 'Something’s wrong',
          errorStateSubtext: 'We’re sorry we can’t load this page. Please reload and try again.',
          reloadPageButtonLabel: 'Reload page',
        },
        paymentMethods: {
          title: 'Payment method',
          done: 'Done',
          addPayment: 'Add a payment method',
          connectAccount: 'Connect bank account',
          creditDebitTitle: 'credit / debit',
          bankAccountsTitle: 'bank accounts',
          availableBalancesTitle: 'available balances',
          noAccountLinked: 'No account linked',
          noBalances: 'No balances',

          // item
          buttonTitle: 'Payment Method',
          noPaymentMethod: 'No payment method',
          bankAccount: 'Bank account',
          personalAccount: 'Personal Account',
          businessAccount: 'Business account',
          account: 'Account',
          externalBankAccount: 'External bank account',
          cardEnding: 'Card ending in {{last4}}',

          // cards
          americanExpress: 'American Express',
          dinners: ' Diners Club',
          discover: 'Discover',
          jcb: 'JCB',
          maestro: 'Maestro',
          mastercard: 'Mastercard',
          visa: 'Visa',
          other: 'Other',
        },
        identityVerification: {
          verifyIdentityTitle: 'We’ll need some information to place this order for you.',
          verifyIdentitySubtitle: 'Verify your identity to continue.',
          tradingUnavailableTitle: 'Trading isn’t available for business accounts.',
          tradingUnavailableSubtitle:
            'We’re sorry, we only allow trading for individuals with verified personal accounts.',
          inactiveAccountTitle: 'Your account is inactive',
          inactiveAccountSubtitle:
            'We’re sorry for any inconvenience, but your account is inactive. Please contact us if you feel there has been a mistake.',
        },
        identityCheck: {
          emptyStateTitle: 'No account',
          emptyStateDescription: 'You don’t have an active account.',
          inactiveBannerText1: 'We’re sorry for any inconvenience, but your account is inactive.',
          inactiveBannerText2: 'if you feel there has been a mistake.',
          notVerifiedBannerText:
            '<bold>Verification Required</bold>: We’ll need basic identity information to continue.',
          additionalVerificationBannerText:
            '<bold>Verification Required</bold>: We’ll need identity documents to continue.',
          notVerifiedBusinessBannerText: 'We’ll need to verify your business information before you can continue.',
          verifyNowBannerButtonText: 'Verify now',
          uploadAndVerifyBannerButtonText: 'Upload & verify',
          contactUsLinkText: 'Please contact us',
          transferringUnavailableBannerText1: 'We’re sorry, transferring is currently unavailable in your state.',
          transferringUnavailableText2: 'if you have questions.',
        },
        coinLabels: {
          ethereum: 'Ethereum',
          polygon: 'Polygon',
          florins: 'Florins',
          bitcoin: 'Bitcoin',
          usdCoinOnEthereum: 'USD Coin on Ethereum',
          usdCoinOnPolygon: 'USD Coin on Polygon',
          solana: 'Solana',
          wrappedETH: 'Wrapped Ethereum',
          avalanche: 'Avalanche',
          cardano: 'Cardano',
          polkadot: 'Polkadot',
          basicAttentionToken: 'Basic Attention Token',
          shibaInuCoin: 'Shiba Inu',
          bnb: 'BNB',
          binanceUSD: 'Binance USD',
          stellar: 'Stellar',
          chainlink: 'Chainlink',
          algorand: 'Algorand',
          litecoin: 'Litecoin',
          uniswap: 'Uniswap',
          usdt: 'Tether',

          dogecoin: 'Dogecoin',
          curveDAOToken: 'Curve DAO Token',
          AAVE: 'AAVE',
          bitcoinCash: 'Bitcoin Cash',
          tezos: 'Tezos',
          compound: 'Compound',

          theGraph: 'The Graph',
          cosmos: 'Cosmos',
        },
        coinsListPage: {
          searchCoinPlaceholder: 'Search by coin name or symbol',
          noResultsTitle: 'No results found for',
          noResultsSubtitle: 'Check your spelling or try again with different keywords.',
        },
        coinsDropdown: {
          noResultsTitle: 'No results found for',
          noResultsSubtitle: 'Check your spelling or try again with different keywords.',
          noCoinsLabel: 'No Coins',
          noAvailableLabel: '0 AVAILABLE',
          searchPlaceholder: 'Search by coin name or symbol',
        },
      },
      trade: {
        customizableLabels: {
          title: 'Trade',
          buy: 'Buy',
          sell: 'Sell',
        },
        buySellPage: {
          amountLabelBuy: 'Spend',
          amountLabelSell: 'Amount',
          amountErrorTextBuy: 'There is a {{currency}} {{minDollarAmount}}.00 minimum',
          amountErrorTextSell: 'Insufficient balance',
          estimatedPriceBuy: 'Estimated price (1\u00A0{{coinAbbreviation}})',
          estimatedPriceSell: 'Estimated market value (1\u00A0{{coinAbbreviation}})',
          totalPurchaseBuy: 'Total estimated purchase',
          totalPurchaseSell: 'Total estimated value',
          priceUnavailable: 'Price unavailable',
          errorMessagePart1: 'There was an error loading estimated price. ',
          errorMessagePart2: 'Please reload',
          errorMessagePart3: ' and try again.',
          unavailableCoinBanner: 'We’re sorry. This coin is currently unavailable for trading.',
          contactUsLinkText: 'Please contact us',
          tradingUnavailableBannerText1: 'We’re sorry, trading is currently unavailable in your state.',
          tradingUnavailableText2: 'if you have questions.',
        },
        reviewPage: {
          titleBuy: 'Review your order',
          confirmAndSubmitButtonLabelBuy: 'Confirm & submit',
          insufficientBalanceError: 'Insufficient balance. Please select another payment method or adjust your order.',
          titleSell: 'Review your sale',
          SubmitButtonLabelSell: 'Submit',
          internalNoteLabel: 'Add a note',
        },
        orderDetails: {
          estimatedPriceBuy: 'Estimated price (1\u00A0{{coinAbbreviation}})',
          estimatedPriceSell: 'Estimated market value (1\u00A0{{coinAbbreviation}})',
          estimatedAmount: 'Estimated {{coinAbbreviation}}',
          totalCost: 'Total Cost',
          totalSell: 'Total estimated value',
          remarkBuyFlow: 'Subject to market fluctuations. Once this order is placed it cannot be canceled.',
          remarkSellFlow: 'Subject to market fluctuations. Once this sale is placed it cannot be canceled.',
          processingBuyErrorLabel: 'There was an error processing your order. Please submit again.',
          processingSellErrorLabel: 'There was an error processing your sale. Please submit again.',
          errorMessagePart1: 'There was an error loading coins. ',
          errorMessagePart2: 'Please reload',
          errorMessagePart3: ' and try again.',
          infoBanner: 'Your sale is in progress. Your funds will be available in your account shortly.',
        },
        orderPage: {
          titleBuy: 'Your order is processing',
          titleSell: 'Your sale is processing',
          doneButtonLabel: 'Done',
          aboutTradingTitle: 'About Trading & Settlement',
          aboutTradingTextPart1:
            'After your order is placed, we initiate your trade. A $20 minimum is required to reduce the chance a trade may fail due to fluctuating minimum coin purchase thresholds. These thresholds vary from coin to coin.',
          aboutTradingTextPart2:
            'Funds from your payment method are transferred to an account used to pay for your trading activity. This account has a maximum weekly deposit threshold that can affect the success of your trade if it has been met.',
          internalNoteTitle: 'Note',
        },
      },
      transfer: {
        customizableLabels: {
          title: 'Transfer',
          depositTitle: 'Deposit',
          depositAchButton: 'Deposit from bank',
          depositAchAmountPageTitle: 'Deposit',
          depositAchReviewPageTitle: 'Review your deposit',
          depositAchSuccessPageTitle: 'Deposit placed',
          depositWireButton: 'Wire Instructions',
          depositWireInstructionsPageTitle: 'Wire Instructions',
          depositCryptoButton: 'Receive Crypto',
          depositCryptoListPageTitle: 'Receive Crypto',
          depositCryptoQrCodePageTitle: 'Receive Crypto',
          withdrawalTitle: 'Send or withdraw',
          withdrawalAloneTitle: 'Withdrawal',
          withdrawalAchButton: 'Transfer to a linked bank account',
          withdrawalAchAmountPageTitle: 'Send or withdraw',
          withdrawalAchReviewPageTitle: 'Review your withdrawal',
          withdrawalAchSuccessPageTitle: 'Withdrawal placed',
          withdrawalWireButton: 'Withdraw a Wire',
          withdrawalWireRecipientSearchPageTitle: 'Wire Withdrawal',
          withdrawalWireRecipientInfoPageTitle: 'Wire Withdrawal',
          withdrawalWireBankInfoPageTitle: 'Wire Withdrawal',
          withdrawalWireAccountNumbersPageTitle: 'Wire Withdrawal',
          withdrawalWireAmountPageTitle: 'Wire Withdrawal',
          withdrawalWireReviewPageTitle: 'Review your wire withdrawal',
          withdrawalWireSuccessPageTitle: 'Wire placed',
          withdrawalCryptoButton: 'Withdraw Crypto',
          withdrawalCryptoAmountPageTitle: 'Withdraw Crypto',
          withdrawalCryptoReviewPageTitle: 'Review',
          withdrawalCryptoSuccessPageTitle: 'Crypto sent',
          internalTransferButton: 'Send Internal Transfer',
          internalTransferRecipientSearchPageTitle: 'Send Internal Transfer',
          internalTransferAmountPageTitle: 'Send Internal Transfer',
          internalTransferReviewPageTitle: 'Send Internal Transfer',
          internalTransferPlacedPageTitle: 'Internal Transfer placed',
        },
        restrictions: {
          cryptoDepositEmptyStateTitle: 'No crypto addresses',
          cryptoDepositEmptyStateDescription: 'You don’t have any crypto addresses.',
          cryptoWithdrawalEmptyStateTitle: 'You don’t have any crypto',
          cryptoWithdrawalEmptyStateDescription: 'It looks like you don’t have any coin balances to send.',
        },
        commonRecipientSection: {
          subtitle: 'RECIPIENT INFORMATION',
          searchBtnTitle: 'Search',
          results: 'RESULTS',
          or: 'OR',
          noResultsFound: 'No Results Found',
          pleaseTryAgain: 'Please try again',
          deleteConfirmModal: {
            title: 'Confirm your action',
            confirmButtonLabel: 'Remove',
          },
        },
        depositAchAmountPage: {
          continueToSummary: 'Continue to summary',
          depositAmount: 'Deposit amount',
          depositTo: 'Deposit To',
          businessAccount: 'Business Account',
          personalAccount: 'Personal Account',
          account: 'Account',
        },
        depositAchReviewPage: {
          depositButton: 'Deposit $ {{depositAmount}}',
          processingError: 'There was an error processing your deposit. Please submit again.',
          depositAmount: 'Deposit amount',
        },
        cryptoQrCodePage: {
          networkName: '{{network}} Network',
          snackbarMsg: 'Address copied to clipboard',
          multipleNetworksMsg:
            'This coin is available on multiple networks. Make sure you are using the correct network to avoid any loss of assets.',
        },
        depositWireInstructionsPage: {
          title: 'Wire Instructions',
          infoMessage:
            'Wire transfers are subject to bank transaction fees, and may take 3-5 business days to complete.',
          snackbarMessage: 'Copied to clipboard',
          accountNumberFieldLabel: 'ACCOUNT NUMBER',
          routingNumberFieldLabel: 'ROUTING NUMBER',
          swiftCodeFieldLabel: 'SWIFT CODE',
          accountHolderSectionHeader: 'ACCOUNT HOLDER',
          bankDetailsSectionHeader: 'BANK DETAILS',
          share: {
            wireInstructionsFor: 'Wire instruction for {{name}}',
            accountNumber: 'Account Number: {{number}}',
            routingNumber: 'Routing Number: {{number}}',
            swiftCode: 'SWIFT Code: {{code}}',
            sendWireTo: 'Send wire to:',
            bankDetails: 'Bank details:',
          },
          downloadPDFButtonLabel: 'Download PDF',
        },
        withdrawalCryptoAmountPage: {
          amount: 'Amount',
          recipientAddress: 'Recipient address',
          reviewButton: 'Review',
          insufficientFundsError: 'Insufficient Funds',
          walletAddress: 'Wallet address',
          walletAddressError: 'Enter valid wallet address',
          destinationTag: 'Destination tag',
          destinationTagError: 'Destination tag should be no more than 100 symbols long',
          cryptoAddressNameError: 'Name should be less than 50 symbols long',
          saveToAddressBook: 'Save to address book (optional)',
          cryptoAddressNameRequiredError: 'Name is required field for crypto address saving',
        },
        withdrawalCryptoRecipientSearchSection: {
          addRecipientBtnTitle: 'Enter Address Manually',
          searchInputPlaceholder: 'Search for the recipient',
          searchInputHelperText: 'Search by Name or by Address',
          deleteConfirmModal: {
            subtitle: 'Are you sure that you want to delete this Crypto Address?',
          },
        },
        withdrawalCryptoReviewPage: {
          sendButton: 'Send {{formattedAmount}}',
          processingError: 'Unable to process your transaction at this time. Please try again.',
          minimumAmountError:
            'We were unable to complete your request.  A minimum of {{amount}} {{coinAbbreviation}} is required to place your transfer.',
          minimalAccountBalanceError:
            'You cannot send your full balance. This coin requires a small balance of {{amount}} {{coinAbbreviation}} to remain in your wallet.',
        },
        withdrawalWireAccountNumbersPage: {
          fieldsHeader: 'RECIPIENT ACCOUNT NUMBERS',
          routingNumberLabel: 'Routing number',
          swiftCodeLabel: 'SWIFT Number',
          accountNumberLabel: 'Account number',
          confirmAccountNumberLabel: 'Confirm account number',
          bankIsNotSupported: 'We’re sorry. This bank is not supported.',
          bankIsNotFound: 'We couldn’t find this bank. Please try again.',
        },
        withdrawalWireAmountPage: {
          inputLabel: 'Wire amount',
          fromLabel: 'From',
          availableAmountLabel: 'Available for withdrawal',
          reviewButton: 'Review',
          insufficientFundsError: 'Insufficient funds',
          initLimitsError:
            'You have reached your initiation limit. Please reach out to an Administrator if you have any questions.',
          internalNoteLabel: 'Internal note',
          addInternalNoteButton: '+ add an internal note',
          internalNoteHelperText: 'This note is internal and for your records only.',
          memoLabel: 'Deliver a memo',
          memoHelperText: 'This memo will be delivered to the recipient.',
          purposeOfPayment: 'Purpose of Payment',
          purposeOfPaymentHelperText: 'Additional context for the recipient.',
          purposeOfPaymentTooltipText1: 'Purpose of payment is needed for sending wires through our banking partner.',
          purposeOfPaymentTooltipText2:
            'Examples: Supplies, Legal Services, Medical Reimbursement, Payroll, Contractor Payment, Trade Services, Travel Expenses.',
        },
        withdrawalWireBankInfoPage: {
          fieldsHeader: 'FINANCIAL INSTITUTION INFORMATION',
          bankNameLabel: 'Name of bank or financial institution',
          countryLabel: 'Country',
          countrySearchLabel: 'Search',
          street1Label: 'Address',
          street2Label: 'Address 2 (optional)',
          cityLabel: 'City',
          stateLabel: 'State',
          provinceLabel: 'State / Province',
          zipCode: 'Zip Code',
          postalCode: 'Postcode',
        },
        withdrawalWirePlacedPage: {
          amountLabel: 'Withdrawal amount',
          totalLabel: 'Total',
          internalNoteTitle: 'Internal note',
          memoTitle: 'Memo to be delivered',
        },
        withdrawalWireRecipientInfoPage: {
          fieldsHeader: 'RECIPIENT INFORMATION',
          fullNameLabel: 'Full name of recipient',
          countryLabel: 'Recipient’s Country',
          countrySearchLabel: 'Search',
          street1Label: 'Recipient’s address',
          street2Label: 'Apartment / Suite (optional)',
          cityLabel: 'City',
          stateLabel: 'State',
          provinceLabel: 'State / Province',
          zipCode: 'Zip Code',
          postalCode: 'Postcode',
          radioGroupHeader: 'Is the bank or financial institution US domestic or international?',
          radioGroupDomesticLabel: 'Domestic (USA)',
          radioGroupInternationalLabel: 'International',
        },
        withdrawalWireReviewPage: {
          withdrawalAmount: 'Withdrawal amount',
          sendTo: 'Send to',
          financialInstitution: 'Financial Institution',
          bankCountry: 'Country',
          bankCountryUS: 'USA',
          accountNumber: 'Account number',
          endingIn: 'ending in',
          from: 'From',
          wireButton: 'Wire {{amount}}',
          processingError: 'Unable to process your transaction at this time. Please try again.',
          internalNoteTitle: 'Internal note',
          memoTitle: 'Memo to be delivered',
          purposeOfPayment: 'Purpose of payment',
        },
        withdrawalWireValidation: {
          accountNumber: 'Please enter a valid account number',
          confirmAccountNumber: 'Account number does not match',
          routingNumber: 'Please enter a valid routing number',
          swiftCode: 'SWIFT Number must be 8-11 characters',
          fullName: 'This field is required',
          street1: 'Please enter a valid address',
          apartment: 'Please enter a valid apartment / suite',
          address2: 'Please enter a valid address 2',
          city: 'Please enter a valid city',
          state: 'Please enter a valid state',
          zipCode: 'Invalid ZIP code',
          postalCode: 'Invalid postal code',
          bankName: 'Please enter a valid name of bank or financial institution',
          purposeOfPaymentRequired: 'International wire requires the Purpose of payment',
          purposeOfPaymentMin: 'Must be at least 5 characters',
          purposeOfPaymentMax: 'Must be 16 characters or less',
        },
        withdrawalWireRecipientSearchPage: {
          addRecipientBtnTitle: 'Add New Recipient',
          searchInputPlaceholder: 'Search for recipient',
          endingIn: 'Ending in',
          deleteConfirmModal: {
            subtitle: 'Are you sure that you want to delete this External Account?',
          },
        },
        internalTransferValidation: {
          comment: 'Please enter from 1 to 50 symbols',
        },
        internalTransferAmountPage: {
          amount: 'Amount',
          commentLabel: 'Comment',
          addCommentButton: 'Add a comment',
          commentHelperText: 'Add a comment to your transfer',
          reviewButton: 'Review',
          fromLabel: 'From',
          toLabel: 'Send to',
          availableAmountLabel: 'Available for transfer',
        },
        internalTransferPlacedPage: {
          amountLabel: 'Transfer amount',
          commentLabel: 'Comment',
        },
        internalTransferRecipientSearchPage: {
          endingIn: 'Ending in',
          searchInputPlaceholder: 'Search for recipient account',
          helperText: 'Fill in Account ID or Account number or Account name',
        },
        internalTransferReviewPage: {
          internalTransferButton: 'Transfer {{amount}}',
          processingError: 'Unable to process your transaction at this time. Please try again.',
          amountLabel: 'Transfer amount',
          commentLabel: 'Comment',
        },
      },
    },
    enumMapping: {
      custodialAccountLabel: {
        user: 'User',
        corporate: 'Corporate',
      },
    },
    environment: {
      label: {
        int: 'Int',
        dev: 'Dev',
        stage: 'Stage',
        sandbox: 'Sandbox',
      },
    },
    backendErrorMessages: {
      tradingOrTransferringIsForbiddenForSuspendedAccounts:
        'Trading or transferring is forbidden for suspended accounts',
      bankNameCannotBeLongerThan: 'Bank account name should be up to 35 symbols.',
      noBankInformationFound: 'No bank information found with routing number.',
      routingNumberDoesNotProcessFedwirePayments: 'Routing Number does not process Fedwire Payments.',
      otherExceptions: 'There was an error processing your request. Please try again.',
      actionIsForbidden: 'Action is forbidden. Please refresh the page',
    },
  },
};
