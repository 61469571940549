import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridFilterPopup } from 'components/shared/grids/CustomizedGrid/GridFilterPopup';
import { Gap } from 'components/shared/Gap';
import { DropdownButton } from 'components/shared/buttons/DropDownButton';
import { MultiValueFilter } from 'filters/multi-value-filter';
import { Filter } from 'filters/interfaces/filter';
import { DateFilter } from 'filters/date-filter';
import { FilterResult } from 'filters/interfaces/filter-result';
import { SelectorCommand } from 'filters/interfaces/selector-command';
import { PaginatedRequestParams, PDFStatementRequestParams } from 'redux/api/types';
import { ExportCSVModal } from './ExportCSVModal';
import { FiltersButton } from './FiltersButton';
import { ExportButton } from './ExportButton';
import { StyledButtonsRow, StyledContainer, StyledHeaderLabel, StyledMainRow } from './styled';
import { ExportPDFModal } from './ExportPDFModal';

type Props = {
  headerName: string;
  selectionMacroCommand: SelectorCommand;
  filters: Filter[];
  fetchOtherItems: (page?: number, filterResult?: FilterResult) => void;
  noPureData: boolean; // refers to data from server without filtering applied
  onExportClick: (pagination: PaginatedRequestParams, filterResult?: FilterResult) => void;
  onPDFExportClick?: (requestParams: PDFStatementRequestParams) => void;
  isExportLoading?: boolean;
};

const NO_VALUE_INDEX = -1;

const DEFAULT_PAGE = '1';
const DEFAULT_PAGE_SIZE = '5000';

export const CustomHeader: React.FC<Props> = ({
  headerName,
  selectionMacroCommand,
  filters,
  fetchOtherItems,
  noPureData,
  isExportLoading,
  onPDFExportClick,
  onExportClick,
}) => {
  const { t } = useTranslation();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [columnsRowHeight, setColumnsRowHeight] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  // Column UI State
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(selectionMacroCommand.getSelectedCommandSlot());
  // Operator UI State
  const [selectedOperatorIndex, setSelectedOperatorIndex] = useState(0);
  // Value UI State
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [selectedValueIndex, setSelectedValueIndex] = useState(NO_VALUE_INDEX);
  const [selectedDateValue, setSelectedDateValue] = useState<string>('');

  useEffect(() => {
    if (headerRef.current) {
      setColumnsRowHeight(headerRef.current.offsetParent?.children[1].children[0].clientHeight ?? 0);
    }
  }, []);

  useEffect(() => {
    setSelectedColumnIndex(selectionMacroCommand.getSelectedCommandSlot());
    return () => selectionMacroCommand.reset();
  }, [selectionMacroCommand]);

  const resetColumnUIState = () => {
    setSelectedColumnIndex(selectionMacroCommand.getSelectedCommandSlot());
  };

  const resetOperatorUIState = () => {
    setSelectedOperatorIndex(0);
  };

  const resetValueUIState = (columnIdx: number) => {
    const currentFilter = filters[columnIdx];
    if (currentFilter instanceof DateFilter) {
      setSelectedDateValue(currentFilter.getSelectedValue());
    } else if (currentFilter instanceof MultiValueFilter) {
      setSelectedValueIndex(NO_VALUE_INDEX);
    } else {
      setSelectedValue(currentFilter.getSelectedValue());
    }
  };

  const hidePopup = () => {
    setShowPopup(false);
  };

  const hasValue = () => {
    const currentFilter = filters[selectionMacroCommand.getSelectedCommandSlot()];
    if (currentFilter instanceof DateFilter) {
      return selectedDateValue !== '';
    }
    if (currentFilter instanceof MultiValueFilter) {
      return selectedValueIndex !== NO_VALUE_INDEX;
    }

    return selectedValue.trim() !== '';
  };

  const reset = () => {
    // reset
    selectionMacroCommand.reset();
    // reset ui
    resetOperatorUIState();
    resetValueUIState(selectionMacroCommand.getSelectedCommandSlot());
    resetColumnUIState();
    hidePopup();
  };

  const resetToAppliedFilterState = () => {
    // restore saved state
    selectionMacroCommand.resetToSavedState();
    // sync ui state with data layer
    const { operator, value } = selectionMacroCommand.getState();
    const colIndex = selectionMacroCommand.getSelectedCommandSlot();

    const currentFilter = filters[colIndex];
    // update operator ui state
    const operatorIndex = currentFilter.getAllOperators().findIndex(o => o === operator);
    setSelectedOperatorIndex(operatorIndex);

    // update value ui state
    if (currentFilter instanceof DateFilter) {
      setSelectedDateValue(currentFilter.getSelectedValue());
    } else if (currentFilter instanceof MultiValueFilter) {
      const valueIndex = currentFilter.getAllValues().findIndex(v => v === value);
      setSelectedValueIndex(valueIndex);
    } else {
      setSelectedValue(currentFilter.getSelectedValue());
    }

    // update column index ui state
    setSelectedColumnIndex(colIndex);
  };

  // handlers

  const onFiltersButtonClick = () => {
    setAnchorEl(headerRef.current);
    if (showPopup) {
      if (!isFilterApplied) {
        // reset
        selectionMacroCommand.reset();
        // reset ui
        resetOperatorUIState();
        resetValueUIState(selectionMacroCommand.getSelectedCommandSlot());
        resetColumnUIState();
      } else {
        resetToAppliedFilterState();
      }
    }
    setShowPopup(prevState => !prevState);
  };

  const [isExportCVSModalShown, setIsExportCVSModalShown] = useState(false);
  const [isExportPDFModalShown, setIsExportPDFModalShown] = useState(false);

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const onExportButtonClick = () => {
    setIsExportCVSModalShown(true);
  };

  const onExportCSVButtonClick = () => {
    setIsExportCVSModalShown(true);
  };

  const onExportPDFButtonClick = () => {
    setIsExportPDFModalShown(true);
  };

  const onExportSubmit = () => {
    if (selectionMacroCommand.hasSavedCommandSlot()) {
      onExportClick({ page, pageSize }, selectionMacroCommand.getState());
    } else {
      onExportClick({ page, pageSize });
    }
    setIsExportCVSModalShown(false);
    setPage(DEFAULT_PAGE);
    setPageSize(DEFAULT_PAGE_SIZE);
  };

  const onPdfExportSubmit = (dateRange: PDFStatementRequestParams) => {
    if (onPDFExportClick) {
      onPDFExportClick(dateRange);
      setIsExportPDFModalShown(false);
    }
  };

  const onColumnFilterChange = (idx: number) => {
    // when we select column with applied filter, we need to see saved version of it
    if (idx === selectionMacroCommand.getSavedCommandSlot()) {
      resetToAppliedFilterState();
      return;
    }

    selectionMacroCommand.setSelectedCommandSlot(idx);
    selectionMacroCommand.getSelectedCommand().reset();
    // reset ui
    resetOperatorUIState();
    resetValueUIState(idx);
    setSelectedColumnIndex(idx);
  };

  const onOperatorChange = (idx: number) => {
    const currentFilter = filters[selectionMacroCommand.getSelectedCommandSlot()];
    const selectedOperator = currentFilter.getAllOperators()[idx];
    currentFilter.setSelectedOperator(selectedOperator);
    setSelectedOperatorIndex(idx);
  };

  const onValueChange = (value: number | string) => {
    const currentFilter = filters[selectionMacroCommand.getSelectedCommandSlot()];
    if (typeof value === 'string') {
      currentFilter.setSelectedValue(value);
      // update ui state
      if (currentFilter instanceof DateFilter) {
        setSelectedDateValue(currentFilter.getSelectedValue());
      } else {
        setSelectedValue(currentFilter.getSelectedValue());
      }
    } else {
      const enumStringValue = (currentFilter as MultiValueFilter).getAllValues()[value];
      currentFilter.setSelectedValue(enumStringValue);
      // update ui state
      setSelectedValueIndex(value);
    }
  };

  const onApplyButtonClick = () => {
    const result = selectionMacroCommand.getState();
    selectionMacroCommand.save();
    fetchOtherItems(undefined, result);
    // no reset, we need to remember applied filter
    hidePopup();
    setIsFilterApplied(true);
  };

  const onClearButtonClick = () => {
    fetchOtherItems();
    reset();
    setIsFilterApplied(false);
  };

  const onCancelButtonClick = () => {
    if (!isFilterApplied) {
      reset();
      return;
    }
    resetToAppliedFilterState();

    hidePopup();
  };

  const buttonContent = useCallback(
    ({ ref, onClick, isDisabled, isLoading }) => (
      <ExportButton ref={ref} onClick={onClick} isDisabled={isDisabled} isLoading={isLoading} />
    ),
    [],
  );
  return (
    <>
      <StyledContainer ref={headerRef}>
        <StyledMainRow>
          <StyledHeaderLabel>{headerName}</StyledHeaderLabel>
          <StyledButtonsRow>
            <div>
              <FiltersButton isDisabled={noPureData} onClick={onFiltersButtonClick} isFilterApplied={isFilterApplied} />
            </div>
            <Gap size="_16px" isHorizontal />
            {onPDFExportClick ? (
              <DropdownButton
                buttonContent={buttonContent}
                isDisabled={noPureData}
                isLoading={isExportLoading}
                menuItems={[
                  { label: t('filters.exportCSVButtonLabel'), onClick: onExportCSVButtonClick },
                  { label: t('filters.exportPDFButtonLabel'), onClick: onExportPDFButtonClick },
                ]}
              />
            ) : (
              <ExportButton isDisabled={noPureData} isLoading={isExportLoading} onClick={onExportButtonClick} />
            )}
          </StyledButtonsRow>
        </StyledMainRow>

        <GridFilterPopup
          open={showPopup && anchorEl !== null}
          anchorEl={anchorEl}
          columnsRowHeight={columnsRowHeight}
          selectedColumnIndex={selectedColumnIndex}
          columnItems={filters.map(f => f.getProperty())}
          columnItemsDisplayValues={filters.map(f => f.getDisplayProperty())}
          operatorItems={filters[selectedColumnIndex].getAllOperators()}
          operatorItemsDisplayValues={filters[selectedColumnIndex].getAllOperatorsDisplayValues()}
          selectedOperatorIndex={selectedOperatorIndex}
          selectedValueIndex={selectedValueIndex}
          selectedValue={selectedValue}
          selectedDate={selectedDateValue}
          onColumnFilterChange={onColumnFilterChange}
          onOperatorChange={onOperatorChange}
          data={
            filters[selectedColumnIndex] instanceof MultiValueFilter
              ? (filters[selectedColumnIndex] as MultiValueFilter).getAllValues()
              : filters[selectedColumnIndex].getSelectedValue()
          }
          dataDisplayValues={
            filters[selectedColumnIndex] instanceof MultiValueFilter
              ? (filters[selectedColumnIndex] as MultiValueFilter).getAllValuesDisplayValues()
              : undefined
          }
          isDatePickerMode={filters[selectedColumnIndex] instanceof DateFilter}
          onValueChange={onValueChange}
          isApplyButtonDisabled={!hasValue()}
          onApplyButtonClick={onApplyButtonClick}
          onClearButtonClick={onClearButtonClick}
          onCancelButtonClick={onCancelButtonClick}
        />
      </StyledContainer>
      <ExportCSVModal
        isOpen={isExportCVSModalShown}
        onClose={() => setIsExportCVSModalShown(false)}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        onExportSubmit={onExportSubmit}
      />
      <ExportPDFModal
        isOpen={isExportPDFModalShown}
        onClose={() => setIsExportPDFModalShown(false)}
        onExportSubmit={onPdfExportSubmit}
      />
    </>
  );
};
