import { ProviderContext } from 'notistack';
import { CustomError, isNetworkError, isNotFound } from 'elements/api';
import i18n from 'localizations';
import {
  BALANCE_OF_WALLET_FOR_NATIVE_COIN_IS_NOT_SUFFICIENT,
  CA_OR_IDENTITY_SUSPENDED,
  DEFAULT_ERROR_CODE_ELEMENTS,
  FUNCTIONALITY_IS_NOT_AVAILABLE_FOR_ORGANIZATION,
  TRANSFER_IS_FORBIDDEN_FOR_ASSET_PAIRS,
  WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_ACCOUNT,
  WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_TRANSACTION,
} from 'redux/api/error-codes';

const getErrorMessage = (error: unknown, onlyBannerError: boolean) => {
  if (isNetworkError(error)) {
    return i18n.t('elements.common.errorBanner.connectionIssuesErrorMessage');
  }

  if (isNotFound(error)) {
    return i18n.t('serverError.notFound');
  }

  if ((error as CustomError)?.responseData?.errors?.[CA_OR_IDENTITY_SUSPENDED]) {
    return i18n.t('backendErrorMessages.tradingOrTransferringIsForbiddenForSuspendedAccounts');
  }

  if ((error as CustomError)?.responseData?.errors?.[TRANSFER_IS_FORBIDDEN_FOR_ASSET_PAIRS]) {
    return i18n.t('serverError.transferIsForbiddenForAssetPairs');
  }

  if ((error as CustomError)?.responseData?.errors?.[WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_ACCOUNT]) {
    return i18n.t('serverError.withdrawalAmountIsLowerRequiredMinAccountBalance');
  }

  if ((error as CustomError)?.responseData?.errors?.[WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_TRANSACTION]) {
    return i18n.t('serverError.withdrawalAmountIsLowerRequiredMinTransactionBalance');
  }

  if ((error as CustomError)?.responseData?.errors?.[BALANCE_OF_WALLET_FOR_NATIVE_COIN_IS_NOT_SUFFICIENT]) {
    return i18n.t('serverError.balanceOfWalletForNativeCoinIsNotSufficient');
  }

  if ((error as CustomError)?.responseData?.errors?.[DEFAULT_ERROR_CODE_ELEMENTS]) {
    return i18n.t('serverError.somethingWentWrong');
  }

  if ((error as CustomError)?.responseData?.errors?.[FUNCTIONALITY_IS_NOT_AVAILABLE_FOR_ORGANIZATION]) {
    return i18n.t('serverError.functionalityIsNotAvailableForOrganization');
  }

  return onlyBannerError ? i18n.t('elements.common.errorBanner.processingError') : null;
};

export const setErrorBanner = (
  e: unknown,
  enqueueSnackbar: ProviderContext['enqueueSnackbar'],
  onlyBannerError = false,
  message = '',
) => {
  const errorMessage = message || getErrorMessage(e, onlyBannerError);

  if (errorMessage) {
    enqueueSnackbar(errorMessage, { variant: 'error' });
    return true;
  }

  return false;
};
