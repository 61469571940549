import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { EmptyState } from 'elements/features/IdentityCheck/EmptyState';
import { pricesLabels } from 'elements/element-trade/utils/labelsMapping';
import { TradeFlow } from 'elements/element-trade/types';
import { BannerType, InlineBanner, NumberInput, Spacer } from 'elements/components';
import EstimatedPricesSection from 'elements/element-trade/components/EstimatedPricesSection';
import { MIN_DOLLAR_AMOUNT } from 'elements/element-trade/constants';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { getUniqueIdentifierForAssetResponseModel } from 'utils/assets/get-unique-identifier-for-asset-response-model';
import { CoinsBuySellDropdown, renderSelectedValue } from './CoinsBuySellDropdown';

type Props = {
  flowType: TradeFlow;
  selectedCoin: AssetResponseModel | CustodialAccountBalanceModel | null;
  coinsList: Array<AssetResponseModel | CustodialAccountBalanceModel>;
  amount: string;
  amountError: boolean;
  minAmountError: boolean;
  lockAmountError: boolean;
  coinPrice: number;
  coinPriceError: boolean;
  coinPriceLoading: boolean;
  serviceUnavailable: boolean;
  contactUsUrl: string | null;
  onAmountInputChange: (
    event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
  ) => void;
  onAmountInputBlur: () => void;
  onAmountInputFocus: () => void;
  onCoinDropdownChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onReloadCoinPrice: () => void;
};

const getCoinsDropDownValue = (
  selectedCoin: AssetResponseModel | CustodialAccountBalanceModel | null,
  flowType: TradeFlow,
): string | null => {
  if (!selectedCoin) {
    return null;
  }

  if (flowType === TradeFlow.Sell) {
    return (selectedCoin as CustodialAccountBalanceModel).id;
  }

  return getUniqueIdentifierForAssetResponseModel(selectedCoin);
};

const PageContent: React.FC<Props> = ({
  selectedCoin,
  coinsList,
  flowType,
  serviceUnavailable,
  amount,
  amountError,
  minAmountError,
  lockAmountError,
  coinPrice,
  coinPriceError,
  coinPriceLoading,
  contactUsUrl,
  onAmountInputChange,
  onAmountInputFocus,
  onAmountInputBlur,
  onCoinDropdownChange,
  onReloadCoinPrice,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const coinsDropDownValue = getCoinsDropDownValue(selectedCoin, flowType);

  const renderValueHandler = useMemo(
    () => renderSelectedValue(coinsList, flowType === TradeFlow.Sell),
    [coinsList, flowType],
  );

  const amountErrorText = amountError ? pricesLabels[flowType].amountError('$', MIN_DOLLAR_AMOUNT, minAmountError) : '';
  const limitsErrorText = lockAmountError ? t('elements.transfer.withdrawalWireAmountPage.initLimitsError') : '';

  return serviceUnavailable ? (
    <Box height="100%">
      <InlineBanner
        type={BannerType.Error}
        text={t('elements.trade.buySellPage.tradingUnavailableBannerText1')}
        text2={t('elements.trade.buySellPage.tradingUnavailableText2')}
        buttonTitle={t('elements.trade.buySellPage.contactUsLinkText')}
        linkHref={contactUsUrl}
      />
      <EmptyState />
    </Box>
  ) : (
    <>
      <NumberInput
        prefix={flowType === TradeFlow.Buy ? '$' : ''}
        decimalScale={flowType === TradeFlow.Buy ? 2 : 8}
        name="amount"
        value={amount}
        onChange={onAmountInputChange}
        onBlur={onAmountInputBlur}
        onFocus={onAmountInputFocus}
        placeholder={flowType === TradeFlow.Buy ? '$0.00' : '0.0'}
        label={pricesLabels[flowType].amount}
        error={amountError || lockAmountError}
        helperText={amountErrorText || limitsErrorText}
      />
      <div>
        <CoinsBuySellDropdown
          flowType={flowType}
          fullWidth
          name="coin"
          value={coinsDropDownValue}
          coinsList={coinsList}
          withSearch={flowType === TradeFlow.Buy || coinsList.length > (isMobile ? 5 : 8)}
          renderValueHandler={renderValueHandler}
          onChange={onCoinDropdownChange}
        />
        <Spacer size={8} />
      </div>
      {selectedCoin && (
        <EstimatedPricesSection
          totalLabel={pricesLabels[flowType].total}
          assetType={selectedCoin.assetTicker}
          coinPrice={coinPrice}
          amount={amount}
          isError={coinPriceError}
          isLoading={coinPriceLoading}
          onReloadCoinPrice={onReloadCoinPrice}
          flowType={flowType}
        />
      )}
    </>
  );
};

export default PageContent;
