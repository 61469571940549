import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { Backdrop, Typography, useTheme } from '@mui/material';
import { Button, Divider, InlineBanner, NoteReview, Spacer } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import { formatCryptoBalance, formatUsdBalance } from 'elements/utils';
import { ReactComponent as RoundedArrowIcon } from 'elements/element-transfer/assets/icons/roundedArrow.svg';
import Layout from 'elements/element-transfer/components/Layout';
import TransactionSummary from 'elements/element-transfer/components/TransactionSummary';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { TrustAssetType } from 'ui-enums/response/custodial-accounts/trust-asset-type';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import InternalTransferTo from '../InternalTransferTo';
import InternalTransferSource from '../InternalTransferSource';

type Props = {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  errorTraceId: string;
  amount: string;
  selectedCoin: CustodialAccountBalanceModel | null;
  recipientName: string;
  recipientAccountNumber: string;
  custodialAccount: CustodialAccount | null;
  custodialAccountName: string;
  comment: string;
  onBack: () => void;
  onClose: () => void;
  onSend: () => void;
};

const InternalTransferReviewPage: React.FC<Props> = ({
  loading,
  error,
  errorMessage,
  errorTraceId,
  amount,
  selectedCoin,
  recipientName,
  recipientAccountNumber,
  custodialAccount,
  custodialAccountName,
  comment,
  onBack,
  onClose,
  onSend,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const theme = useTheme<ElementsCustomTheme>();

  const formattedAmount =
    selectedCoin?.assetType.toLowerCase() === TrustAssetType.fiatCurrency.toLowerCase()
      ? formatUsdBalance(Number(amount), isMobile)
      : formatCryptoBalance(Number(amount), { coinAbbreviation: selectedCoin?.assetTicker, withCommas: true });

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.internalTransferReviewPageTitle')}
      onBack={onBack}
      onClose={onClose}
      footerButtons={
        <Button isLoading={loading} onClick={onSend}>
          <Typography variant="buttonLarge">
            {t('elements.transfer.internalTransferReviewPage.internalTransferButton', {
              amount: formattedAmount,
            })}
          </Typography>
          <CheckIcon />
        </Button>
      }
    >
      {error && (
        <>
          <InlineBanner
            text={errorMessage || t('elements.transfer.internalTransferReviewPage.processingError')}
            errorTraceId={errorTraceId}
          />
          <Spacer size={isMobile ? 24 : 16} />
        </>
      )}
      <Backdrop open={loading} sx={{ backgroundColor: theme.palette.background.whiteTransparent50, zIndex: 5 }} />
      <TransactionSummary
        icon={RoundedArrowIcon}
        amount={formattedAmount}
        amountLabel={t('elements.transfer.internalTransferReviewPage.amountLabel')}
        totalAmount={formattedAmount}
        totalLabel={t('elements.common.total')}
      />
      <Spacer size={40} />
      <InternalTransferTo recepientName={recipientName} recepientAccountNumber={recipientAccountNumber} />
      <Spacer size={isMobile ? 32 : 24} />
      {!!custodialAccount && (
        <>
          <InternalTransferSource custodialAccountName={custodialAccountName} custodialAccount={custodialAccount} />
          <Spacer size={isMobile ? 32 : 24} />
        </>
      )}
      {!!comment && (
        <>
          <NoteReview note={comment} title={t('elements.transfer.internalTransferReviewPage.commentLabel')} />
          <Divider />
          <Spacer size={32} />
        </>
      )}
    </Layout>
  );
};

export default InternalTransferReviewPage;
