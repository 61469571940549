import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';

import * as api from 'elements/element-transfer/api';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';

import { setErrorBanner } from 'elements/utils';
import { DEFAULT_PAGE_COUNT, FIRST_PAGE, PAGE_SIZE_10 } from 'elements/constants';
import {
  mergeInternalTransferRecipientData,
  resetInternalTransferRecipientData,
  resetInternalTransferRecipients,
  setPage,
  setInternalTransferRecipientSearchValue,
  setInternalTransferRecipients,
  useElement,
} from 'elements/element-transfer/contexts/Element';

import { CustodialAccountStatus } from 'models/enums/custodial-accounts/custodial-account-status';
import useInfiniteScroll from './useInfiniteScroll';

const useInternalTransferRecipientSearchPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();
  const {
    search,
    recipientId,
    recipients: { list: recipientList, page: pageFromStore, pageCount: pageCountFromStore },
  } = state[TransferFlow.Withdrawal].INTERNAL;

  const [isRecipientsLoading, setIsRecipientsLoading] = useState(false);

  const [lastItem, setLastItem] = useState<HTMLDivElement | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  const getCustodialAccounts = async (value: string, page: number, pageCount: number) => {
    if (page > pageCount && pageCount !== 0) return;
    try {
      setIsRecipientsLoading(true);

      const { data: custodialAccountList, meta } = await api.transferElement.getCustodialAccounts({
        'filter[searchInfo cont]': value,
        'filter[status eq]': CustodialAccountStatus.open,
        page: page || FIRST_PAGE,
        pageSize: PAGE_SIZE_10,
      });

      const list = custodialAccountList.filter(custodialAccount => custodialAccount.id !== state.custodialAccountId);
      if (page === FIRST_PAGE) {
        dispatch(resetInternalTransferRecipients());
      }
      dispatch(setInternalTransferRecipients({ list, page, pageCount: meta?.pageCount || 0 }));
    } catch (err) {
      setErrorBanner(err, enqueueSnackbar, true);
    } finally {
      setIsRecipientsLoading(false);
    }
  };

  const onBack = () => {
    dispatch(setInternalTransferRecipientSearchValue(''));
    dispatch(setPage(Paths.Transfer));
  };

  const onSearch = async (value: string) => {
    dispatch(resetInternalTransferRecipients());
    dispatch(setInternalTransferRecipientSearchValue(value));

    await getCustodialAccounts(value, FIRST_PAGE, DEFAULT_PAGE_COUNT);
  };

  const onContinue = (id: string) => {
    dispatch(resetInternalTransferRecipientData());
    dispatch(setInternalTransferRecipientSearchValue(''));
    const recipient = recipientList?.find(rec => rec.id === id);

    if (recipient) {
      dispatch(mergeInternalTransferRecipientData(recipient));
      dispatch(setPage(Paths.InternalTransferAmount));
    }
  };

  const getExternalAccountsCallback = useCallback(
    () => getCustodialAccounts(search, pageFromStore + 1, pageCountFromStore),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, pageFromStore, pageCountFromStore],
  );

  useInfiniteScroll(lastItem, getExternalAccountsCallback);

  // is used to resolve the problem of getting ref of the last component at conditional rendering of the recipients list
  useEffect(() => {
    setLastItem(lastItemRef?.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientList]);

  return {
    search,
    recipientList,
    isRecipientsLoading,
    recipientId,
    lastItemRef,
    onBack,
    onSearch,
    onContinue,
  };
};

export default useInternalTransferRecipientSearchPage;
