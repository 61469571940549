import React, { useRef, useState, ReactNode } from 'react';
import { MenuItem, Typography } from '@mui/material';
import { StyledMenu } from './styled';

type DropdownButtonProps = {
  buttonContent: (props: {
    ref: React.Ref<HTMLButtonElement>;
    onClick: () => void;
    isDisabled: boolean;
    isLoading: boolean;
  }) => ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  menuItems: Array<{
    label: string;
    onClick: () => void;
  }>;
};

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  buttonContent,
  isDisabled = false,
  menuItems,
  isLoading = false,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(prev => !prev);
  const closeMenu = () => setIsOpen(false);

  return (
    <div>
      {buttonContent({ ref: buttonRef, onClick: toggleMenu, isDisabled, isLoading })}

      <StyledMenu
        anchorEl={buttonRef.current}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuItems.map(item => (
          <MenuItem
            key={item.label}
            onClick={() => {
              item.onClick();
              closeMenu();
            }}
          >
            <Typography variant="bodyDefaultBook">{item.label}</Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};
