import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import { StyledTooltip } from 'components/shared/StyledTooltip';
import { SideMenuListItem } from 'ui-interfaces/side-menu/side-menu-list-item';
import { getSideMenuFadedIcon } from 'utils/icons-mapping/side-menu/get-side-menu-faded-icon';
import { StyledListItemButton, StyledListItemIcon } from '../styled';
import { StyledLink } from './styled';

type Props = {
  el: SideMenuListItem;
  open: boolean;
};

export const SideMenuExternalLinkItem: React.FC<Props> = ({ el, open }) => {
  return (
    <StyledLink target="_blank" rel="noopener noreferrer" href={el.to}>
      {!open ? (
        <StyledTooltip title={el.label} placement="right">
          <StyledListItemButton key={el.label}>
            <StyledListItemIcon>{getSideMenuFadedIcon(el.icon)}</StyledListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={el.label} />
          </StyledListItemButton>
        </StyledTooltip>
      ) : (
        <StyledListItemButton key={el.label}>
          <StyledListItemIcon>{getSideMenuFadedIcon(el.icon)}</StyledListItemIcon>
          <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={el.label} />
        </StyledListItemButton>
      )}
    </StyledLink>
  );
};
