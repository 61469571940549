import i18n from 'i18next';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { SideMenu } from 'ui-interfaces/side-menu/side-menu';
import { SideMenuIcon } from 'ui-enums/side-menu/side-menu-icon';
import { SideMenuListItem } from 'ui-interfaces/side-menu/side-menu-list-item';
import { SUPPORT_URL } from 'view-models/side-menu/constants';

export class SideMenuBaseViewModel implements Pick<SideMenu, 'footerTitle' | 'footerSubTitle' | 'helpListItem'> {
  readonly footerTitle: string = '';

  readonly footerSubTitle: string = '';

  readonly helpListItem: SideMenuListItem = {
    id: 'help',
    label: `${i18n.t('sideMenu.labels.help')}`,
    icon: SideMenuIcon.help,
    iconFaded: SideMenuIcon.help,
    to: SUPPORT_URL || '',
    externalLink: true,
  };

  constructor(user?: ShortUserResponseModel) {
    if (user) {
      this.footerTitle = `${user.firstName} ${user.lastName}`;
      this.footerSubTitle = user.email;
    }
  }
}
