import { DialogActions, DialogContent, DialogTitle, IconButton, styled, Typography } from '@mui/material';
import { Row } from 'components/shared/Flex/Row';
import { pxToRem } from 'utils/styling-utils';

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  padding: `${pxToRem(theme.gaps._64px)} ${pxToRem(theme.gaps._40px)} ${pxToRem(theme.gaps._12px)}`,
  textAlign: 'center',
  wordBreak: 'break-word',
  fontFamily: 'GothamMedium',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: pxToRem(26),
  lineHeight: '130%',
  color: '#1C1C1C',
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  textAlign: 'center',
  padding: `0 ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: `${pxToRem(theme.gaps._32px)} ${pxToRem(theme.gaps._40px)} ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: pxToRem(theme.gaps._16px),
  right: pxToRem(theme.gaps._16px),
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
}));

export const StyledRow = styled(Row)(() => ({
  justifyContent: 'center',
}));

export const StyledTypography = styled(Typography)(() => ({
  textAlign: 'center',
  wordBreak: 'break-word',
}));

export const StyledCaptionTypography = styled(StyledTypography)(() => ({
  fontFamily: 'GothamBook',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: pxToRem(14),
  lineHeight: '160%',
  color: '#707070',
}));

export const StyledMainTextTypography = styled(StyledTypography)(() => ({
  fontFamily: 'GothamMedium',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: pxToRem(14),
  lineHeight: '160%',
  color: '#1C1C1C',
}));
