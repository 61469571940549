import { Box, FormControlLabel, formControlLabelClasses, styled, Typography } from '@mui/material';
import { css } from '@mui/material/styles';
import { ReactComponent as AccountBalanceWalletIcon } from 'elements/element-transfer/assets/icons/accountBalanceWallet.svg';
import { elementsStyled } from 'elements/theme/createCustomTheme';
import { pxToRem } from 'utils/styling-utils';

export const StyledContainer = elementsStyled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(theme.gaps._16px)};
  `,
);

export const StyledTypography = elementsStyled(Typography)(
  ({ theme }) => css`
    text-transform: uppercase;
    color: ${theme.palette.secondary.font};
  `,
);

export const StyledAccountBalanceWalletIcon = elementsStyled(AccountBalanceWalletIcon)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.font};
  `,
);

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  padding: `0 0 ${pxToRem(theme.gaps._12px)} 0`,
  [`& .${formControlLabelClasses.label}`]: {
    marginLeft: pxToRem(theme.gaps._12px),
  },
}));
