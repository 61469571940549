import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Gap } from 'components/shared/Gap';
import { ActionModal } from '../ActionModal';
import {
  StyledCaptionTypography,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
  StyledMainTextTypography,
  StyledRow,
} from './styled';
import { StyledButton } from '../StyledButton/styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  captionText: string;
  mainText: string;
  actionButton: React.ReactNode;
};

export const CommonActionModal: React.FC<Props> = ({ isOpen, onClose, title, captionText, mainText, actionButton }) => {
  const { t } = useTranslation();

  return (
    <ActionModal isOpen={isOpen} onClose={onClose}>
      <StyledDialogTitle>
        {title}
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledRow>
          <StyledCaptionTypography>{captionText}</StyledCaptionTypography>
        </StyledRow>
        <Gap size="_4px" />
        <StyledRow>
          <StyledMainTextTypography>{mainText}</StyledMainTextTypography>
        </StyledRow>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton buttonType="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">{t('transactionDetailsPage.actionDialog.cancelButtonLabel')}</Typography>
        </StyledButton>
        <Gap isHorizontal size="_16px" />
        {actionButton}
      </StyledDialogActions>
    </ActionModal>
  );
};
