import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';

import { ExternalAccount } from 'elements/types';

import { ReactComponent as NoResultsIcon } from 'elements/element-transfer/assets/icons/no_results.svg';
import { BaseInput, Button, CircularProgress } from 'elements/components';
import FlowFirstPageLayout from 'elements/element-transfer/components/FlowFirstPageLayout';
import BaseRadioGroup from 'elements/element-transfer/components/BaseRadioGroup';
import { DestroyActionModal } from 'elements/components/ConfirmDeleteModal/DestroyActionModal';
import RadioLabel from './RadioLabel';

import { StyledButton, StyledLoaderContainer } from './styled';

type Props = {
  search: string;
  recipients: Array<ExternalAccount>;
  isRecipientsLoading: boolean;
  isDeleteLoading: boolean;
  recipientId?: string;
  lastItemRef: React.MutableRefObject<HTMLDivElement | null>;
  onAddNewRecipient: () => void;
  onSearch: (value: string) => void;
  onClose: () => void;
  onBack: () => void;
  onContinue: (id: string) => void;
  onDeleteRecipient: (id: string) => void;
};

const WireRecipientSearchPage: React.FC<Props> = ({
  search,
  recipients,
  isRecipientsLoading,
  isDeleteLoading,
  recipientId: id,
  lastItemRef,
  onAddNewRecipient,
  onSearch,
  onClose,
  onBack,
  onContinue,
  onDeleteRecipient,
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState(search);
  const [recipientId, setRecipientId] = useState(id || '');
  const [deleteRecipientId, setDeleteRecipientId] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRecipientName, setDeleteRecipientName] = useState('');
  const formattedRadioValues = useMemo(
    () => recipients.map(rec => ({ value: rec.id, label: rec.id, data: rec })),
    [recipients],
  );

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onSearchClick = () => {
    const trimmedValue = searchValue.trim();
    setSearchValue(trimmedValue);
    setRecipientId('');
    onSearch(trimmedValue);
  };

  const onRecipientChange = (e: React.ChangeEvent<HTMLInputElement>) => setRecipientId(e.target.value);

  const onDeleteClick = (deleteId: string) => {
    const name = formattedRadioValues.find(val => val.data.id === deleteId)?.data.name;
    if (name) {
      setDeleteRecipientName(name);
    }
    setDeleteRecipientId(deleteId);
    setIsDeleteModalOpen(true);
  };

  const onConfirmDeleteClick = async () => {
    await onDeleteRecipient(deleteRecipientId);
    setIsDeleteModalOpen(false);
  };

  const onCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <FlowFirstPageLayout
      title={t('elements.transfer.customizableLabels.withdrawalWireRecipientSearchPageTitle')}
      onClose={onClose}
      onBack={onBack}
      isAbove
      footerButtons={
        <Stack gap="16px">
          <Button type="submit" disabled={!recipientId} onClick={() => onContinue(recipientId)}>
            <Typography variant="buttonLarge">{t('elements.common.continue')}</Typography>
            <ArrowForwardIcon />
          </Button>
        </Stack>
      }
    >
      <Stack gap="16px" mb="24px">
        <Typography variant="overlineDefaultMedium" color="secondary.font">
          {t('elements.transfer.commonRecipientSection.subtitle')}
        </Typography>
        <Button onClick={onAddNewRecipient}>
          <AddIcon />
          <Typography variant="buttonLarge">
            {t('elements.transfer.withdrawalWireRecipientSearchPage.addRecipientBtnTitle')}
          </Typography>
        </Button>
        <Typography variant="overlineDefaultMedium" color="secondary.font" textAlign="center">
          {t('elements.transfer.commonRecipientSection.or')}
        </Typography>
        <Stack direction="row" gap="18px" paddingBottom="24px">
          <BaseInput
            name="searchExternalAccount"
            placeholder={t('elements.transfer.withdrawalWireRecipientSearchPage.searchInputPlaceholder')}
            value={searchValue}
            onChange={onSearchInputChange}
          />
          <StyledButton disabled={searchValue.trim().length < 3} onClick={onSearchClick}>
            <Typography variant="buttonLarge">
              {t('elements.transfer.commonRecipientSection.searchBtnTitle')}
            </Typography>
          </StyledButton>
        </Stack>
        {search && !isRecipientsLoading && (
          <Typography variant="overlineDefaultMedium" color="secondary.font">
            {t('elements.transfer.commonRecipientSection.results')}
          </Typography>
        )}

        {search && !isRecipientsLoading && !recipients.length && (
          <Stack alignItems="center">
            <NoResultsIcon />
            <Typography variant="h6" color="grey.500">
              {t('elements.transfer.commonRecipientSection.noResultsFound')}
            </Typography>
            <Typography variant="bodyDefaultBook" color="secondary.font">
              {t('elements.transfer.commonRecipientSection.pleaseTryAgain')}
            </Typography>
          </Stack>
        )}

        {search && !!recipients.length && (
          <>
            <BaseRadioGroup
              labelledby="recipients-radio-group"
              name="recipients"
              value={recipientId}
              onChange={onRecipientChange}
              values={formattedRadioValues}
              radioLabelNode={RadioLabel}
              onDelete={onDeleteClick}
              withHover
            />
            <div id="intersection-observer-trigger" ref={lastItemRef} />
            <DestroyActionModal
              onClose={onCloseModal}
              title={t('elements.transfer.commonRecipientSection.deleteConfirmModal.title')}
              captionText={t('elements.transfer.withdrawalWireRecipientSearchPage.deleteConfirmModal.subtitle')}
              mainText={deleteRecipientName}
              isOpen={isDeleteModalOpen}
              destroyButtonTitle={t('elements.transfer.commonRecipientSection.deleteConfirmModal.confirmButtonLabel')}
              onDestroyButtonClick={onConfirmDeleteClick}
              destroyButtonLoading={isDeleteLoading}
            />
          </>
        )}

        {isRecipientsLoading && (
          <StyledLoaderContainer>
            <CircularProgress />
          </StyledLoaderContainer>
        )}
      </Stack>
    </FlowFirstPageLayout>
  );
};

export default WireRecipientSearchPage;
