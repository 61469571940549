import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as RejectIcon } from 'assets/icons/white-close-icon.svg';
import { CommonActionModal } from '../CommonActionModal';
import { StyledButton } from '../StyledButton/styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  captionText: string;
  mainText: string;
  destroyButtonTitle: string;
  destroyButtonLoading: boolean;
  onDestroyButtonClick: () => void;
};

export const DestroyActionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  captionText,
  mainText,
  destroyButtonTitle,
  destroyButtonLoading,
  onDestroyButtonClick,
}) => {
  return (
    <CommonActionModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      captionText={captionText}
      mainText={mainText}
      actionButton={
        <StyledButton buttonType="error" size="large" onClick={onDestroyButtonClick} isLoading={destroyButtonLoading}>
          <Stack gap="8px" direction="row">
            <RejectIcon />
            <Typography variant="buttonMedium" color="background.paper">
              {destroyButtonTitle}
            </Typography>
          </Stack>
        </StyledButton>
      }
    />
  );
};
