import {
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Button,
} from '@mui/material';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { pxToRem } from 'utils/styling-utils';

const DIALOG_MARGIN = 32;
const MAX_DIALOG_HEIGHT = 704;
const MAX_DIALOG_OUTER_HEIGHT = MAX_DIALOG_HEIGHT + 2 * DIALOG_MARGIN;
export const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    width: pxToRem(460),
    margin: pxToRem(DIALOG_MARGIN),
    maxHeight: `calc(100% - ${2 * DIALOG_MARGIN}px)`,
  },
  [`@media screen and (min-height: ${MAX_DIALOG_OUTER_HEIGHT}px)`]: {
    [`& .${dialogClasses.paper}`]: {
      maxHeight: pxToRem(MAX_DIALOG_HEIGHT),
    },
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: `${pxToRem(theme.gaps._40px)} ${pxToRem(theme.gaps._40px)} 0`,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: `${pxToRem(theme.gaps._40px)}`,
  paddingRight: `${pxToRem(theme.gaps._40px)}`,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: `${pxToRem(theme.gaps._24px + theme.gaps._10px)}`,
  paddingBottom: `${pxToRem(theme.gaps._56px / 2)}`,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: pxToRem(theme.gaps._20px),
  right: pxToRem(theme.gaps._20px),
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
}));

export const StyledLoadingButtonWithSkeleton = styled(LoadingButtonWithSkeleton)(() => ({
  width: pxToRem(135),
}));

export const StyledButton = styled(Button)(() => ({
  width: pxToRem(135),
}));
