import { useDispatch } from 'react-redux';
import { useState } from 'react';
import i18next from 'i18next';
import axios, { ResponseType } from 'axios';
import dayjs from 'dayjs';
import { MessageType, setBannerData } from 'redux/banner';
import { API_BASE_URL, API_ROUTES } from 'redux/api/api-routes';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { Auth0ClientSingleton } from 'auth0/auth0-client-singleton';
import { FetchPDFStatement, UsePdfStatementExportReturnValue } from './interfaces';

export const usePdfStatementExport = (): UsePdfStatementExportReturnValue => {
  const dispatch = useDispatch();
  const [error, setError] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const downloadPdfStatement = async ({ custodialAccountId, requestParams, preFilterParams }: FetchPDFStatement) => {
    const { dateTo, dateFrom } = requestParams;
    try {
      const auth0 = await Auth0ClientSingleton.getInstance();
      const token = await auth0.getTokenSilently();

      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
          accept: 'application/pdf',
        },
        responseType: 'blob' as ResponseType,
      };

      setIsLoading(true);

      if (!navigator.onLine) {
        dispatch(setBannerData({ type: MessageType.error, message: i18next.t('offlineNetworkMessage') }));
        setIsLoading(false);
        setError(new Error());
        return;
      }

      let url: string;
      if (preFilterParams) {
        const params: Record<string, string> = { ...preFilterParams };

        if (dateFrom) {
          params.dateFrom = dateFrom;
        }

        if (dateTo) {
          params.dateTo = dateTo;
        }

        url = `${API_BASE_URL}${getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_STATEMENT, {
          custodialAccountId,
        })}?${new URLSearchParams(params)}`;
      } else {
        const params: Record<string, string> = {};

        if (dateFrom) {
          params.dateFrom = dateFrom;
        }

        if (dateTo) {
          params.dateTo = dateTo;
        }

        if (Object.keys(params).length > 0) {
          url = `${API_BASE_URL}${getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_STATEMENT, {
            custodialAccountId,
          })}?${new URLSearchParams(params)}`;
        } else {
          url = `${API_BASE_URL}${getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_STATEMENT, {
            custodialAccountId,
          })}`;
        }
      }
      const res = await axios.get(url, {
        ...header,
      });

      const contentType = res.headers['Content-Disposition'];
      const fileName = contentType?.split('; ')?.[1]?.split('filename=')?.[1];
      const blob = await res.data;
      // Create blob link to download
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName ?? `CA_Statements_${dayjs().format('MM-DD-YYYY')}.pdf`);
      // Append to html page
      document.body.appendChild(link);
      // Force download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
      if (!navigator.onLine) {
        dispatch(setBannerData({ type: MessageType.error, message: i18next.t('offlineNetworkMessage') }));
      } else if (err.response.status === 400) {
        dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverError.badRequest') }));
      } else {
        dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverErrorBanner') }));
      }
    }
  };

  return { isLoading, error, downloadPdfStatement };
};
