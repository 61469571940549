import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove-x-red.svg';
import { StyledLoadingButtonWithSkeleton } from './styled';

type Props = {
  isLoading: boolean;
  onClick: () => void;
};

export const RemoveButton: React.FC<Props> = ({ isLoading, onClick }) => {
  const { t } = useTranslation();

  return (
    <StyledLoadingButtonWithSkeleton
      variant="outlined"
      color="error"
      size="large"
      icon={<RemoveIcon />}
      text={
        <Typography color="error" variant="buttonMedium">
          {t('userDetailsPage.buttons.remove')}
        </Typography>
      }
      loading={isLoading}
      onClick={onClick}
    />
  );
};
