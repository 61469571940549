import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Backdrop, Typography, useTheme } from '@mui/material';
import useForm from 'elements/hooks/useForm';
import { Button, NoteField, NumberInput, Spacer } from 'elements/components';
import { CustodialAccount } from 'elements/types';
import Layout from 'elements/element-transfer/components/Layout';
import { useYupRules } from 'elements/element-transfer/hooks/useYupRules';
import { ElementsCustomTheme } from 'elements/theme/createCustomTheme';
import { ErrorState } from 'elements/features/LoadingErrorState';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { OnCloseElement } from 'elements/models/types/element-result';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { getUniqueIdentifierForCustodialAccountBalanceModel } from 'utils/account-balances/get-unique-identifier-for-custodial-account-balance-model';
import { TrustAssetType } from 'ui-enums/response/custodial-accounts/trust-asset-type';
import { CoinsDropdown, renderSelectedValue } from 'elements/element-transfer/components/CoinsDropdown';
import InternalTransferSource from 'elements/element-transfer/components/Withdrawal/InternalTransferSource';

type Props = {
  amount: string;
  comment: string;
  custodialAccount: CustodialAccount | null;
  custodialAccountName: string;
  selectedCoin: CustodialAccountBalanceModel | null;
  coinList: CustodialAccountBalanceModel[];
  getCoinListError?: boolean;
  lockAmountError: boolean;
  unlockAmountError: boolean;
  lockingAmount: boolean;
  onReview: (formValues: { amount: string; cabId: string; comment: string }) => void;
  onReload: () => void;
  onBack: () => void;
  onClose: OnCloseElement;
  clearLockError?: () => void;
};

const InternalTransferAmountPage: React.FC<Props> = ({
  amount,
  comment,
  custodialAccount,
  custodialAccountName,
  selectedCoin,
  coinList,
  getCoinListError,
  unlockAmountError,
  lockAmountError,
  lockingAmount,
  onReview,
  onReload,
  onBack,
  onClose,
  clearLockError,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const theme = useTheme<ElementsCustomTheme>();
  const [currentCoin, setCurrentCoin] = useState<CustodialAccountBalanceModel | null>(selectedCoin);
  const isFiat = currentCoin?.assetType.toLowerCase() === TrustAssetType.fiatCurrency.toLowerCase();
  const { cryptoWithdrawalAmountRule, requiredStringRule, requiredNumberRule, internalTransferCommentRule } =
    useYupRules();

  const {
    formik: { setFieldValue, setValues, submitForm, values, validateForm },
    helpers: { getFieldProps, getFieldErrorProps, isFormDisabled },
  } = useForm({
    initialValues: {
      amount,
      coinBalance: selectedCoin?.disbursable ?? '',
      cabId: selectedCoin ? getUniqueIdentifierForCustodialAccountBalanceModel(selectedCoin) : '',
      comment,
    },
    yupShape: {
      amount: cryptoWithdrawalAmountRule(lockAmountError),
      coinBalance: requiredNumberRule,
      cabId: requiredStringRule,
      comment: internalTransferCommentRule,
    },
    onSubmit: (formValues, { setSubmitting }) => {
      setSubmitting(false);
      onReview(formValues);
    },
  });

  const onAmountChange = (event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } }) => {
    clearLockError?.();
    setFieldValue('amount', event.target.value);
  };

  const onCommentChange = (value: string) => {
    setFieldValue('comment', value);
  };

  const onCoinDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value as string;

    const chosenCoin = coinList.find(cab => getUniqueIdentifierForCustodialAccountBalanceModel(cab) === id)!;

    setValues(val => ({
      ...val,
      cabId: id,
      coinBalance: chosenCoin.disbursable,
    }));
    setCurrentCoin(chosenCoin);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockAmountError]);

  return (
    <Layout
      title={t('elements.transfer.customizableLabels.internalTransferAmountPageTitle')}
      isContentCentered={getCoinListError || unlockAmountError}
      isAbove
      onBack={onBack}
      onClose={onClose}
      footerButtons={
        !getCoinListError &&
        !unlockAmountError && (
          <Button
            type="submit"
            isLoading={lockingAmount}
            disabled={isFormDisabled || !Number(values.amount)}
            onClick={submitForm}
          >
            <Typography variant="buttonLarge">
              {t('elements.transfer.internalTransferAmountPage.reviewButton')}
            </Typography>
            <ArrowForwardIcon />
          </Button>
        )
      }
    >
      {getCoinListError || unlockAmountError ? (
        <ErrorState onReload={onReload} />
      ) : (
        <>
          <Backdrop
            open={lockingAmount}
            sx={{ backgroundColor: theme.palette.background.whiteTransparent50, zIndex: 5, position: 'absolute' }}
          />
          <CoinsDropdown
            {...getFieldProps('cabId')}
            {...getFieldErrorProps('cabId')}
            onChange={onCoinDropdownChange}
            fullWidth
            coinsList={coinList}
            withSearch={coinList.length > (isMobile ? 5 : 8)}
            renderValueHandler={renderSelectedValue(coinList)}
          />
          <Spacer size={16} />
          {isFiat ? (
            <NumberInput
              prefix="$"
              decimalScale={2}
              {...getFieldProps('amount')}
              {...getFieldErrorProps('amount')}
              onChange={onAmountChange}
              placeholder="$0.00"
              label={t('elements.transfer.internalTransferAmountPage.amount')}
            />
          ) : (
            <NumberInput
              decimalScale={8}
              {...getFieldProps('amount')}
              {...getFieldErrorProps('amount')}
              onChange={onAmountChange}
              placeholder="0.0"
              label={t('elements.transfer.internalTransferAmountPage.amount')}
            />
          )}
          <Spacer size={16} />
          <NoteField
            {...getFieldProps('comment')}
            {...getFieldErrorProps('comment')}
            onChange={onCommentChange}
            multiline
            label={t('elements.transfer.internalTransferAmountPage.commentLabel')}
            addButtonName={t('elements.transfer.internalTransferAmountPage.addCommentButton')}
            helperText={
              getFieldErrorProps('comment').error
                ? getFieldErrorProps('comment').helperText
                : t('elements.transfer.internalTransferAmountPage.commentHelperText')
            }
          />
          <Spacer size={32} />
          {!!custodialAccount && !!currentCoin && (
            <>
              <InternalTransferSource
                custodialAccount={custodialAccount}
                custodialAccountName={custodialAccountName}
                withBalance
                coin={currentCoin}
              />
              <Spacer size={32} />
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default InternalTransferAmountPage;
