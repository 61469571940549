import React, { useEffect } from 'react';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';
import ContentBlock from 'elements/element-transfer/components/TransferPage';
import {
  resetWithdrawalWireState,
  setDepositAchAmount,
  setFlow,
  setPage,
  setWithdrawalWireAmount,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import { OnCloseElement } from 'elements/models/types/element-result';

const TransferPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { dispatch } = useElement();

  const {
    state: { paymentMethodTypesMap, transferDirection, flowType },
  } = useElement();

  const onDepositFromBank = () => {
    dispatch(setPage(Paths.DepositAchAmount));
  };

  const onWireInstructions = () => {
    if (flowType === TransferFlow.Deposit) {
      dispatch(setPage(Paths.DepositWire));
    } else {
      dispatch(resetWithdrawalWireState());
      dispatch(setPage(Paths.WithdrawalWireRecipientSearch));
    }
  };

  const onSendCrypto = () => {
    dispatch(setPage(Paths.WithdrawalCryptoAmount));
  };

  const onReceiveCrypto = () => {
    dispatch(setPage(Paths.DepositCryptoAddresses));
  };

  const onInternalTransfer = () => {
    dispatch(setPage(Paths.InternalTransferRecipientSearch));
  };

  const onTabChange = (value: TransferFlow) => {
    dispatch(setFlow(value));
  };

  useEffect(
    () => () => {
      dispatch(setDepositAchAmount(''));
      dispatch(setWithdrawalWireAmount(''));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ContentBlock
      transferDirection={transferDirection}
      flowType={flowType}
      onClose={onClose}
      onTabChange={onTabChange}
      onDepositFromBank={onDepositFromBank}
      onWireInstructions={onWireInstructions}
      onSendCrypto={onSendCrypto}
      onReceiveCrypto={onReceiveCrypto}
      onInternalTransfer={onInternalTransfer}
      paymentMethodTypesMap={paymentMethodTypesMap}
    />
  );
};

export default TransferPage;
