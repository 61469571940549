import React from 'react';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/InternalTransferRecipientSearchPage';
import useInternalTransferRecipientSearchPage from 'elements/element-transfer/hooks/useInternalTransferRecipientSearchPage';
import { OnCloseElement } from 'elements/models/types/element-result';

const InternalTransferRecipientSearchPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { search, recipientList, isRecipientsLoading, recipientId, lastItemRef, onBack, onSearch, onContinue } =
    useInternalTransferRecipientSearchPage();

  return (
    <ContentBlock
      search={search}
      recipients={recipientList}
      isRecipientsLoading={isRecipientsLoading}
      recipientId={recipientId}
      lastItemRef={lastItemRef}
      onSearch={onSearch}
      onClose={onClose}
      onBack={onBack}
      onContinue={onContinue}
    />
  );
};

export default InternalTransferRecipientSearchPage;
