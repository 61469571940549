import React from 'react';
import { useTranslation } from 'react-i18next';
import SwapCallsOutlinedIcon from '@mui/icons-material/SwapCallsOutlined';
import { TransferDirectionEnum, TransferFlow } from 'elements/element-transfer/types';
import { Divider, Tabs } from 'elements/components';
import { PaymentMethodTypesMap } from 'elements/features/PaymentMethods/utils/getPaymentMethodTypesMap';
import { ReactComponent as CryptoIcon } from 'elements/element-transfer/assets/icons/crypto.svg';
import { ReactComponent as DollarIcon } from 'elements/element-transfer/assets/icons/dollar.svg';
import { ReactComponent as InternalTransferIcon } from 'elements/element-transfer/assets/icons/internalTransfer.svg';
import Layout from 'elements/element-transfer/components/Layout';
import transferPageTitleMapping from 'elements/element-transfer/utils/transferPageTitleMapping';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { OnCloseElement } from 'elements/models/types/element-result';
import TransferOption from './TransferOption';
import { StyledContainer } from './styled';

type Props = {
  transferDirection: TransferDirectionEnum;
  flowType: TransferFlow;
  paymentMethodTypesMap: PaymentMethodTypesMap;
  onClose: OnCloseElement;
  onTabChange: (value: TransferFlow) => void;
  onDepositFromBank: () => void;
  onWireInstructions: () => void;
  onSendCrypto: () => void;
  onReceiveCrypto: () => void;
  onInternalTransfer: () => void;
};

const TransferPage: React.FC<Props> = ({
  transferDirection,
  flowType,
  onClose,
  onTabChange,
  onDepositFromBank,
  onWireInstructions,
  onSendCrypto,
  onReceiveCrypto,
  onInternalTransfer,
  paymentMethodTypesMap,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <Layout
      title={transferPageTitleMapping[transferDirection]}
      onClose={onClose}
      withHeader
      subHeader={
        transferDirection === TransferDirectionEnum.DepositWithdrawal && (
          <Tabs
            items={[
              { value: TransferFlow.Deposit, title: t('elements.transfer.customizableLabels.depositTitle') },
              { value: TransferFlow.Withdrawal, title: t('elements.transfer.customizableLabels.withdrawalTitle') },
            ]}
            value={flowType}
            onTabChange={onTabChange}
          />
        )
      }
    >
      <StyledContainer isMobile={isMobile}>
        {flowType === TransferFlow.Deposit && paymentMethodTypesMap.ach && (
          <>
            <TransferOption
              startIcon={<DollarIcon />}
              title={t('elements.transfer.customizableLabels.depositAchButton')}
              onClick={onDepositFromBank}
            />
            <Divider />
          </>
        )}
        {paymentMethodTypesMap.wire && (
          <>
            <TransferOption
              startIcon={<SwapCallsOutlinedIcon />}
              title={
                flowType === TransferFlow.Deposit
                  ? t('elements.transfer.customizableLabels.depositWireButton')
                  : t('elements.transfer.customizableLabels.withdrawalWireButton')
              }
              onClick={onWireInstructions}
            />
            <Divider />
          </>
        )}
        {paymentMethodTypesMap.crypto && (
          <>
            <TransferOption
              startIcon={<CryptoIcon />}
              title={
                flowType === TransferFlow.Deposit
                  ? t('elements.transfer.customizableLabels.depositCryptoButton')
                  : t('elements.transfer.customizableLabels.withdrawalCryptoButton')
              }
              onClick={flowType === TransferFlow.Deposit ? onReceiveCrypto : onSendCrypto}
            />
            <Divider />
          </>
        )}
        {flowType === TransferFlow.Withdrawal && (
          <>
            <TransferOption
              startIcon={<InternalTransferIcon />}
              title={t('elements.transfer.customizableLabels.internalTransferButton')}
              onClick={onInternalTransfer}
            />
            <Divider />
          </>
        )}
      </StyledContainer>
    </Layout>
  );
};

export default TransferPage;
