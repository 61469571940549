import React from 'react';
import { useIsMobile } from 'elements/hooks/useIsMobile';
import { Stack } from '../Stack';
import { StyledTooltip, StyledTypography } from './styled';

type Props = {
  children: React.ReactElement;
  title?: string;
  texts: Array<string>;
};

export const Tooltip: React.FC<Props> = ({ children, title, texts }) => {
  const { isMobile } = useIsMobile();
  return (
    <StyledTooltip
      isMobile={isMobile}
      title={
        <>
          {title && <StyledTypography variant="bodySmallBold">{title}</StyledTypography>}
          <Stack gap="18px">
            {texts.map((text, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledTypography key={i} variant="bodySmallBook">
                {text}
              </StyledTypography>
            ))}
          </Stack>
        </>
      }
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      placement="top"
    >
      {children}
    </StyledTooltip>
  );
};
