import React from 'react';
import { TransferFlow } from 'elements/element-transfer/types';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/InternalTransferPlacedPage';
import { useElement } from 'elements/element-transfer/contexts/Element';
import { OnCloseElement } from 'elements/models/types/element-result';
import { sendPaymentIdMessage } from 'elements/element-transfer/utils/sendPaymentIdMessage';

const InternalTransferPlacedPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { state } = useElement();
  const internalTransferState = state[TransferFlow.Withdrawal].INTERNAL;
  const { amount, recipientInfo, comment, selectedCoin } = internalTransferState;

  const onDone = () => {
    onClose(
      sendPaymentIdMessage({
        internalTransferPaymentId: state.internalTransferPaymentId,
      }),
    );
    // @TODO remove when element will use RTK query with common re-fetch approach
    window.location.reload();
  };

  return (
    <ContentBlock
      amount={amount}
      selectedCoin={selectedCoin}
      recipientName={recipientInfo.name}
      recipientAccountNumber={recipientInfo.accountNumber}
      custodialAccount={state.custodialAccount}
      custodialAccountName={state.custodialAccountName}
      comment={comment}
      onDone={onDone}
    />
  );
};

export default InternalTransferPlacedPage;
