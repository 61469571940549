import { FIRST_PAGE, PAGE_SIZE_10 } from 'elements/constants';
import { ExternalAccountTypeEnum } from 'elements/types';

const WIRE_EXTERNAL_ACCOUNT_TYPE = `${ExternalAccountTypeEnum.WireExternalAccount},${ExternalAccountTypeEnum.InternationalWireExternalAccount}`;

export const formExternalAccountsSearchParams = (
  search: string,
  identityId: string,
  page = FIRST_PAGE,
  pageSize = PAGE_SIZE_10,
  type = WIRE_EXTERNAL_ACCOUNT_TYPE,
) => {
  return {
    'filter[searchInfo cont]': search,
    'filter[type in]': type,
    page,
    pageSize,
    identityId,
  };
};
