import { CustodialAccount, ExternalAccount, ExternalAccountTypeEnum, IdentityData } from 'elements/types';
import { axiosInstance } from 'elements/api';
import { getRouteWithParams } from 'elements/utils';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { TradingPriceResponseModel } from 'models/response/trades/trading-price-response-model';
import { TryLockInitRequestModel } from 'models/request/current/try-lock-init-request-model';
import { TrustAssetType } from 'ui-enums/response/custodial-accounts/trust-asset-type';
import { BuyCoinsRequestModel } from 'models/request/trades/buy-coins-request-model';
import { BuyCoinsResponseModel } from 'models/response/trades/buy-coins-response-model';
import { SellCoinsRequestModel } from 'models/request/trades/sell-coins-request-model';
import { SellCoinsResponseModel } from 'models/response/trades/sell-coins-response-model';
import { AssetType } from 'ui-enums/assets/asset-type';

enum TradeElementEndpoints {
  GetBuyCoinsData = '/assets?operation=buy',

  GetSellCoinsData = '/custodial-accounts/:custodialAccountId/balances?page=1&pageSize=100&operation=sell',
  GetIdentityData = '/current/:custodialAccountId/identity',
  GetCustodialAccount = '/current/:custodialAccountId/custodial-account',
  GetExternalAccounts = '/external-accounts',

  GetCoinPrice = '/trades/:network/:asset/crypto-price',
  ProcessBuyOrder = '/trades/buy',
  ProcessSellOrder = '/trades/sell',

  GetAddBankAccountJwt = '/custodial-accounts/:custodialAccountId/jwt/addBankAccount',
  GetKYCJwt = '/custodial-accounts/:custodialAccountId/jwt/kyc',

  // TODO update
  LockPaymentAmount = '/current/current/try-lock',
  UnlockPaymentAmount = '/current/current/cancel-lock/:lockId',
}

export const tradeElement = {
  async getBuyCoinsData(): Promise<Array<AssetResponseModel>> {
    return axiosInstance.get<AssetResponseModel[]>(TradeElementEndpoints.GetBuyCoinsData).then(res => {
      return res.data.filter(a => a.assetType === AssetType.crypto);
    });
  },

  async getSellCoinsData(custodialAccountId: string): Promise<Array<CustodialAccountBalanceModel>> {
    return axiosInstance
      .get<CustodialAccountBalanceModel[]>(
        getRouteWithParams(TradeElementEndpoints.GetSellCoinsData, { custodialAccountId }),
      )
      .then(res => {
        return res.data.filter(cab => cab.assetType.toLowerCase() === TrustAssetType.cryptoCurrency.toLowerCase());
      });
  },

  async getEstimatedCoinPrice(network: string, asset: string): Promise<TradingPriceResponseModel> {
    return axiosInstance.get(getRouteWithParams(TradeElementEndpoints.GetCoinPrice, { network, asset }));
  },

  async getCustodialAccounts(custodialAccountId: string): Promise<{ data: CustodialAccount[] }> {
    const custodialAccount = await axiosInstance.get<never, CustodialAccount>(
      getRouteWithParams(TradeElementEndpoints.GetCustodialAccount, { custodialAccountId }),
    );
    // left array here to use multiple custodial accounts logic later
    return { data: [custodialAccount] };
  },
  getExternalAccounts(identityId: string): Promise<{ data: ExternalAccount[] }> {
    return axiosInstance.get<never, { data: ExternalAccount[] }>(TradeElementEndpoints.GetExternalAccounts, {
      params: {
        identityId,
        'filter[type nin]': `${ExternalAccountTypeEnum.WireExternalAccount},${ExternalAccountTypeEnum.CardExternalAccount}`,
      },
    });
  },

  processBuyOrder(body: BuyCoinsRequestModel): Promise<BuyCoinsResponseModel> {
    return axiosInstance.post(TradeElementEndpoints.ProcessBuyOrder, body);
  },

  processSellOrder(body: SellCoinsRequestModel): Promise<SellCoinsResponseModel> {
    return axiosInstance.post(TradeElementEndpoints.ProcessSellOrder, body);
  },

  getAddBankAccountJwt(custodialAccountId: string): Promise<{ jwt: string }> {
    return axiosInstance.get(getRouteWithParams(TradeElementEndpoints.GetAddBankAccountJwt, { custodialAccountId }));
  },

  getIdentityData(custodialAccountId: string): Promise<IdentityData> {
    return axiosInstance.get(getRouteWithParams(TradeElementEndpoints.GetIdentityData, { custodialAccountId }));
  },

  getKYCJwt(custodialAccountId: string): Promise<{ jwt: string }> {
    return axiosInstance.get(getRouteWithParams(TradeElementEndpoints.GetKYCJwt, { custodialAccountId }));
  },

  lockPaymentAmount(lockParams: TryLockInitRequestModel): Promise<string> {
    return axiosInstance.post(TradeElementEndpoints.LockPaymentAmount, lockParams);
  },

  unlockPaymentAmount(lockId: string): Promise<void> {
    return axiosInstance.post(getRouteWithParams(TradeElementEndpoints.UnlockPaymentAmount, { lockId }));
  },
};
