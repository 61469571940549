import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/InternalTransferAmountPage';
import {
  setPage,
  useElement,
  setLockId,
  setPaymentType,
  setInternalTransferCoinList,
  setInternalTransferSelectedCoin,
  setInternalTransferCoinAmount,
  setInternalTransferComment,
  setCustodialAccountName,
} from 'elements/element-transfer/contexts/Element';
import { OnCloseElement } from 'elements/models/types/element-result';
import * as api from 'elements/element-transfer/api';
import { useLockPaymentAmount } from 'elements/hooks/useLockPaymentAmount';
import { PaymentType } from 'ui-enums/response/transactions/payment-type';
import { setErrorBanner } from 'elements/utils';
import { OperationTypeModel } from 'models/enums/assets/operation-type-model';
import { getUniqueIdentifierForCustodialAccountBalanceModel } from 'utils/account-balances/get-unique-identifier-for-custodial-account-balance-model';
import { LoadingPage } from 'elements/features/LoadingErrorState';
import { TrustAssetType } from 'ui-enums/response/custodial-accounts/trust-asset-type';
import { getAccountData } from '../../utils/getAccountData';

const InternalTransferAmountPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();

  const { coinList, selectedCoin, amount, comment } = state[TransferFlow.Withdrawal].INTERNAL;

  const [getCoinListError, setGetCoinListError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { lock, unlock, locking, unlocking, lockError, unlockError, clearLockError } = useLockPaymentAmount({
    lockId: state.lockId,
    setLockId: (id: string) => dispatch(setLockId(id)),
    lockRequest: api.transferElement.lockPaymentAmount,
    unlockRequest: api.transferElement.unlockPaymentAmount,
  });

  const requestCoinList = async () => {
    try {
      setIsLoading(true);
      setGetCoinListError(false);

      const coins = (await api.transferElement.getCoinsBalance(state.custodialAccountId, OperationTypeModel.internal))
        .data;
      dispatch(setInternalTransferCoinList(coins));
      if (coins.length) {
        dispatch(setInternalTransferSelectedCoin(coins[0]));
      }

      if (state.custodialAccount) {
        const custodialAccount = await api.transferElement.getCustodialAccountDetails(state.custodialAccountId);
        if (custodialAccount) {
          dispatch(
            setCustodialAccountName(
              custodialAccount.name ||
                custodialAccount.ownerIdentityDisplayName ||
                getAccountData(state.custodialAccount.type).title ||
                '',
            ),
          );
        }
      }

      setIsLoading(false);
    } catch (e) {
      const isBannerShown = setErrorBanner(e, enqueueSnackbar);
      if (!isBannerShown) {
        setGetCoinListError(true);
      }
      setIsLoading(false);
    }
  };

  const onReview = async (formValues: { amount: string; cabId: string; comment: string }) => {
    const chosenCab = coinList.find(
      cab => getUniqueIdentifierForCustodialAccountBalanceModel(cab) === formValues.cabId,
    );

    if (!chosenCab) {
      return;
    }

    const paymentType =
      chosenCab?.assetType.toLowerCase() === TrustAssetType.cryptoCurrency.toLowerCase()
        ? PaymentType.internalCrypto
        : PaymentType.internal;

    dispatch(setPaymentType(paymentType));

    if (paymentType) {
      const isLocked = await lock({
        paymentType,
        cryptoAmount:
          chosenCab?.assetType.toLowerCase() === TrustAssetType.cryptoCurrency.toLowerCase()
            ? +formValues.amount
            : undefined,
        usdAmount:
          chosenCab?.assetType.toLowerCase() === TrustAssetType.fiatCurrency.toLowerCase()
            ? +formValues.amount
            : undefined,
        network: chosenCab.network,
        assetType: chosenCab.assetTicker,
      });

      if (!isLocked) {
        return;
      }
    }
    dispatch(setInternalTransferCoinAmount(formValues.amount));
    dispatch(setInternalTransferComment(formValues.comment));
    dispatch(setInternalTransferSelectedCoin(chosenCab));

    dispatch(setPage(Paths.InternalTransferReview));
  };

  const onReload = async () => {
    if (getCoinListError) {
      await requestCoinList();
    }

    if (unlockError) {
      await unlock();
    }
  };

  const onBack = () => {
    dispatch(setPage(Paths.InternalTransferRecipientSearch));
  };

  useEffect(() => {
    if (!coinList.length || !state.custodialAccountName) {
      // eslint-disable-next-line no-return-await
      (async () => await requestCoinList())();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading || unlocking ? (
    <LoadingPage onClose={onClose} />
  ) : (
    <ContentBlock
      amount={amount}
      comment={comment}
      custodialAccount={state.custodialAccount}
      custodialAccountName={state.custodialAccountName}
      selectedCoin={selectedCoin}
      coinList={coinList}
      getCoinListError={getCoinListError}
      lockAmountError={lockError}
      unlockAmountError={unlockError}
      lockingAmount={locking}
      onReview={onReview}
      onReload={onReload}
      onBack={onBack}
      onClose={onClose}
      {...(lockError ? { clearLockError } : {})}
    />
  );
};

export default InternalTransferAmountPage;
