import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as api from 'elements/element-trade/api';
import { MIN_DOLLAR_AMOUNT } from 'elements/element-trade/constants';
import { isExternalAccount, setErrorBanner } from 'elements/utils';
import { TradeFlow } from 'elements/element-trade/types';
import {
  setAmount,
  setBuySellSelectedCoin,
  setCoinPrice,
  setIsCoinPriceError,
  setShouldPriceReload,
  useElement,
  setCustodialAccountList,
  setExternalAccountList,
  setPaymentAccount,
} from 'elements/element-trade/contexts/Element';
import isCoinDisabled from 'elements/element-trade/utils/isCoinDisabled';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { AssetResponseModel } from 'models/response/assets/asset-response-model';
import { getUniqueIdentifierForAssetResponseModel } from 'utils/assets/get-unique-identifier-for-asset-response-model';
import { selectDefaultAccount } from 'elements/features/PaymentMethods';
import { filterPositiveCustodialAccounts } from 'elements/element-trade/utils/filterPositiveCustodialAccounts';
import { CustodialAccount } from 'elements/types';

export const useOrderPreparation = ({ clearLockError }: { clearLockError?: () => void }) => {
  const { state, dispatch } = useElement();
  const { enqueueSnackbar } = useSnackbar();
  const { coinsBuySellList, selectedBuySellCoin, coinPrice, amount } = state[state.flowType];
  const { shouldPriceReload, coinPriceError, paymentAccount, custodialAccountId, paymentMethodTypesMap, identityId } =
    state;

  const [amountError, setAmountError] = useState(false);
  const [minAmountError, setMinAmountError] = useState(false);
  const [coinPriceLoading, setCoinPriceLoading] = useState(false);

  const getIsAmountInvalid = (value: string): boolean => {
    if (state.flowType === TradeFlow.Sell) {
      const disbursable = (selectedBuySellCoin as CustodialAccountBalanceModel)?.disbursable;

      const balance = disbursable ? Number(disbursable) : 0;

      return Number(value) > balance;
    }
    const isInvalidPaymentAccount = !paymentAccount || isExternalAccount(paymentAccount);
    const isNotAcceptablePaymentMethod =
      !isInvalidPaymentAccount && (paymentAccount as CustodialAccount).balance < parseFloat(amount);
    const isLessThanMin = Number(value) < MIN_DOLLAR_AMOUNT;

    setMinAmountError(isLessThanMin);
    return isInvalidPaymentAccount || isLessThanMin || isNotAcceptablePaymentMethod;
  };

  const onCoinDropdownChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = event.target.value as string;

      const sellMode = state.flowType === TradeFlow.Sell;

      let chosenCoin: CustodialAccountBalanceModel | AssetResponseModel;

      if (sellMode) {
        chosenCoin = (coinsBuySellList as CustodialAccountBalanceModel[]).find(cab => cab.id === id)!;

        const c = chosenCoin as CustodialAccountBalanceModel;
        setAmountError(Number(amount) > Number(c.disbursable));
      } else {
        chosenCoin = (coinsBuySellList as AssetResponseModel[]).find(
          a => getUniqueIdentifierForAssetResponseModel(a) === id,
        )!;
      }

      dispatch(setBuySellSelectedCoin(chosenCoin));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.flowType, coinsBuySellList, amount],
  );

  const onAmountInputBlur = () => {
    let value = amount;
    if (state.flowType === TradeFlow.Sell) {
      value = amount.replace(/(?:(\.\d*?[1-9]+)|\.)0*$/g, '$1');
      dispatch(setAmount(value));
    }
    if (value.length > 0) {
      setAmountError(getIsAmountInvalid(value));
    }
  };

  const onAmountInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
  ) => {
    dispatch(setAmount(event.target.value));
    clearLockError?.();
  };

  const onAmountInputFocus = () => {
    setAmountError(false);
  };

  const requestAccounts = async () => {
    try {
      const [custodialAccountList, externalAccountList] = await Promise.all([
        api.tradeElement.getCustodialAccounts(custodialAccountId),
        api.tradeElement.getExternalAccounts(identityId),
      ]);

      dispatch(setCustodialAccountList(custodialAccountList.data));
      dispatch(setExternalAccountList(externalAccountList.data));

      const defaultAccount = selectDefaultAccount(
        filterPositiveCustodialAccounts(custodialAccountList.data),
        externalAccountList.data,
        paymentMethodTypesMap,
      );

      if (defaultAccount) {
        dispatch(setPaymentAccount(defaultAccount));
      }
    } catch (e) {
      setErrorBanner(e, enqueueSnackbar);
    }
  };

  const requestEstimatedCoinPrice = async (currentCoin: AssetResponseModel | CustodialAccountBalanceModel | null) => {
    if (!currentCoin) {
      return;
    }

    if (isCoinDisabled(currentCoin.assetTicker)) {
      dispatch(setIsCoinPriceError(false));
      return;
    }
    try {
      setCoinPriceLoading(true);
      dispatch(setIsCoinPriceError(false));

      if (currentCoin) {
        const requestCoinPrice = await api.tradeElement.getEstimatedCoinPrice(
          currentCoin.network,
          currentCoin.assetTicker,
        );
        dispatch(setCoinPrice(requestCoinPrice));
      } else {
        dispatch(setIsCoinPriceError(true));
      }
    } catch (e) {
      setErrorBanner(e, enqueueSnackbar);
      dispatch(setIsCoinPriceError(true));
    } finally {
      setCoinPriceLoading(false);
    }
  };

  const onReloadCoinPrice = async () => {
    await requestEstimatedCoinPrice(selectedBuySellCoin);
  };

  useEffect(() => {
    if (!shouldPriceReload) {
      dispatch(setShouldPriceReload(true));
      return;
    }

    (async () => {
      await requestEstimatedCoinPrice(selectedBuySellCoin);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuySellCoin]);

  useEffect(() => {
    if (amount) {
      setAmountError(getIsAmountInvalid(amount));
    } else {
      setAmountError(false);
    }
    if (shouldPriceReload) {
      dispatch(setIsCoinPriceError(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.flowType]);

  useEffect(() => {
    (async () => {
      await requestAccounts();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custodialAccountId, identityId]);

  return {
    coinsBuySellList,
    coinPrice,
    amount,
    amountError,
    minAmountError,
    coinPriceError,
    coinPriceLoading,
    getIsAmountInvalid,
    onAmountInputChange,
    onAmountInputBlur,
    onAmountInputFocus,
    onCoinDropdownChange,
    onReloadCoinPrice,
  };
};
