import React from 'react';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { Stack } from 'elements/components';
import { ReactComponent as CrossIcon } from 'assets/icons/remove-x-red.svg';
import { CryptoAddressResponse } from 'elements/element-transfer/types';
import { MAX_RADIO_LABEL_TEXT_LENGTH } from 'elements/element-transfer/constants';
import { StyledTypography } from './styled';

type Props = {
  data?: CryptoAddressResponse;
  onDelete?: (id: string) => void;
};

const RadioLabel: React.FC<Props> = ({ data, onDelete }) => {
  if (!data) return null;

  const { name = '', address, id } = data;

  const formattedName =
    name.length > MAX_RADIO_LABEL_TEXT_LENGTH ? `${name.substr(0, MAX_RADIO_LABEL_TEXT_LENGTH)}...` : name;

  const onDeleteClick = () => {
    if (id && onDelete) {
      onDelete(id);
    }
  };

  return (
    <Stack direction="row" alignCenter justifyContent="space-between">
      <Stack paddingY="5px">
        <Typography variant="bodyDefaultBook">{formattedName}</Typography>
        <StyledTypography variant="bodyDefaultBook" color="secondary.font">
          {address}
        </StyledTypography>
      </Stack>
      {onDelete && (
        <IconButton onClick={onDeleteClick}>
          <CrossIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default RadioLabel;
