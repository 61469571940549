import React from 'react';
import { FormControlLabel, Typography } from '@mui/material';
import { StyledFormControl, StyledRadio, StyledRadioGroup } from './styled';

export type BaseRadioGroupOption<T> = { value: string; label: string; data?: T };

type Props<T> = {
  labelledby: string;
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  values: Array<BaseRadioGroupOption<T>>;
  radioLabelNode?: React.FC<{ data?: T; onDelete?: (id: string) => void }>;
  header?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  withHover?: boolean;
  onDelete?: (id: string) => void;
};
const BaseRadioGroup = <T extends {}>({
  labelledby,
  name,
  value,
  values,
  onChange,
  radioLabelNode: RadioLabelNode,
  header,
  onBlur,
  withHover = false,
  onDelete,
}: Props<T>): React.ReactElement => {
  return (
    <StyledFormControl>
      {header && (
        <Typography component="label" variant="bodyDefaultMedium" id={labelledby}>
          {header}
        </Typography>
      )}

      <StyledRadioGroup
        aria-labelledby={labelledby}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        withHover={withHover}
      >
        {values.map(({ value: val, label, data }) => (
          <FormControlLabel
            key={val}
            value={val}
            control={<StyledRadio disableRipple />}
            label={
              RadioLabelNode ? (
                <RadioLabelNode data={data} onDelete={onDelete} />
              ) : (
                <Typography variant="bodyDefaultBook">{label}</Typography>
              )
            }
          />
        ))}
      </StyledRadioGroup>
    </StyledFormControl>
  );
};

export default BaseRadioGroup;
