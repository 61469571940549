import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Typography } from '@mui/material';
import { BaseInput } from 'elements/components';
import { BaseInputProps } from 'elements/components/BaseInput';
import { StyledAccountBalanceWalletIcon, StyledContainer, StyledFormControlLabel, StyledTypography } from './styled';

type Props = {
  walletAddressProps: BaseInputProps;
  destinationTagProps: BaseInputProps;
  nameProps?: BaseInputProps;
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  saveNewRecipient: boolean;
};

export const WalletAddressSection: React.FC<Props> = ({
  walletAddressProps,
  destinationTagProps,
  nameProps,
  onCheckboxChange,
  saveNewRecipient,
}) => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledTypography variant="captionMedium">
        {t('elements.transfer.withdrawalCryptoAmountPage.recipientAddress')}
      </StyledTypography>
      <BaseInput
        {...walletAddressProps}
        placeholder={t('elements.transfer.withdrawalCryptoAmountPage.walletAddress')}
        InputProps={{
          startAdornment: <StyledAccountBalanceWalletIcon />,
        }}
      />
      <BaseInput
        {...destinationTagProps}
        placeholder={t('elements.transfer.withdrawalCryptoAmountPage.destinationTag')}
      />
      {saveNewRecipient && nameProps && <BaseInput {...nameProps} placeholder="Name" />}
      <StyledFormControlLabel
        control={<Checkbox onChange={onCheckboxChange} checked={saveNewRecipient} />}
        label={
          <Typography variant="bodyDefaultMedium">
            {t('elements.transfer.withdrawalCryptoAmountPage.saveToAddressBook')}
          </Typography>
        }
      />
    </StyledContainer>
  );
};
