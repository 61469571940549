import { mainApi } from 'redux/api/main-api';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { API_ROUTES } from 'redux/api/api-routes';
import { ExternalAccountDetailResponseModel } from 'models/response/current/external-account-detail-response-model';

export interface ExternalAccountDetailsApiParams {
  identityId: string;
  externalAccountId: string;
}

const externalAccountsApi = mainApi.injectEndpoints({
  endpoints: build => ({
    getExternalAccountDetailsById: build.query<ExternalAccountDetailResponseModel, ExternalAccountDetailsApiParams>({
      query: ({ identityId, externalAccountId }) => ({
        url: getRouteWithParams(API_ROUTES.private.EXTERNAL_ACCOUNT, { identityId, externalAccountId }),
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetExternalAccountDetailsByIdQuery } = externalAccountsApi;
