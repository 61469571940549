export enum Paths {
  Root = '/',
  Transfer = '/deposit-withdrawal',

  // deposit ach
  DepositAchAmount = '/deposit/ach/amount',
  DepositPaymentMethod = '/deposit/ach/amount/payment',
  DepositAchReview = '/deposit/ach/review',
  DepositAchPlaced = '/deposit/ach/placed',

  // deposit wire
  DepositWire = '/deposit/wire',

  // deposit crypto
  DepositCryptoAddresses = '/deposit/crypto/addresses',
  DepositCryptoQrCode = '/deposit/crypto/qrCode',

  // withdrawal ach
  WithdrawalAchAmount = '/withdrawal/ach/amount',

  // withdrawal wire
  WithdrawalWireRecipientSearch = '/withdrawal/wire/recipient-search',
  WithdrawalWireRecipientInfo = '/withdrawal/wire/recipient-info',
  WithdrawalWireBankInfo = '/withdrawal/wire/bank-info',
  WithdrawalWireAccountNumbers = '/withdrawal/wire/account-numbers',
  WithdrawalWireAmount = '/withdrawal/wire/amount',
  WithdrawalWireReview = '/withdrawal/wire/review',
  WithdrawalWirePlaced = '/withdrawal/wire/placed',

  // withdrawal crypto
  WithdrawalCryptoAmount = '/withdrawal/crypto/amount',
  WithdrawalCryptoReview = '/withdrawal/crypto/review',
  WithdrawalCryptoSent = '/withdrawal/crypto/sent',

  // internal transfer
  InternalTransferRecipientSearch = '/withdrawal/internal/recipient-search',
  InternalTransferAmount = '/withdrawal/internal/amount',
  InternalTransferReview = '/withdrawal/internal/review',
  InternalTransferPlaced = '/withdrawal/internal/placed',
}
