import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';

import * as api from 'elements/element-transfer/api';
import { CryptoAddressRequest, TransferFlow } from 'elements/element-transfer/types';

import { setErrorBanner } from 'elements/utils';
import { DEFAULT_PAGE_COUNT, FIRST_PAGE, PAGE_SIZE_10 } from 'elements/constants';
import {
  mergeCryptoWithdrawalRecipientData,
  resetCryptoWithdrawalRecipientData,
  resetCryptoWithdrawalRecipients,
  setCryptoWithdrawalRecipients,
  setCryptoWithdrawalRecipientSearchValue,
  useElement,
} from 'elements/element-transfer/contexts/Element';

import useInfiniteScroll from './useInfiniteScroll';

const useCryptoWithdrawalRecipientSearchPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();
  const {
    search,
    recipientId,
    recipients: { list: recipientList, page: pageFromStore, pageCount: pageCountFromStore },
  } = state[TransferFlow.Withdrawal].CRYPTO;

  const [isRecipientsLoading, setIsRecipientsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isRecipientSaveLoading, setIsRecipientSaveLoading] = useState(false);
  const [lastItem, setLastItem] = useState<HTMLDivElement | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);
  const [currNetwork, setCurrNetwork] = useState('');
  const [currAsset, setCurrAsset] = useState('');
  const getCryptoRecipients = async (
    value: string,
    network: string,
    asset: string,
    page: number,
    pageCount: number,
  ) => {
    if (page > pageCount && pageCount !== 0) return;
    try {
      setIsRecipientsLoading(true);

      const { data: cryptoAddressesList, meta } = await api.transferElement.getCryptoAddresses({
        'filter[searchInfo cont]': value,
        'filter[network eq]': network,
        'filter[asset eq]': asset,
        page: page || FIRST_PAGE,
        pageSize: PAGE_SIZE_10,
      });

      dispatch(setCryptoWithdrawalRecipients({ list: cryptoAddressesList, page, pageCount: meta?.pageCount || 0 }));
    } catch (err) {
      setErrorBanner(err, enqueueSnackbar, true);
    } finally {
      setIsRecipientsLoading(false);
    }
  };

  const onSaveNewRecipient = async (body: CryptoAddressRequest) => {
    try {
      setIsRecipientSaveLoading(true);
      await api.transferElement.saveCryptoAddress(body);
    } finally {
      setIsRecipientSaveLoading(false);
    }
  };

  const onDeleteRecipient = async (id: string) => {
    try {
      setIsDeleteLoading(true);
      await api.transferElement.deleteCryptoAddress(id);

      await onSearch(search, currNetwork, currAsset);
    } catch (err) {
      setErrorBanner(err, enqueueSnackbar, true);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const onSearch = async (value: string, network: string, asset: string) => {
    dispatch(resetCryptoWithdrawalRecipients());
    dispatch(setCryptoWithdrawalRecipientSearchValue(value));

    setCurrNetwork(network);
    setCurrAsset(asset);
    await getCryptoRecipients(value, network, asset, FIRST_PAGE, DEFAULT_PAGE_COUNT);
  };

  const onSelectRecipient = (id: string) => {
    dispatch(resetCryptoWithdrawalRecipientData());
    const recipient = recipientList.find(rec => rec.id === id);

    if (recipient) {
      dispatch(mergeCryptoWithdrawalRecipientData(recipient));
    }
  };

  const getCryptoRecipientsCallback = useCallback(
    () => getCryptoRecipients(search, currNetwork, currAsset, pageFromStore + 1, pageCountFromStore),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, pageFromStore, pageCountFromStore],
  );

  useInfiniteScroll(lastItem, getCryptoRecipientsCallback);

  // is used to resolve the problem of getting ref of the last component at conditional rendering of the recipients list
  useEffect(() => {
    setLastItem(lastItemRef?.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientList]);

  return {
    search,
    recipientList,
    isRecipientsLoading,
    isDeleteLoading,
    isRecipientSaveLoading,
    recipientId,
    lastItemRef,
    onSaveNewRecipient,
    onSearch,
    onSelectRecipient,
    onDeleteRecipient,
  };
};

export default useCryptoWithdrawalRecipientSearchPage;
