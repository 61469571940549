import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Paths } from 'elements/element-transfer/navigation/routes';
import { TransferFlow } from 'elements/element-transfer/types';
import { setErrorBanner } from 'elements/utils';
import ContentBlock from 'elements/element-transfer/components/Withdrawal/InternalTransferReviewPage';
import {
  setInternalTransferPaymentId,
  setIsOrderPlaced,
  setLockId,
  setPage,
  useElement,
} from 'elements/element-transfer/contexts/Element';
import { ConfirmModal } from 'elements/components/ConfirmModal';
import { OnCloseElement } from 'elements/models/types/element-result';
import { CustomError, isOtherExceptions, isReachedLimits } from 'elements/api';
import { getParsedTraceId } from 'elements/element-transfer/utils/getParsedTraceId';
import * as api from 'elements/element-transfer/api';
import convertErrorMessage from 'elements/element-transfer/utils/convertErrorMessage';

const InternalTransferReviewPage: React.FC<{ onClose: OnCloseElement }> = ({ onClose }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useElement();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTraceId, setErrorTraceId] = useState('');

  const internalTransferState = state[TransferFlow.Withdrawal].INTERNAL;
  const { recipientId, amount, comment, recipientInfo, selectedCoin } = internalTransferState;

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onConfirmDecline = () => setIsConfirmModalOpen(false);

  const onCustomClose = () => setIsConfirmModalOpen(true);

  const onBack = (): void => {
    dispatch(setPage(Paths.InternalTransferAmount));
  };

  const sendTransfer = async () => {
    try {
      if (!state.custodialAccount || !selectedCoin || !recipientId) return;

      setLoading(true);
      setError(false);
      setErrorMessage('');
      setErrorTraceId('');

      const result = await api.transferElement.initiateInternalTransfer({
        lockId: state.lockId,
        sourceCustodialAccountId: state.custodialAccount.id,
        destinationCustodialAccountId: recipientId,
        assetType: selectedCoin.assetTicker,
        network: selectedCoin.network,
        amount: +amount,
        comment,
      });

      if (result?.id) {
        dispatch(setInternalTransferPaymentId(result?.id));
      }

      dispatch(setLockId(''));
      dispatch(setIsOrderPlaced(true));
      dispatch(setPage(Paths.InternalTransferPlaced));
    } catch (err) {
      const e = err as CustomError;
      setErrorBanner(err, enqueueSnackbar);

      setError(true);

      if (e?.responseData?.traceId) {
        const tId = getParsedTraceId(e.responseData.traceId);
        if (tId) {
          setErrorTraceId(tId);
        }
      }

      const coinAbbreviation = selectedCoin?.assetType;

      const errorResponse = err as CustomError;
      const errorMessageResponse = errorResponse?.responseData?.errors?.['']?.[0];

      if (errorMessageResponse && coinAbbreviation && !isReachedLimits(err)) {
        const errorMsg = convertErrorMessage(errorMessageResponse, coinAbbreviation);
        setErrorMessage(errorMsg);
      }

      if (isReachedLimits(err)) {
        setErrorMessage(t('serverError.transactionlimitsError'));
      }

      if (isOtherExceptions(err)) {
        const message = t('backendErrorMessages.otherExceptions');
        setErrorMessage(message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state.isOrderPlaced) {
      dispatch(setPage(Paths.WithdrawalWirePlaced));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentBlock
        amount={amount}
        selectedCoin={selectedCoin}
        recipientName={recipientInfo.name}
        recipientAccountNumber={recipientInfo.accountNumber}
        custodialAccount={state.custodialAccount}
        custodialAccountName={state.custodialAccountName}
        comment={internalTransferState.comment}
        onClose={onCustomClose}
        onBack={onBack}
        onSend={sendTransfer}
        loading={loading}
        error={error}
        errorMessage={errorMessage}
        errorTraceId={errorTraceId}
      />
      {isConfirmModalOpen && <ConfirmModal onConfirm={onClose} onDecline={onConfirmDecline} />}
    </>
  );
};

export default InternalTransferReviewPage;
