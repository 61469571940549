import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { pickersYearClasses } from '@mui/x-date-pickers/YearPicker/PickersYear';
import dayjs from 'dayjs';
import { TextFieldProps } from '@mui/material/TextField';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { StyledTextField } from '../grids/PaginatedExportGrid/GridFilterPopup/FilterValueComponent/styled';
import { DATE_PICKER_MAX_DATE, DATE_PICKER_MIN_DATE } from './constants';

type Props = {
  label: string;
  onChange: (value: number | string) => void;
  selectedDate?: string;
  textFieldProps?: TextFieldProps;
};

const OpenPickerIcon = () => {
  return <CalendarTodayOutlinedIcon sx={{ color: 'grey.500' }} />;
};

const DatePicker: React.FC<Props> = ({ label, selectedDate, onChange, textFieldProps }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        PopperProps={{
          sx: theme => ({
            '& .MuiSvgIcon-root': { color: theme.palette.grey['600'] },
            '& .css-1bx5ylf': {
              ...theme.typography.subtitleMedium,
            },
            '& .css-1n2mv2k': {
              ...theme.typography.bodySmallMedium,
              color: theme.palette.grey['500'],
            },
            '& .MuiButtonBase-root': {
              ...theme.typography.bodySmallBook,
            },
            '& .MuiButtonBase-root.Mui-selected': {
              color: theme.palette.background.paper,
              backgroundColor: theme.palette.secondary.main,
            },
            '& .MuiButtonBase-root.Mui-selected:focus': {
              backgroundColor: theme.palette.secondary.dark,
            },
            '& .MuiPickersDay-today:not(.Mui-selected)': {
              border: `1px solid ${theme.palette.secondary.main}`,
            },
            [`& .${pickersYearClasses.yearButton}.${pickersYearClasses.selected}`]: {
              backgroundColor: theme.palette.secondary.main,
            },
            [`& .${pickersYearClasses.yearButton}.${pickersYearClasses.selected}:focus, & .${pickersYearClasses.yearButton}.${pickersYearClasses.selected}:hover`]:
              {
                backgroundColor: theme.palette.secondary.dark,
              },
          }),
        }}
        components={{
          OpenPickerIcon,
        }}
        label={label}
        value={selectedDate}
        minDate={DATE_PICKER_MIN_DATE}
        maxDate={DATE_PICKER_MAX_DATE}
        onChange={(newValue: dayjs.Dayjs | null) => {
          let val: string;
          if (
            newValue === null ||
            (!newValue.isValid() && !textFieldProps) ||
            newValue.isBefore(DATE_PICKER_MIN_DATE) ||
            newValue.isAfter(DATE_PICKER_MAX_DATE)
          ) {
            val = '';
          } else {
            // save only date without time
            // eslint-disable-next-line prefer-destructuring
            val = newValue.format().split('T')[0];
          }

          onChange(val);
        }}
        renderInput={(params: TextFieldProps) => <StyledTextField {...params} {...textFieldProps} />}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
